@import "~styles/utils/media-queries";
@import "~styles/utils/mixins";

.background {
  --close-button-size: 50px;

  position: fixed;
  width: 100%;
  height: 100dvh;
  pointer-events: none;
  background-color: rgb(0 0 0 / 0%);
  z-index: 100;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease-in-out;
  display: flex;
}

.background.right {
  justify-content: flex-end;
}

.background.open {
  pointer-events: auto;
  background-color: rgb(0 0 0 / 30%);
}

.drawer {
  position: relative;
  height: 100%;
  max-width: calc(100% - var(--close-button-size));
  width: 500px;
  background-color: var(--white);
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;

  .right & {
    transform: translateX(100%);
  }

  .open & {
    transform: translateX(0) !important;
  }

  @media (--tablet-sm-and-less) {
    width: 400px;
  }
}

.close-button {
  width: var(--close-button-size);
  height: var(--close-button-size);
  position: absolute;
  display: flex;
  padding: 15px;
  top: 20%;
  right: -50px;
  background-color: var(--white);
  fill: var(--muted);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  cursor: pointer;

  .right & {
    right: auto;
    left: -50px;
  }

  .open & {
    opacity: 1;
  }
}

.container {
  @include custom-scrollbar;

  overflow: hidden auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}
