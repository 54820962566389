.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.bg-secondary-gray {
  background-color: #eeeeee;
}

.position-relative {
  position: relative;
}

.text-small {
  font-size: 14px !important;
  line-height: 20px;
}

// Custom Checkboxes override
.custom-checkbox {
  margin: 0;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 24px;
  .custom-control-indicator {
    border-radius: 4px;
    background-color: $gColorFont3;
    border: 1px solid rgba($gColorFont1, 0.25);
    width: 20px;
    height: 20px;
    background-size: 85%;
    top: 2px;
  }
  .custom-control-input:active ~ .custom-control-indicator,
  .custom-control-input:checked ~ .custom-control-indicator {
    background-color: $color1;
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjMsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8Zz4NCgkJCTxnPg0KCQkJCTxwYXRoIGZpbGw9IiNGRkZGRkYiIHN0cm9rZT0iI0ZGRkZGRiIgc3Ryb2tlLXdpZHRoPSI4IiBzdHJva2UtbWl0ZXJsaW1pdD0iMTAiIGQ9Ik0zOTMuNzI4LDE2NC4zNTINCgkJCQkJYzExLjI2NC0xMi4yODgsMjkuMTg0LDUuNjMyLDE3LjkyLDE3LjkyYy02Mi40NjQsNzAuNjU2LTEyNS40MzksMTQwLjI4OC0xODguNDE2LDIxMC40MzJjLTMuMDcxLDMuNTg0LTYuMTQ0LDYuNjU2LTkuMjE2LDEwLjI0DQoJCQkJCWMtNC42MDcsNS4xMi0xMy4zMTIsNC42MDctMTcuOTIsMGMtMzEuNzQ0LTMwLjIwOC02My40ODgtNjAuOTI4LTk1LjIzMi05MS4xMzZjLTExLjc3NS0xMS4yNjUsNi4xNDUtMjkuNjk2LDE3LjkyMS0xNy45Mg0KCQkJCQljMjguNjcyLDI3LjEzNiw1Ni44MzIsNTQuMjcxLDg1LjUwNCw4MS45MkMyNjcuNzc2LDMwNS4xNTIsMzMwLjc1MiwyMzUuMDA4LDM5My43MjgsMTY0LjM1MnoiLz4NCgkJCTwvZz4NCgkJPC9nPg0KCTwvZz4NCjwvZz4NCjwvc3ZnPg0K") !important;
    background-size: 100% 100%;
    top: 2px;
  }
  .custom-control-input:focus ~ .custom-control-indicator {
    box-shadow: none;
  }
}

.custom-control-description {
  font-size: 16px;
  margin-left: 10px;
  display: inline-block;
  @media (--mobile) {
    font-size: 14px;

    small {
      display: block;
    }
  }
}

// Custom Radio override
.custom-radio {
  margin: 0;
  outline: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0;
  .custom-control-indicator {
    border-radius: 50%;
    background-color: $gColorFont3;
    border: 1px solid rgba($gColorFont1, 0.25);
    width: 20px;
    height: 20px;
    top: 2px;
    display: block;
  }
  .custom-control-input:checked ~ .custom-control-indicator,
  .custom-control-indicator.checked {
    background-color: $gColorFont3;
    background-size: 100% 100%;
    border-color: $color1;
    top: 2px;
  }
  .custom-control-input:focus ~ .custom-control-indicator,
  .custom-control-indicator.focus {
    box-shadow: none;
  }
  .custom-control-input:disabled ~ .custom-control-indicator,
  .custom-control-indicator.disabled {
    background-color: silver;
  }
}

.form-check-inline {
  .custom-control-indicator {
    top: 1px;
  }
}
