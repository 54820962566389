@import "~styles/utils/media-queries";

.splash--text {
  z-index: 2;
  color: var(--color-on-splash);
  text-align: left;
  background: var(--splash-color);
  width: fit-content;
  border-radius: var(--rounded-corners-text-splash);
  padding: 3px 15px;
  text-transform: var(--text-uppercase-on-splash-notification);
  text-align: left;
  font-family: var(--brand-font);
  font-weight: var(--font-weight-on-splash);
  font-size: 28px;
  line-height: normal;
  @media (--tablet-lg) {
    font-size: 24px;
  }
  @media (--tablet-sm-and-less) {
    font-size: 20px;
    padding: 2px 13px;
  }
  @media (--tablet-xs) {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (--mobile) {
    font-size: 16px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: var(--rounded-corners-text-splash-sm);
  }
  @media (--mobile-md) {
    font-size: 13px;
    padding-left: 6px;
    padding-right: 6px;
  }
  @media (--mobile-sm) {
    font-size: 11px;
  }
}

.splash--pct {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  max-height: 145px;
  max-width: 145px;
  .shop-lavprisvvs-dk & {
    max-height: 155px;
    max-width: 155px;
  }
  @media (--tablet-sm) {
    max-height: 120px;
    max-width: 120px;
    .shop-lavprisvvs-dk & {
      max-height: 130px;
      max-width: 130px;
    }
  }
  @media (--tablet-xs) {
    max-height: 100px;
    max-width: 100px;
    .shop-lavprisvvs-dk & {
      max-height: 110px;
      max-width: 110px;
    }
  }
  @media (--mobile) {
    max-height: 80px;
    max-width: 80px;
    .shop-lavprisvvs-dk & {
      max-height: 90px;
      max-width: 90px;
    }
  }
  @media (--mobile-md) {
    max-height: 60px;
    max-width: 60px;
    .shop-lavprisvvs-dk & {
      max-height: 70px;
      max-width: 70px;
    }
  }
  @media (--mobile-sm) {
    max-height: 50px;
    max-width: 50px;
    .shop-lavprisvvs-dk & {
      max-height: 60px;
      max-width: 60px;
    }
  }
  svg {
    fill: var(--splash-color);
    @media (--mobile-md) {
      height: unset;
      width: 100%;
      .shop-lavprisvvs-dk & {
        height: 100%;
        width: unset;
      }
    }
  }
}

.splash--pct__text {
  fill: var(--color-on-splash);
  font-family: var(--brand-font);
  font-weight: var(--font-weight-on-splash);
}
