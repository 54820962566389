.header-container {
  p:first-of-type {
    display: none;
  }
}
.pageheader-header {
  text-align: left;
  border-bottom: none;
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100vw;
  background-blend-mode: darken;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    max-width: 852px;
    .design-lavpris &,
    .design-completvvs & {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
    .design-lavpris & {
      width: 100%;
      max-width: 100% !important;
      @media (--tablet-and-above) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
    @media (--mobile) {
      max-width: 737px;
    }
  }

  &__img {
    &-desktop {
      @media only screen and (max-width: 666px) {
        display: none;
      }
    }
    &-mobile {
      @media only screen and (min-width: 667px) {
        display: none;
      }
    }
  }

  &--open {
    max-height: none !important;
  }

  @media (--mobile) {
    border-bottom: none;
    margin: 0;
  }

  .container {
    padding: 15px 0 5px;

    @media (--mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  h2 {
    display: none;
  }
  &.bg-img {
    color: var(--white);
    position: relative;
    min-height: 350px;
    height: 34dvh;
    overflow: hidden;

    @media (--tablet-lg) {
      min-height: 330px;
    }
    @media (--tablet-sm) {
      min-height: 310px;
    }
    @media (--tablet-xs-and-less) {
      min-height: 290px;
    }
    @media (--mobile) {
      min-height: 40vw;
      height: auto;
    }
    @media (--mobile-xl) {
      min-height: 30vw;
    }
    @media (--mobile-sm) {
      min-height: 50vw;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--white);
    }

    p {
      .design-completvvs & {
        margin-top: 0;
      }
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 15px);
      z-index: 1;
      object-fit: cover;
    }
    .container {
      min-height: 350px;
      height: 34dvh;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      z-index: 2;
      .design-lavpris &,
      .design-completvvs & {
        justify-content: center;
      }
      .design-completvvs & {
        h1:after {
          width: 80%;
          margin: 5px auto 10px;
        }
      }
      @media (--tablet-and-less) {
        width: 100%;
      }
      @media (--tablet-lg) {
        min-height: 345px;
      }
      @media (--tablet-sm) {
        min-height: 325px;
      }
      @media (--tablet-xs-and-less) {
        min-height: 305px;
      }
      @media (--mobile) {
        min-height: 40vw;
        height: auto;
        padding-top: 60px;
      }
      @media (--mobile-xl) {
        min-height: 30vw;
      }
      @media (--mobile-sm) {
        min-height: 50vw;
      }
    }
    .bgcontainer {
      position: relative;
      min-height: 350px;
      height: 34dvh;
      overflow: hidden;
    }
  }

  .pageheader--author {
    text-align: left;
    margin: 5px 0;
    font-size: 0.9em;
    font-weight: 100;
    color: gray;
  }
  @media (--mobile) {
    .pageheader--author {
      text-align: center;
      margin: 10px 0 5px 0;
      font-size: 0.8em;
      font-weight: 100;
      padding: 5px 0;
      border-bottom: 1px solid #dddddd;
    }
  }

  @media (--mobile) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: none;
  }
  .pageheader-header {
    text-align: left;
    border-bottom: none;
  }

  &__manufacturer {
    margin-top: 0;

    h2 {
      color: var(--color-on-brand-color);
    }

    @media (--mobile) {
      margin-top: 100px;
    }
  }
}

.cms-header {
  .container {
    padding: 15px 0 5px;
    margin-left: auto;
    margin-right: auto;
    @media (--tablet-lg) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media (--tablet-sm) {
      padding-left: 30px;
      padding-right: 30px;
    }

    @media (--mobile) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.pageheading {
  font-size: 40px;
  line-height: 50px;
  @media (--tablet-lg) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (--tablet-sm) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (--mobile) {
    font-size: 34px;
    line-height: 40px;
  }
  @media (--mobile-sm) {
    font-size: 32px;
    line-height: 38px;
  }
}

.pageheader-bgcontainer {
  margin-top: -15px;
}


.productlist-header {
  margin: 20px 0;
  @media (--mobile) {
    margin: 15px 10px;
  }
}