.manufacturer-logo {
  position: absolute;
  top: -350px;
  right: -20px;
  transform: scale(0.8);

  @media (--mobile) {
    top: -460px;
    right: -45px;
    transform: scale(0.6);
  }
}

.article {
  background-color: var(--primary-background-color-grey);
  &__introduction {
    clear: both;
    margin: 0 auto;
    background-color: var(--white-background-color);
    h1 {
      color: var(--primary-color);
      letter-spacing: var(--letter-spacing);
      word-spacing: var(--word-spacing);
      text-transform: var(--title-uppercase);
      margin-bottom: 20px;
      .design-vvskupp & {
        color: var(--dark);
      }
      @media (--tablet-lg) {
        margin-bottom: 15px;
      }
      @media (--mobile) {
        max-width: none;
        margin: 5px 0 10px 0;
      }
    }

    @media (--mobile) {
      .row {
        flex-direction: column-reverse;
      }
    }
  }
  &__ingress {
    font-family: var(--brand-font);
    display: block;
    font-size: 20px;
    line-height: 35px;
    @media (--tablet) {
      font-size: 18px;
      line-height: 30px;
    }
    @media (--mobile) {
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__campaign-period {
    text-transform: var(--minor-header-uppercase);
    text-decoration: none;
    position: absolute;
    top: -60px;
    left: 0;

    @media (--tablet-sm) {
      top: -50px;
    }
    @media (--mobile) {
      position: initial;
      top: initial;
      left: initial;
    }

    small {
      margin: 0 5px;
    }
  }
  &__campaign-period > * {
    padding: 0 60px;
    font-family: var(--brand-font);
    display: inline-block;
    background-color: var(--highligted-background-color);
    text-decoration: none;
    color: var(--color-on-highligted-background-color);
    border-radius: var(--rounded-corners-only-top);
    height: 60px;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media (--tablet-sm) {
      padding: 0 40px;
      height: 50px;
      font-size: 1.4em;
    }
    @media (--mobile) {
      padding: 7px 15px;
      width: 100%;
      height: auto;
      border-radius: var(--rounded-corners);
      margin-bottom: 15px;
      font-size: 1.3em;
    }

    @media (--mobile-md) {
      font-size: 1.2em;
    }
    @media (--mobile-sm) {
      font-size: 1.1em;
    }
  }
  &__campaign-period.article__has-list :hover {
    background-color: color-mix(in srgb, var(--highligted-background-color), #fff 5%);
    color: var(--color-on-highligted-background-color);
    text-decoration: none;
    a,
    a:hover,
    a:focus,
    a:active {
      text-decoration: none;
      color: var(--color-on-highligted-background-color);
    }
  }
  &__continues {
    font-style: italic;
    color: $gColorFont2;
    text-align: center;
    margin: 30px 0 0 0;

    @media (--mobile) {
      margin: 15px 0 0 0;
    }
  }
}
