@import "~styles/utils/media-queries";
html {
  box-sizing: border-box;
  color: $gColorFont1;
  scroll-behavior: smooth;
  scroll-padding-top: 71px;
  @media(--mobile) {
    scroll-padding-top: 58px;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  font-size: 15px;
  line-height: normal;
  color: var(--standard-text-color);
  font-family: var(--default-font);
  @media (--mobile) {
    font-size: 14px;
    line-height: normal;
  }
}

.heading {
  &--nav {
    font-size: 15px;
    text-transform: uppercase;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading,
.heading--1,
.heading--2,
.heading--3 {
  margin: 0 0 5px 0;
  line-height: 1.4em;
  display: block;
  flex-shrink: 0;
  word-break: break-word;
  font-weight: var(--title-font-weight);
  font-family: var(--brand-font);
  color: var(--dark);
}

h1,
.heading--1 {
  font-size: 40px;
  line-height: 50px;
  @media (--tablet-lg) {
    font-size: 38px;
    line-height: 48px;
  }
  @media (--tablet-sm) {
    font-size: 36px;
    line-height: 44px;
  }
  @media (--mobile) {
    font-size: 34px;
    line-height: 40px;
  }
  @media (--mobile-sm) {
    font-size: 32px;
    line-height: 38px;
  }
}

h2,
.heading--2 {
  font-size: 32px;
  line-height: 42px;
  @media (--tablet-lg) {
    font-size: 30px;
    line-height: 40px;
  }
  @media (--tablet-sm) {
    font-size: 28px;
    line-height: 36px;
  }
  @media (--mobile) {
    font-size: 26px;
    line-height: 32px;
  }
  @media (--mobile-sm) {
    font-size: 24px;
    line-height: 30px;
  }
}

h3,
.heading--3 {
  font-size: 26px;
  line-height: 36px;
  @media (--tablet-lg) {
    font-size: 24px;
    line-height: 34px;
  }
  @media (--tablet-sm) {
    font-size: 22px;
    line-height: 30px;
  }
  @media (--mobile) {
    font-size: 20px;
    line-height: 26px;
  }
}

h4,
.heading--4 {
  font-size: 22px;
  line-height: 32px;
  @media (--tablet-lg) {
    font-size: 20px;
    line-height: 30px;
  }
  @media (--tablet-sm) {
    font-size: 18px;
    line-height: 26px;
  }
  @media (--mobile) {
    font-size: 17px;
    line-height: 23px;
  }
}

h5,
.heading--5 {
  font-size: 18px;
  line-height: 28px;
  @media (--tablet-lg) {
    font-size: 18px;
    line-height: 28px;
  }
  @media (--tablet-sm) {
    font-size: 17px;
    line-height: 23px;
  }
  @media (--mobile) {
    font-size: 16px;
    line-height: 22px;
  }
  @media (--mobile-sm) {
    font-size: 15px;
    line-height: 21px;
  }
}

h6,
.heading--6 {
  font-size: 16px;
  line-height: 26px;
  @media (--tablet-lg) {
    font-size: 15px;
    line-height: 25px;
  }
  @media (--tablet-sm) {
    font-size: 15px;
    line-height: 23px;
  }
  @media (--mobile) {
    font-size: 14px;
    line-height: 20px;
  }
  @media (--mobile-sm) {
    font-size: 14px;
    line-height: 20px;
  }
}

p,
.fake-p {
  margin: 10px 0 20px;
  font-size: 15px;
  line-height: 25px;
  @media (--mobile) {
    margin: 5px 0 10px;
    font-size: 14px;
    line-height: 24px;
  }
}

form {
  margin: 0;
}

img {
  max-width: 100%;
}

/* Hide lazyloading images */
img:not([src]) {
  visibility: hidden;
}

svg {
  max-width: 100%;
  height: auto;
  /* Fixes hover/click events in safari browsers. */
  position: relative;
  /* Fix vertical positioning of SVG elements in safari. */
  max-height: 100%;
}

svg > use {
  /* Fix for javascript errors in Internet Explorer/Edge. */
  pointer-events: none;
}

.svg-container {
  display: none;
}

article {
  @media (--mobile) {
    padding: 0 10px;
  }
  .btn {
    margin-top: 5px;
  }
}

.icon {
  width: 32px;
  height: 32px;
  &--arrow {
    &--right {
      transform: rotate(0deg);
    }
    &--left {
      transform: rotate(180deg);
    }
    &--up {
      transform: rotate(-90deg);
    }
    &--down {
      transform: rotate(90deg);
    }
  }
  &--plus,
  &--minus {
    cursor: pointer;
  }
  &--sm {
    width: 16px;
    height: 16px;
  }
  &--md {
    width: 28px;
    height: 28px;
  }
  &--lg {
    width: 64px;
    height: 64px;
  }
  &--alert-small {
    height: 14px;
    width: 14px;
    margin: -1px 5px 0 0;
  }
}

.login__wrapper {
  padding: 40px 0 60px 0;
  @media (--mobile) {
    padding: 15px;
  }
}

.myprofile {
  @media (--mobile) {
    background-color: transparent !important;
  }
  &__top {
    form {
      display: flex;
      gap: var(--spacing-300);
    }
    padding-top: var(--spacing-200);
    padding-bottom: var(--spacing-400);
  }
  &__wrapper {
    .form-group {
      text-align: left;
    }
    @media (--mobile) {
      padding: 15px;
      .article {
        border-radius: 14px;
        padding: 0;
        background-color: transparent !important;
      }
      .a-form {
        padding: 5px;
      }
    }
  }
}

select {
  border: 1px solid $gColorBorder4;
  padding: 5px;
  font-size: 16px;
}

.showOn {
  &--lg {
    display: none;
    @media (--desktop) {
      display: inherit;
    }
  }
  &--md {
    display: none;
    @media (--tablet) {
      display: inherit;
    }
  }
  &--sm {
    display: none !important;
    @media (--mobile) {
      display: inline !important;
    }
  }
}

.hide {
  display: none;
  &--lg {
    @media (--desktop) {
      display: none !important;
    }
  }
  &--md {
    @media (--tablet) {
      display: none !important;
    }
  }
  &--sm {
    @media (--mobile) {
      display: none !important;
    }
  }
}

.footer,
.main-container {
  @media (--tablet-portrait) {
    max-width: 1024px;
    width: 100vw;
  }
}

.container {
  width: 1435px;
  margin: 0 auto;
  position: relative;
  display: block;
  position: relative;
  max-width: 1435px;
  padding-right: 0;
  padding-left: 0;
  @media (--desktop-lg) {
    width: 1725px;
    max-width: 1725px;
  }
  @media (--tablet-lg) {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (--tablet-sm) {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (--mobile) {
    width: 100%;
  }
  @media (--tablet) {
    max-width: 100%;
  }
}

.spacer {
  width: 100%;
  height: 40px;
}

.divider {
  width: 100%;
  margin: 15px 0;
  display: block;
  border-top: 1px solid;
  &--big {
    margin: 20px 0;
  }
  &--double {
    border-bottom: 1px solid;
    height: 6px;
  }
  &--clean {
    border-top: 0px solid;
    @media (--mobile) {
      width: 100%;
    }
  }
}

.textLeft {
  text-align: left;
}

.textRight {
  text-align: right;
}

.textCenter {
  text-align: center;
}

.form {
  fieldset {
    margin: 0;
    border: none;
    padding: 0;
  }
  legend {
    display: none;
  }
  &__required::after {
    content: " *";
    color: #ad3200;
  }
  &__row {
    margin: 10px 0;
    align-items: baseline;
    width: 100%;
    text-align: left;
  }
  &__label-container {
    margin-bottom: auto;
    margin-top: 10px;
    @media (--tablet-and-above) {
      white-space: nowrap;
    }
  }
  &__input {
    width: 100%;
  }
  .has-error {
    .control-label,
    .help-block,
    .form-control-feedback {
      color: $gColorFont10;
      border-width: medium;
    }
    .agree-to-terms {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
    input[type="tel"],
    input[type="text"],
    input[type="email"],
    input[type="password"] {
      border-color: $gColorFont10;
      border-width: medium;
    }
  }
  .help-block {
    display: block;
    padding-top: 5px;
  }
}

/* Disable text-decoration from bootstrap reboot. */

a {
  color: var(--standard-text-color);
}

a:hover {
  color: var(--dark)
}

.link {
  text-decoration: underline;
}

button:focus {
  outline: none;
}

.embed-responsive {
  margin: 0 0 15px 0;
  border-radius: var(--rounded-corners);
  @media (--mobile) {
    border-radius: var(--rounded-corners-sm);
  }
}

.margin-0 {
  margin: 0;
}

#Coi-Renew {
  bottom: 155px !important;
  border-bottom-right-radius: 50%;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  @media (--mobile) {
    bottom: 75px !important;
  }
  display: none !important;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

input[type="checkbox"],
input[type="radio"] {
  min-width: 20px;
  min-height: 20px;
}
