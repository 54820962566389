///Used for animated-arrow.html so we have a consistent look for the animated arrow across react and html

.animated-arrow-container {
    min-width: 200%;
    height: 100%;
    display: flex;
    transform: translateX(-50%);
    transition: none;
    align-items: center;
}

.animated-arrow {
    display: flex;
    overflow: hidden;
    justify-content: normal;
    fill: var(--color-on-brand-color);
    border-radius: var(--rounded-corners-circle-or-square);
    background-color: var(--brand-color);
    border: var(--border-medium) var(--brand-color);
    flex-shrink: 0;
    align-self: center;
    height: 1em;
    width: 1em;
    min-height: 24px;
    min-width: 24px;
    .design-lampeguru & {
        border-color: transparent;
        fill: var(--white);
        background-color:transparent;
    }

    svg {
        width: 50%;
        height: 100%;
        padding: 12%;
    }

    @media (--tablet-xs-and-less) {
        border-width: 1px;
        min-height: 20px;
        min-width: 20px;
    }


    a:hover & {
        fill: var(--brand-color);
        background-color: var(--color-brand-brighter-1);
        border-color: var(--color-brand-brighter-1);
        .design-greenline & {
            background-color: var(--hover-of-brand-color);
            border-color: var(--hover-of-brand-color);
            fill: var(--white);
        }
        .design-lampeguru & {
            background-color: var(--dark);
            border-color: var(--dark);
            fill: var(--white);
        }
        .design-lavpris & {
            fill: var(--color-on-primary-color);
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }

        .design-completvvs & {
            fill: var(--color-on-brand-color);
            background-color: var(--hover-of-brand-color);
            border-color: var(--hover-of-brand-color);
        }
        .animated-arrow-container{
            transform: translateX(0%);
            transition: transform var(--standard-transition);
        }
    }
}

.animated-arrow-outline {
    fill: var(--color-on-primary-color);
    background-color: transparent;
    border-color: var(--color-on-primary-color);
    border-radius: var(--rounded-corners-circle-or-square);
    .design-lavpris & {
        border-color: var(--dark);
        background-color: var(--dark);
        color: var(--white)
    }

    a:hover & {
        fill: var(--brand-color);
        background-color: var(--white);
        border-color: var(--white);
        .design-lavpris & {
            background-color: var(--color-primary-400);
            border-color: var(--color-primary-400);
            fill: var(--white);
        }
        .design-completvvs & {
            fill: var(--brand-color);
            background-color: var(--white);
            border-color: var(--white);
        }
        .design-greenline & {
            fill: var(--brand-color);
            background-color: var(--white);
            border-color: var(--white);
        }
    }
}

.animated-arrow-dark {
    fill: var(--color-on-brand-color);
    border-radius: var(--rounded-corners-circle-or-square);
    background-color: var(--color-brand-600);
    border-color: var(--color-brand-600);
    .design-lavpris & {
        background-color: var(--brand-color);
        fill: var(--color-on-brand-color);
        border-color: var(--brand-color);

    }
    .design-lampeguru & {
        fill: var(--dark);
        background-color: transparent;
        border-color: transparent;

    }

    .design-greenline & {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
    }

    a:hover & {
        fill: var(--white);
        background-color: var(--color-brand-700);
        border-color: var(--color-brand-700);
        .design-lavpris & {
            background-color: var(--dark);
            border-color: var(--dark);
            fill: var(--white);
        }

        .design-greenline &{
            fill: var(--white);
            background-color: var(--color-secondary-600);
            border-color: var(--color-secondary-600);
        }

        .design-lampeguru & {
            fill: var(--dark);
            background-color: var(--color-brand-200);
            border-color: var(--color-brand-200);
        }
    }
}

.animated-arrow-down {
    transform: rotate(90deg);
}
