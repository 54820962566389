body:has(#AdminTools.pinned) {
  @media screen and (min-width: 1735px) {
    > .header__top-bar, > .header__megamenu, > .main-container, > .footer, .product-card__enlarged {
      padding-right: 100px;
    }

    .product-card__close__wrapper {
      margin-right: 100px;
    }
  }
}
body:has(#AdminTools) {
  > .header__top-bar, > .header__megamenu, > .main-container, > .footer, .product-card__enlarged {
    transition: padding-right ease-in-out 0.3s;
  }
}

div#AdminTools {
  position: fixed;
  z-index: 1000000;
  bottom: 0;
  right: -300px;
  top: 0;
  min-height: 30px;
  background-color: #444;
  opacity: 0.95;
  color: white;
  font-size: 12px;
  width: 300px;
  overflow-x: auto;

  .toggle-button {
    color: #fff;
    position: fixed;
    right: 40px;
    top: 50px;
    transform: rotate(270deg);
    transform-origin: right top;
    font-size: 16pt;
    background-color: rgb(161, 161, 161);
    border: black solid 1px;
    padding: 8px;
    font-family: Arial;
  }

  @media screen and (min-width: 1735px) {
    &.pinned {
      opacity: 1;
    }
  }

  &:hover,
  &.pinned {
    right: 0;
    .toggle-button {
      display: none;
    }
  }

  a {
    display: block;
    width: 100%;
    font-size: 12px;
    color: white;
    border-bottom: gray solid 1px;
    text-decoration: none;
    &:hover {
      background-color: #6C6C6C;
    }
  }

  .block {
    display: block;
    width: 100%;
    font-size: 12px;
    color: white;
    border-bottom: gray solid 1px;
    text-decoration: none;

    a {
      border-bottom: none;
    }
    .kvinfo {
      width: 49%;
      padding: 5px 10px;
    }
    .subblock {
      display: none;
    }
    &.open {
      .subblock {
        display: flex;
        flex-wrap: wrap;
      }
      a {
        font-weight: bold;
      }
    }
  }

  a {
    padding: 10px;
    position: relative;
  }

  h1 {
    content: "Administration";
    display: block;
    border-bottom: #aaa solid 1px;
    margin: 0;
    font-family: Arial;
    font-size: 20px;
    text-align: center;
    height: 30px;
    color: white;
    background-color: $colorBg6;
    padding-top: 1px;
    font-weight: 400;
    line-height: 26px;
  }

  svg {
    stroke: white;
    height: 22px;
    width: 22px;
    position: absolute;
    top: 6px;
    left: 5px;
  }
  svg ~ span {
    margin-left: 20px;
  }

  .js-admintools-pin {
    display: none;
    position: absolute;
    top: 2px;
    left: 8px;
    color: #ccc;
    transform: rotate(20deg);
    cursor: pointer;
  }

  &.pinned .js-admintools-pin,
  .js-admintools-pin:hover {
    transform: none;
    color: white;
  }

  &:hover .js-admintools-pin,
  &.pinned .js-admintools-pin {
    display: block;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
}

.js-click-to-copy {
  cursor: pointer;
  padding: 0 2px;
  border-bottom: white dotted 1px;
  position: relative;
  &:hover {
    border-color: red;
  }
}
