@import "~styles/utils/media-queries";
@import "~styles/utils/mixins";

@keyframes fade-in {
  from {
    background-color: transparent;
  }

  to {
    background-color: var(--standard-faded-overlay);
  }
}

.overlay {
  animation: fade-in var(--standard-transition);
  padding: 30px;
  align-items: center;
  background-color: var(--standard-faded-overlay);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  gap: 10px;

  @media (--mobile) {
    padding: 8px 8px 35px;
  }
}

.overlay-container {
  --bg-color: var(--white);
  --fill-color: var(--muted);

  padding: 30px;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3000;
  gap: 10px;
  flex-direction: column;
  line-height: 25px;

  @media (--mobile) {
    padding: 8px 8px 35px;
    line-height: 24px;
  }
}

.mobile-close {
  cursor: pointer;
  flex-shrink: 0;
  stroke: var(--color-black);
  stroke-opacity: 0.6;
  transition: var(--standard-transition-all);
  align-items: center;
  background-color: var(--bg-color);
  border-radius: var(--rounded-corners-circle-or-square);
  box-shadow: var(--modal-shadow);
  display: flex;
  font-size: 20px;
  height: 50px;
  justify-content: center;
  width: 50px;
  z-index: 1015;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover,
  :active {
    stroke-opacity: 0.9;
  }

  @media (--tablet-and-above) {
    display: none;
  }
}

.desktop-close {
  cursor: pointer;
  stroke: var(--color-black);
  height: 16px;
  width: 16px;
  position: absolute;
  right: 15px;
  top: 15px;
  transition: var(--standard-transition-all);
  z-index: 100;
  stroke-opacity: 0.6;

  &:hover,
  :active {
    stroke-opacity: 0.9;
  }

  @media (--mobile) {
    display: none;
  }
}

.size-fullscreen .mobile-close {
  display: none !important;
}

.size-fullscreen .desktop-close {
  display: block !important;
}

.modal {
  @include custom-scrollbar;

  background-color: var(--bg-color);
  border-radius: var(--rounded-corners);
  box-shadow: var(--modal-shadow);
  max-width: 100%;
  position: relative;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.size-small .modal {
  width: 440px;
}

.size-fullscreen .modal {
  height: 100%;
  width: 100%;

  @media (--mobile) {
    border-radius: 0;
  }
}

.size-fullscreen {
  padding: 30px;

  @media (--mobile) {
    padding: 0;
  }

  @media (--tablet-sm) {
    padding: 20px;
  }
}

.size-default .modal {
  width: 930px;
}

.type-info {
  --bg-color: var(--color-info-bg);
  --fill-color: var(--color-info);
}

.type-error {
  --bg-color: var(--color-error-bg);
  --fill-color: var(--color-error);
}

.type-warning {
  --bg-color: var(--color-warning-bg);
  --fill-color: var(--color-warning);
}

.type-success {
  --bg-color: var(--color-success-bg);
  --fill-color: var(--color-success);
}
