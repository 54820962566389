$enable-flex: true;

/*Custom breakpoints
$mobile: 0px;
$tablet: 768px;
$tablet-landscape: 1024px;
$small-desktop: 1025px;
$desktop: 1340px;*/

//Custom breakpoints made for the new Product Card
$mobile: 0;
$tablet: 961px;
$tablet-landscape: 961px;
$small-desktop: 1280px;
$desktop: 1516px;
$large-desktop: 1516px;
$xl-desktop: 1516px;

$container-max-widths: (
  sm: 100%,
  md: 100%,
  lg: 1435px,
);

$brand-danger: #ad3200;

$grid-breakpoints: (
  // Extra small screen / phone
  //xs: 0,
  // Small screen / phone
  sm: $mobile,
  // Medium screen / tablet
  md: $tablet,
  // Medium-large screen / above tablet-landscape
  ml: $small-desktop,
  // Large screen / desktop
  lg: $desktop,
  // Extra large screen / wide desktop
  //xl: 1200px
);

//== Grid system
//
//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 24;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 10px;

//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: 769px;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: 1025px;
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1100px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;

//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 1024px;
$screen-md-min: $screen-md;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1024px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;

$input-border-focus: #7e7e7e;
