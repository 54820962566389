/* overwrite bootstraps  */

/* Overrides font color and adds bold to <a> elements before alerts. */
@mixin alert-link($font-color) {
  color: $font-color;
  font-weight: bold;
}

.alert {
  padding: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  line-height: 1.4em;
  @media (--mobile) {
    margin: 10px 0px;
    width: auto;
  }
  border: 1px solid transparent;
  /* remove the border radius from bootstrap, as we're not using rounded
       borders on alerts. */
  border-radius: 0;

  &.alert-success {
    color: $gColorFont5;
    border-color: $gColorBorder6;
    background: $gColorbg4;
    & a {
      @include alert-link($gColorFont5);
    }
  }
  &.alert-info {
    color: $gColorbg2;
    background: $color9;
    border-radius: 4px;
    & a {
      @include alert-link($gColorFont6);
    }
    &-header {
      font-family: $fontFamilyOswald;
      font-size: 20px;
      display: block;
      line-height: 1.6;
      margin: 0;
      padding: 0;
      @media (--mobile) {
        font-size: 16px;
      }
    }
    @media (--mobile) {
      font-size: 0.9rem;
    }
  }
  &.alert-warning {
    color: $gColorFont7;
    border-color: $gColorBorder8;
    background: $gColorbg6;
    & a {
      @include alert-link($gColorFont7);
    }
  }
  &.alert-danger,
  &.alert-error {
    color: $gColorFont8;
    border-color: $gColorBorder9;
    background: $gColorbg7;
    & a {
      @include alert-link($gColorFont8);
    }
  }
}

.alert-ícon svg {
  fill: $gColorbg2;
  height: 32px;
  width: 32px;
}
