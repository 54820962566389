@import "~styles/utils/media-queries";

.container {
  margin: 0 auto;
  max-width: 1435px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  width: 1435px;

  @media (--desktop-lg) {
    width: 1725px;
    max-width: 1725px;
  }

  @media (--tablet-and-less) {
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    max-width: 100%;
  }

  @media (--tablet-sm) {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media (--mobile) {
    position: unset;
    padding: 0 15px;
  }
}
