.product-card {
  &__manufacturer-logo {
    z-index: 1;
    a {
      text-decoration: none;
      transition: all 0.2s ease-in-out;
      display: flex;
      justify-content: flex-end;
      &:hover {
        opacity: 0.8;
      }
    }
    img {
      max-height: 40px;
      max-width: 90px;
    }
    @media (--mobile) {
      display: none;
    }
  }
  &__content {
    &__manufacturer {
      background-color: $color1;
      color: $color5;
      padding-bottom: 5px;
      @media (--mobile) {
        background-color: $colorBg2;
        color: $gColorFont1;
        padding-bottom: 13px;
      }

      .product-card__read-more {
        color: $color5;
        @media (--mobile) {
          color: $color1;
        }
        svg {
          fill: $color5;
        }

        &:hover {
          cursor: pointer;
          .product-card__read-more__arrow {
            background-color: $color5;

            @media (--mobile) {
              background-color: $color2;
            }
            svg {
              fill: $color1;
            }
          }
        }
      }
      .product-card__read-more__arrow {
        background-color: transparent;
        box-shadow: 0 0 0 1px $color5;
        border: 1px solid $color5;
        padding: 13px;
        @media (--mobile) {
          background-color: $color1;
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }
      blockquote {
        color: $color5;
        @media (--mobile) {
          color: $gColorFont1;
        }
        .product-card__content__body__blockquote {
          margin: 5px auto;
          background-color: $color5;
          @media (--mobile) {
            background-color: $color1;
          }
        }
        svg {
          fill: $color1;
          @media (--mobile) {
            fill: $color5;
          }
        }
      }
      .product-card__content__header__h2 {
        display: none;
        @media (--mobile) {
          display: block;
        }
      }
      p:first-of-type {
        font-family: $fontFamilyOswald;
        font-size: 18px;
        line-height: 28px;
        @media (--mobile) {
          font-size: 16px;
          line-height: 26px;
          color: $gColorFont1;
        }
      }
      p {
        @media (--mobile) {
          color: $gColorFont1;
        }
      }
    }
    &__body {
      &__manufacturer-logo {
        margin: 40px 0 30px 0;
        @media (--mobile) {
          margin: 20px 0 10px 0;
        }
        img {
          max-height: 70px;
          max-width: 140px;
          @media (--mobile) {
            transform: scale(0.8);
          }
        }
        .black-white {
          @media (--mobile) {
            display: none;
          }
        }
        .colors {
          display: none;
          @media (--mobile) {
            display: inline-block;
          }
        }
      }
    }
  }
}
