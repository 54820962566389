.highlight {
  &__checkmark {
    height: 50px;
    width: 50px;
    background-color: var(--brand-color);
    border-radius: var(--rounded-corners-circle-or-square);
    color: var(--color-on-brand-color);
    .design-billigvvs & {
      background-color: var(--highligted-background-color);

      color: var(--color-on-highligted-background-color);
    }
    font-family: var(--brand-font);
    display: inline-block;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    @media (--mobile) {
      margin-bottom: 5px;
      height: 40px;
      width: 40px;
    }
    svg {
      height: 20px;
      width: 20px;
      .icon-stroke {
        stroke: var(--color-on-secondary-color);
      }
      @media (--mobile) {
        height: 18px;
        width: 18px;
      }
    }
  }
}

.points {
  text-align: center;
  padding-bottom: 30px;
  @media (--tablet) {
    padding-bottom: 20px;
  }
  @media (--mobile) {
    padding-bottom: 10px;
  }
  p {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 5px;
    max-width: 852px;
    @media (--tablet-sm-and-less) {
      padding-left: 10px;
      padding-right: 10px;
    }
    @media (--mobile-md) {
      font-size: 0.8rem;
      line-height: 20px;
    }
  }
}

.points-button {
  display:flex;
  justify-content: center;
}

.points-flex {
  --point-grap: 30px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-top: 30px;
  gap: var(--point-grap);

  @media (--tablet) {
    margin-top: 20px;
  }
  @media (--tablet-sm) {
    --point-grap: 20px;
  }
  @media (--mobile) {
    margin-top: 15px;
    --point-grap: 10px;
  }
  @media (--mobile-sm) {
    --point-grap: 5px;
  }
}

.flex-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: calc(33% - ((var(--point-grap) * 2) / 3)); // 2 gaps between 3 items
  flex-grow: 1;
  flex-basis: calc(33% - ((var(--point-grap) * 2) / 3));

  @media (--mobile) {
    max-width: calc(50% - var(--point-grap) / 2) !important;
  }

  @media (--mobile-md) {
    flex-basis: calc(50% - var(--point-grap) / 2) !important;
    max-width: 100% !important;

    .flex-3-odd &:first-child {
      flex-basis: 100% !important;
    }
  }

  @media (--mobile-sm) {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }

  img {
    border-radius: var(--rounded-corners);
    margin-bottom: 10px;
    @media (--mobile) {
      border-radius: var(--rounded-corners-sm);
    }
  }
}

.flex-4 .flex-item {
  max-width: calc(25% - ((var(--point-grap) * 3) / 4)); // 3 gaps between 4 items
  flex-basis: calc(25% - ((var(--point-grap) * 3) / 4));
}

.flex-2 .flex-item {
  max-width: calc(50 - (var(--point-grap) / 2));
  flex-basis: calc(25% - (var(--point-grap) / 2));
}

//.flex-2 .flex-item {
//  max-width: 50%;
//  flex-basis: 182px;
//  @media (--mobile-sm) {
//    max-width: 100%;
//  }
//
//  img {
//    max-height: 702px;
//  }
//}
//
//.flex-3 .flex-item {
//  max-width: 33%;
//  min-width: 26%;
//  flex-basis: 182px;
//
//  @media (--mobile-md) {
//    max-width: 100%;
//  }
//  &:nth-child(1n + 4) {
//    max-width: calc(33% - 5px);
//    @media (--mobile-md) {
//      max-width: 100%;
//    }
//  }
//  img {
//    max-height: 565px;
//  }
//}
//
//.flex-4 .flex-item {
//  max-width: 25%;
//  flex-basis: 180px;
//  @media (--mobile) {
//    max-width: 50%;
//    min-width: 34%;
//  }
//  @media (--mobile-lg) {
//    flex-basis: 223px;
//  }
//
//  @media (--mobile-sm) {
//    flex-basis: 182px;
//    max-width: 100%;
//  }
//}
