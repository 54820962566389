.tag {
  &__explore-more {
    color: $gColorFont1;
    text-decoration: none;
    display: inline-block;
    background-color: $color5;
    padding: 15px 20px;
    margin: 0 1px 5px 0;
    border-radius: 50px;
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.2s ease-in-out;
    border: var(--border-thin) $gColorBorder1;
    &:hover {
      background-color: $color1;
      border-color: $color1;
      color: $color5;
    }
    @media (--tablet-sm) {
      font-size: 13px;
      padding: 10px 15px;
    }
    @media (--mobile) {
      font-size: 12px;
      padding: 8px 12px;
    }
    &__campaign {
      border-color: $color1;
      color: $color1;
    }
  }
}
.product-card {
  &__content {
    &__explore-more {
      background-color: $colorBg10;
      @media (--mobile) {
        background-color: $color5;
      }
    }
  }
}
