.header__top-bar--checkout {
  border-bottom: var(--border-thin) var(--brand-color);
  background-color: var(--white);
  position: sticky;
  top: 0;
  z-index: 50;

  .design-lavpris & {
    background-color: var(--brand-color);
  }

  .design-lampeguru & {
    background-color: var(--primary-color);
  }

  @media (--mobile) {
    position: sticky;
    width: 100%;
    z-index: 100;
  }
}

.header__top-bar--checkout--blocks {
  height: 71px;
  display: flex;
  justify-content: space-between;

  @media (--mobile) {
    flex-direction: column;
    height: 56px;
  }
}

.header__logo {
  height: 100%;
}

.header__logo-img {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (--mobile) {
   padding-right: 4px;
  }

  svg {
    height: 47px;
    width: 100%;
    padding: 3px 0;

    .shop-billigvvs-dk &,
    .shop-billigvvs-no & {
      padding: 7px 0 0;
  
      @media (--tablet-sm) {
        padding: 9px 0 2px;
      }
  
      @media (--mobile) {
        padding: 10px 4px 4px 0;
      }
    }

    
  .shop-vvsochbad-se & {
    padding: 8px 0;
    @media (--tablet-sm) {
      padding: 10px 0;
    }
    @media (--mobile) {
      padding: 11px 0;
    }
    @media (--mobile-sm) {
      padding: 12px 0;
    }
  }

  .design-greenline & {
    @media (--tablet-and-above) {
      padding: 3px 0 0;
    }
    @media (--tablet-sm) {
      padding: 5px 0 2px;
    }
  }

  .design-completvvs & {
    @media (--tablet-sm) {
      padding: 5px 0;
    }
    @media (--mobile) {
      padding: 8px 0 5px;
    }
  }

  .shop-lampeguru-dk &,
  .shop-lampeguru-no & {
    padding: 5px 0;
    @media (--tablet-sm) {
      padding: 8px 0;
    }
    @media (--mobile) {
      padding: 11px 0 10px;
    }
  }

  .shop-ljusochlampor-se & {
    padding: 7px 0;
    @media (--tablet-lg) {
      padding: 8px 0;
    }
    @media (--tablet-sm) {
      padding: 10px 0;
    }
    @media (--mobile) {
      padding: 11px 0 10px;
    }
    @media (--mobile-sm) {
      padding: 12px 0 11px;
    }
  }

    @media (--mobile) {
      padding: 6px 0 5px 0 ;
    }
  }
}