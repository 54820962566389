.container-fluid .article__content {
  padding: 40px 10px 40px 0;
  @media (--tablet) {
    padding: 30px 5px;
  }
  @media (--mobile) {
    padding: 15px;
  }
}

.article {
  &__content {
    background-color: var(--white);
    padding: 30px 40px;
    line-height: 1.5;
    column-count: 2;
    column-gap: 40px;
    h1 {
      font-size: 40px;
      line-height: 50px;
      word-spacing: 0.5px;
      @media (--tablet) {
        font-size: 35px;
        line-height: 45px;
      }
      @media (--mobile) {
        font-size: 30px;
        line-height: 40px;
      }
    }
    h2 {
      font-size: 28px;
      line-height: 38px;
      word-spacing: 0.5px;
      @media (--tablet) {
        font-size: 24px;
        line-height: 34px;
      }
      @media (--mobile) {
        font-size: 22px;
        line-height: 32px;
      }
    }
    h3 {
      font-size: 22px;
      line-height: 42px;
      word-spacing: 0.5px;
    }
    @media (--tablet) {
      padding: 25px 35px;
      column-gap: 35px;
    }
    @media (--mobile) {
      padding: 15px;
      column-count: 1;
      column-gap: initial;
      margin: 0;
    }
    p a {
      color: $color1;
      &:hover {
        color: #000;
        text-decoration: underline;
      }
    }
    p {
      margin: 0 0 15px 0;
    }

    h2:first-of-type {
      margin-top: 0;
    }
    .embed-responsive {
      margin: 3% 0;
      display: flex;
      justify-content: center;
      clear: both;
      -webkit-column-break-inside: avoid;
      page-break-inside: avoid;
      break-inside: avoid;
      height: 357px;
      border-radius: 4px;
      padding: 0;
      align-items: center;
    }
    .embed-responsive img {
      min-height: 357px;
    }
    &__single-column {
      column-count: 1;
      @media (--desktop) {
        .embed-responsive {
          width: 635px;
          float: left;
          margin: 1% 3% 1% 0;
        }
      }
      @media (--tablet) {
        .embed-responsive {
          height: 477px;
          width: 100%;

          margin: 3% 0;
        }
      }
    }
    ul {
      display: table;
      list-style: none;
      margin: 10px 0 15px 0;
      padding: 0 0 0 20px;
    }
    ul li {
      display: table-row;
      line-height: 1.8;
      padding: 0 20px 0 0;
      text-indent: 0;
    }
    ul li:before {
      color: $color1;
      content: "\25AA ";
      display: table-cell;
      text-indent: -15px;
    }
    .embed-responsive .youtube_codegena {
      display: contents;
      position: static;
    }
    .embed-responsive .youtube_codegena .play {
      background-color: $color1;
      border-radius: 20px;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAAQlBMVEVHcEz////////////////////////////////////////////////////////////////////////////////////1bZCPAAAAFXRSTlMAPPaiUCkRzXrr1m3csxq8xF+KlgmYsw5PAAABUElEQVRYw+2XW3aDMAxEx4AB8w5B+99qf9L6EQK2pY+e1rOAe7AYSSOgqKio6M/oMZhZSfKIiKhexIAbERFR1R9CwIFemhoZYEc/2pQwkOjZCgOp6oWBREMjAjSVRa5aADgu7pfWLRvYA6OxRDMKAHHszrvzXWmBgJ6dd8+aDwSage1KHyhQyhCI9um6UvGBQSmTXXkCDEq5t3wg0DsWMg8BYH4pPwEBtbrdqPlAoJkySnkFxOGVUvGBwFKn/pwbILBYV86/Eeg+OW6kxf+UTovaJnryXBh7y2roqNZLGTkRwyFtKN6Or9SxfTZgV86OfgO6zstJEVdLKms5B2vUdV5eEvMWvVe8zNhtgZ7z8tPsaVji5O1XnNNOnKt2zkXwHjiZN0sYidmngA9M2+m3QJFzquNHzA+nWSd0kq5ixfvu3pzIdk3cplqjqKio6H/pCzsKPvol3xXTAAAAAElFTkSuQmCC");
      background: auto;
      height: 80px;
      width: 80px;
      transition: all 0.4s ease;
      &:hover {
        opacity: 1;
        transition: all 0.4s ease;
        background-color: #005078;
        transition: all 0.4s ease;
        box-shadow: 0 0 0 10px rgba(0, 138, 208, 0.4);
      }
    }

    .icon-guide,
    .icon-factbox {
      background-color: $color1;
      height: 40px;
      width: 40px;
      border-radius: 100px;
      fill: $color5;
      color: $color5;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      float: left;
      @media (--mobile) {
        height: 30px;
        width: 30px;
        top: 15px;
        left: 15px;
      } 
      svg {
        height: 18px;
        width: 18px;
        .icon-stroke {
          stroke: var(--color-on-brand-color);
        }
        @media (--mobile) {
          height: 13px;
          width: 13px;
        }
      }
    }
    blockquote {
      padding: 15px 20px;
      margin: 10px 0;
      background-color: $color5;
      font-size: 1.4rem;
      color: var(--dark);
      max-width: 60%;
      @media (--mobile) {
        width: 100%;
        max-width: 100%;
      }
    }
    blockquote.align-right {
      float: right;
    }
    blockquote.align-left {
      float: left;
    }
  }
  &__factbox {
    background-color: var(--primary-background-color-grey);
    border-radius: var(--rounded-corners);
    padding: 9px 15px 20px 15px;
    float: left;
    margin: 10px 30px 20px 0;
    font-family: var(--brand-font);
    color: var(--standard-text-color);
    min-width: 46%;
    max-width: calc(50% - 40px);
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    .design-billigvvs & {
      background-color: var(--offset-of-brand-color-lighter);
    }
    .design-greenline & {
      background-color: #f5f5f5;
    }
    @media only screen and (max-width: 500px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin: 5px 0 15px 0;
      min-height: auto;
    }
    @media (--mobile) {
      font-size: 15px;
      line-height: 25px;
      border-radius: var(--rounded-corners-sm);
    }
    h3 {
      font-size: 16px;
      line-height: 26px;
    }
    h4 {
      color: var(--primary-color);
      margin: 10px 0 10px 50px;
      font-size: 1.5rem;
      .design-vvskupp & {
        color: var(--dark);
      }
      @media (--tablet-sm) {
        font-size: 1.4rem;
        margin-top: 12px;
      }
      @media (--mobile) {
        margin-left: 40px;
        margin-top: 8px;
        font-size: 1.3rem;
      }
      p {
        margin: 0;
        display: none;
      }
    }
    p {
      font-size: 16px;
      line-height: 26px;
      @media (--mobile) {
        font-size: 15px;
        line-height: 25px;
      }
    }
    a {
      color: $color1;
      &:hover {
        color: #005078;
        text-decoration: underline;
      }
    }
    ul {
      line-height: 1.8rem;
      padding-left: 25px;
      color: var(--standard-text-color);
      margin: 0;
    }
    ul li {
      list-style-type: square;
      color: var(--standard-text-color);
      font-weight: var(--font-weight-regular);
      position: relative;
      display: table-row;
      font-size: 16px;
      line-height: 32px;
      font-family: var(--default-font);
      &:before {
        color: var(--primary-color);
        content: "\25AA\fe0e";
        display: table-cell;
        text-indent: -15px;
        position: absolute;
      }
      @media (--mobile) {
        font-size: 15px;
        line-height: 30px;
      }
    }
  }
  &__factbox.align-right {
    float: right;
    margin: 10px 0 20px 20px;

  }
  &__guide {
    background-color: var(--primary-background-color-grey);
    border-radius: var(--rounded-corners);
    padding: 9px 15px 15px;
    float: left;
    margin: 10px 15px 15px 0;
    font-family: var(--brand-font);
    color: var(--standard-text-color);
    min-width: 40%;
    max-width: calc(50% - 30px);
    text-align: left;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    .design-billigvvs & {
      background-color: var(--offset-of-brand-color-lighter);
    }
    .design-greenline & {
      background-color: #f5f5f5;
    }
    @media (--mobile) {
     border-radius: var(--rounded-corners-sm);
    }
    @media (--mobile-md) {
      width: 100%;
      max-width: 100%;
      margin: 10px 0;
      min-height: auto;
      font-size: 15px;
      line-height: 25px;
    }
    h3 {
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
    h4 {
      color: var(--primary-color);
      margin: 10px 0 10px 50px;
      font-size: 1.5rem;
      .design-vvskupp & {
        color: var(--dark);
      }
      @media (--tablet-sm) {
        font-size: 1.4rem;
        margin-top: 12px;
      }
      @media (--mobile) {
        margin-top: 8px;
        font-size: 1.3rem;
      }
      p {
        margin: 0;
        display: none;
      }
    }
    p {
      font-size: 16px;
      line-height: 26px;
      @media (--mobile) {
        font-size: 15px;
        line-height: 25px;
      }
    }

    a {
      color: $gColorFont1;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
        color: $gColorFont1;
      }
      img {
        border-radius: 4px;
        margin: 5px 0 10px 0;
        width: 100%;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  &__guide.align-right {
    float: right;
    margin: 2% 0% 2% 3%;
    @media only screen and (max-width: 500px) {
      float: none;
      margin: 20px 0;
    }
  }
  &__guide__img {
    border-radius: 4px;
    margin: 5px 0 10px 0;
    width: 100%;
  }
  &__guide__arrow svg {
    height: 18px;
    width: 18px;
    margin: 0 0 -3px 5px;
    display: inline-block;
    .icon-stroke {
      stroke: var(--primary-color)
    }
  }
  &__image {
    background-position: center;
    height: 395px;
    border-radius:var(--rounded-corners);
    background-repeat: no-repeat;
    background-size: cover;
    align-items: flex-end;
    display: flex;
    padding: 15px;
    margin: 20px 0;
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    overflow: hidden;
    position: relative;
    @media (--mobile) {
      border-radius:var(--rounded-corners-sm);
    }

    &__caption {
      background-color: var(--brand-color);
      font-family: var(--brand-font);
      color: var(--color-on-brand-color);
      border-radius: var(--rounded-corners-sm);
      position: absolute;
      left: 0;
      margin: 10px;
      width: fit-content;
      padding: 5px 15px;
      font-size: 14px;
      letter-spacing: var(--letter-spacing-xs);
      word-spacing: var(--word-spacing-xs);
      text-transform: var(--text-uppercase-on-splash-notification);
      max-width: 100%;
      font-weight: var(--brand-font-weight);
      a {
        text-decoration: none;
        color: var(--color-on-brand-color);
        &:hover {
          text-decoration: underline;
        }
      }
      @media (--mobile) {
        font-size: 12px;
      }
      @media (--mobile-sm) {
        font-size: 10px;
      }
    }
  }
  &__content__1-column blockquote {
    max-width: 33%;
  }
  &__blockquote {
    clear: both;
    background-color: $color1;
    height: 50px;
    width: 50px;
    border-radius: var(--rounded-corners-circle-or-square);
    margin: 5px 10px 0 0;
    color: $gColorFont1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @media (--mobile) {
      margin: 0 auto 5px auto;
      height: 30px;
      width: 30px;
    }
    svg {
      height: 24px;
      width: 24px;
      fill: var(--color-on-brand-color);
      .design-completvvs & {
        fill: var(--brand-color);
      }
      @media (--mobile) {
        height: 14px;
        width: 14px;
      }

  .icon-stroke {
    stroke: var(--color-on-brand-color);
    .design-completvvs & {
      fill: var(--brand-color);
    }
    stroke-width: 0;
  }
    }
    &__text {
      width: calc(100% - 57px);
      @media (--mobile) {
        width: 100%;
      }
    }
  }
  &__focusproduct-box {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    line-height: 1.2;
    float: left;
    @media (--mobile-sm) {
      float: none;
      width: 100%;
      margin-left: 0 !important;
      margin-right: 0 !important;
      display: flex;
      justify-content: center;
      .new-productbox {
        width: 100%;
      }
    }

    ul {
      display: flex;
      list-style: initial;
      padding: 0;
      overflow: visible;
      @media (--tablet-and-above) {
        margin: 0 0 -10px 0 !important;
      }
      @media (--mobile) {
        margin: 0;
      }

    }
  }
  &__focusproduct-box.align-right {
    float: right;
    margin: 15px 0 15px 30px;
    @media (--tablet-sm) {
      margin: 15px 0 15px 20px;
    }
    @media (--mobile) {
      margin: 8px 0 8px 15px;
    }
  }
  &__focusproduct-box.align-left {
    float: left;
    margin: 15px 30px 15px 0;
    @media (--tablet-sm) {
      margin: 15px 20px 15px 0;
    }
    @media (--mobile) {
      margin: 8px 15px 8px 0;
    }
  }
  &__content__1-column .embed-responsive {
    margin: 0.5% 1.5% 1% 0;
    float: left;
    width: 48.5%;
    padding-bottom: 24%;
    clear: inherit;
    @media (--mobile) {
      margin: 2% 0 3% 0;
      width: 100%;
      float: none;
    }
  }
  &__textbox {
    background-color: var(--primary-background-color);
    padding: 30px 40px;
    max-width: 66%;
    border-radius: var(--rounded-corners);
    float: left;
    margin: 10px 30px 15px -28%;
    @media (--tablet-lg) {
      margin-left: -20%;
    }
    @media (--tablet-sm) {
      margin-left: 0;
      max-width: 49%;
      padding: 20px;
    }
    @media (--mobile) {
      max-width: 100%;
      width: 100%;
      float: none;
      margin: 5px 0 15px;
      padding: 15px;
      border-radius: var(--rounded-corners-sm);
    }
  }
  &__textbox.bgcolor {
    background-color: $color1;
    color: $color5;
    h1,
    h2,
    h3,
    h4,
    p,
    a {
      color: $color5 !important;
    }

    a:hover,
    a:focus {
      color: darken($color5, 10%) !important;
    }
  }

  &__textbox.align-right {
    margin: 10px -28% 15px 30px;
    float: right;
    @media (--tablet-lg) {
      margin-right: -20%;
      max-width: 66%;
    }
    @media (--tablet-sm) {
      margin-right: 0;
      max-width: 49%;
      padding: 20px;
    }
    @media (--mobile) {
      max-width: 100%;
      width: 100%;
      float: none;
      margin: 5px 0 15px;
    }
  }

  &__highligted-text {
    background-color: transparent;
    padding: 0 0 10px 0;
    h1,
    h2,
    h3,
    h4,
    p {
      display: inline;
      background: $color1;
      padding: 0;
      color: $color5;
    }
    p {
      font-size: 16px;
      line-height: 26px;
      @media (--tablet-sm) {
        font-size: 15px;
        line-height: 25px;
      }
      @media (--mobile) {
        font-size: 15px;
        line-height: 25px;
      }
    }
  }
  &__textbanner {
    width: 100%;
    background: transparent;
    padding: 60px 5% 50px 5%;
    text-align: center;
    clear: both;
    overflow: hidden;

    @media (--tablet-sm) {
      padding: 40px 2% 30px 2%;
    }
    @media (--mobile) {
      padding: 15px 30px;
    }
    h2 {
      font-weight: var(--brand-font-weight);
      padding: 0 30px;
      @media (--tablet) {
        line-height: 38px;
      }
      @media (--mobile) {
        line-height: 34px;
      }
    }
  }

}

.design-billigvvs.article, .design-greenline.article {
  .cms-related_articles {

      background-color: var(--primary-background-color-grey);
    }
    
  }

.faq {
  &__block {
    h2 {
      text-align: center;
    }
  }
  &__column {
    width: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0 40px;
    position: relative;
    height: fit-content;
    @media (--tablet-lg) {
      padding: 0 30px;
    }
    @media (--tablet-sm) {
      padding: 0 15px;
    }
    @media (--mobile) {
      padding: 0 5px 0 0;
    }

    @media (--mobile-md) {
      width: 100%;
      padding: 0;
    }
    &__wrapper {
      display: flex;
      width: 100%;
      justify-self: center;
      @media (--mobile-md) {
        flex-direction: column;
      }
    }
    &--second {
      @media only screen and (max-width: 960px) and (min-width: 768px) {
        padding: 0 0 0 5px;
        .faq__line {
          left: 24px;
          height: calc(100% - 45px);
        }
      }
      @media (--mobile) {
        .faq__line {
          height: calc(100% - 45px);
        }
      }
    }
    li {
      padding: 25px 45px 20px 15px;
      background-color: $color5;
      border-radius: var(--rounded-corners);
      margin-bottom: 30px;
      @media (--tablet-lg) {
        padding: 20px 35px 15px 15px;
        margin-bottom: 15px;
      }
      @media (--tablet-sm) {
        padding: 20px 35px 15px 15px;
        margin-bottom: 10px;
      }
      @media (--mobile) {
        padding: 15px 30px 10px 10px;
        margin-bottom: 10px;
        border-radius: var(--rounded-corners-sm);
      }
    }
  }
  &__line {
    border-right: 2px solid $color1;
    height: calc(100% - 110px);
    left: 70px;
    position: absolute;
    top: 50px;
    z-index: 1;

    @media (--tablet-lg) {
      left: 60px;
      height: calc(100% - 95px);
    }
    @media (--tablet-sm) {
      left: 45px;
      height: calc(100% - 90px);
    }
    @media (--mobile) {
      left: 19px;
      height: 100%;
      top: 20px;
    }
    @media only screen and (max-width: 960px) and (min-width: 768px) {
      height: calc(100% - 45px);
    }
  }
  &__icon {
    background-color: $color1;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color5;
    font-weight: 700;
    z-index: 2;
    margin: 3px 0 0 0;
    @media (--mobile) {
      height: 20px;
      width: 20px;
      font-size: 10px;
      margin: 5px 0 0 0;
    }
  }
  &__question {
    flex: 1 1 0%;
    margin-left: 15px;
    @media (--mobile) {
      margin-left: 10px;
    }
    &__wrapper {
      align-items: flex-start;
      display: flex;
    }
  }
  &__answer {
    width: 100%;
    height: auto;
    padding-left: 45px;

    img {
      margin-bottom: 15px;
      border-radius: var(--rounded-corners);
      @media (--mobile) {
      border-radius: var(--rounded-corners-sm);
      }
    }
    @media (--mobile) {
      padding-left: 30px;
      img {
        margin-bottom: 10px;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
}

@media (--mobile-md) {
  blockquote {
    width: 100%;
    max-width: 100%;
  }
}
