.footer {
  &__top {
    background-color: var(--primary-color);
    .row {
      justify-content: center;
    }
  }
  &__bot {
    @media (--tablet-and-above) {
      padding: 10px 0 5px;
    }
  }

  &__list-item,
  &__nav-link,
  &__news-letter,
  .heading {
    color: $colorFont3;
  }
  .heading {
    &--news-letter {
      font-size: 30px;
      @media (--mobile) {
        font-size: 25px;
      }
    }
  }
  &__contact--icon svg {
    stroke-width: 2px;
  }
  &__news-letter {
    border: var(--border-thin) color-mix(in srgb, var(--primary-color), #000 20%);
    padding: 25px 25px 20px;
    text-align: center;
    line-height: 24px;
    border-radius: 14px;
    .btn {
      margin: 10px auto;
      display: flex;
      justify-content: center;
      padding: 10px 20px;
      border-radius: 100px;
      color: $color5;
      text-transform: var(--title-uppercase);
      font-size: 12px;
      transition: var(--standard-transition-all);
      max-width: none;
      cursor: pointer;
      background-color: var(--offset-of-brand-color-darker);
      @media (--mobile) {
        padding: 11px 25px;
      }
      &:hover {
        background-color: var(--hover-of-primary-color);
      }
    }
    @media (--mobile) {
      padding: 10px 15px 10px;
      margin: 0 0 15px 0;
      border-radius: 0;
      border: none;
    }
  }
  &__text {
    margin-bottom: 25px;
    @media (--mobile) {
      font-size: 14px;
      line-height: 1.5em;
      margin-bottom: 15px;
    }
  }
  &__logo {
    margin: 5px 15px 5px 0;
    display: block;

    body.shop-vvsochbad-se & {
      margin: 0 15px 0 0;
    }
    @media (--mobile) {
      margin: 10px 0px 10px 0px;

    }
  }
  &__logo-img {
    width: 130px;
    height: 30px;
    body.shop-vvsochbad-se & {
      width: 150px;
      height: 37px;
    }
    @media (--mobile) {
      width: 120px;
      height: 30px;
    }
  }
}

//Mobile
@media (--mobile) {
  .footer {
    &__list {
      border-bottom: var(--border-thin) color-mix(in srgb, var(--primary-color), #000 20%);
    }
    &__nav-icon {
   .icon-stroke {
    stroke: var(--color-on-brand-color);
  }
    }
  }
}
