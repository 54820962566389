.missing-consent {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  padding: 15px;
  @media (--mobile) {
    font-size: 14px;
  }
  &__header {
    padding: 10px 0;
    text-align: center;
  }

  &__list {
    padding: 0;
  }
  ul {
    list-style-type: none;
    font-weight: 700;
  }
  .btn {
    margin: 0;
  }
}
