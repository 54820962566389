// Colors
$color1: #006496; //main color Blue       RGB: 0,100,150
$color2: #e5f0f5; //LightBlue
$color3: #96c300; //Green      RGB: 150,195,0
$color4: #e8f1d7; //LightGreen
$color5: #ffffff; //White
$color6: #4895b5; //hover Blue
$color7: #787979; //hover black
$color8: #0276af; //secondary main color
$color9: #005078; //new hover of main color
$color10: #a3d404; //light green hover
$color11: #000000; // black

// Backgrounds
$colorBg1: #f5f5f5; // Lightgrey - Body color - Primary grey
$colorBg2: #ffffff; // White - Product bg
$colorBg3: #ebebeb; // Grey - aside filters
$colorBg4: #96c300; //changed old yellow color #fee238
$colorBg5: #7ea832; //btn hover gradient
$colorBg6: #96c300; // green
$colorBg7: #000000; // black
$colorBg8: #96c300; // green
$colorBg9: #a3d404; // lighter green
$colorBg10: #eeeeee; // Secondary grey
$colorBg11: #e1e1e1; // Tertiary grey

// Borders
$colorBorder1: #d6d5d5; // Grey
$colorBorder2: #d9d9d9; // Dark(er) grey
$colorBorder3: #c6c6c6; // Dark grey (search inupt)
$colorBorder4: #2e87ab; // Lightblue
$colorBorder5: #000000; // Darkest
$colorBorder6: #000000; // Darkest
$colorBorder11: #c5cea7; // Very light green

$borderThin: 1px solid;
$borderMedium: 2px solid;
$borderThick: 3px solid;

// Font
$colorFont1: #363636; // primary - grey
$colorFont2: #363636; // blackish
$colorFont3: #ffffff; // White (productlist btn)
$colorFont4: #666666; // lightgrey - footer usp  hover on links

$fontFamilyOswald: "Oswald", sans-serif;
$fontFamilyArial: Arial, sans-serif;
