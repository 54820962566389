@import "~styles/utils/media-queries";


  .design-billigvvs.productcard {
    .review-slider-wrapper {
      background-color: var(--primary-background-color);
    
  }
}
  .design-lampeguru.productcard {
    .review-slider-wrapper {
    background-color: var(--tertiary-background-color);
  }
}

  .design-greenline.productcard {
    .review-slider-wrapper {
    background-color: var(--primary-background-color);
  }
}
  
  .design-completvvs.productcard, .design-lavpris.productcard {
    .review-slider-wrapper {
    @media (--tablet-and-above) {
      background-color: var(--primary-background-color);
    }
  }
  }

.review-slider-wrapper {
  .design-billigvvs & {
    height: 484px;
  }
  .design-lavpris & {
    height: 445px;
  }
  .design-greenline & {
    height: 492px;
  }
  .design-completvvs & {
    height: 508px;
  }
  .design-lampeguru & {
    height: 480px;
  }

  width: 100%;

  @media (--tablet-lg) {
    .design-billigvvs & {
      height: 460px;
    }
    .design-lavpris & {
      height: 430px;
    }
    .design-greenline & {
      height: 470px;
    }
    .design-completvvs & {
      height: 486px;
    }
    .design-lampeguru & {
      height: 455px;
    }
  }

  @media (--tablet-sm) {
    .design-billigvvs & {
      height: 440px;
    }
    .design-lavpris & {
      height: 405px;
    }
    .design-greenline & {
      height: 445px;
    }
    .design-completvvs & {
      height: 466px;
    }
    .design-lampeguru & {
      height: 433px;
    }
  }

  @media (--mobile) {
    .design-billigvvs & {
      height: 292px;
    }
    .design-lavpris & {
      height: 292px;
    }
    .design-greenline & {
      height: 306px;
    }
    .design-completvvs & {
      height: 302px;
    }
    .design-lampeguru & {
      height: 295px;
    }
  }
}
