.contact-block {
  &--checkout {
    .contact-block__icon--email,
    .contact-block__icon--phone {
      margin-top: 0px;
    }
    .contact-block__info {
      margin-top: 2px;
    }
    .contact-block__icon {
      float: left;
      border: 1px solid $gColorBorder1;
      fill: $color1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .contact-block__title-and-info {
      padding-top: 2px;
      padding-left: 5px;
      flex-direction: column;
      display: flex;
    }
  }
  &__block {
    float: left;
    margin: 0 20px;
    color: $gColorFont1;
    @media (--tablet) {
      body.shop-vvsochbad-se & {
        margin: 0 10px;
      }
    }
  }
  &__title {
    font-size: 18px;
    display: inline-block; //align-self: center;
  }
  &__info {
    font-size: 13px;
    display: inline-block;
  }
  &__title,
  &__info {
    margin-left: 5px;
    .contact-block__block--email & {
      margin-left: 0;
    }
  }
  &__icon {
    &--email {
      margin-top: 7px;

      @media (--tablet) {
        margin-top: 0;
      }
      @media (--mobile) {
        margin: 0;
      }
    }
  }
  &__icon-and-title {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .contact-block__icon {
      .contact-block__block--email & {
        margin-right: 5px;
      }
    }
  }
  &__title-and-info {
    padding-left: 10px;
    @media (--mobile) {
      padding-left: 8px;
    }
  }
}

@media (--mobile) {
  .contact-block {
    &--footer {
      width: 100%;
      margin: 40px 0 15px;
      .contact-block {
        &__info {
          margin-left: 15px;
          font-size: 16px;
        }
      }
    }
  }
}
