@import "~styles/utils/media-queries";

.container {
  width: 100%;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;

  @media (--tablet-sm-and-less) {
    padding: 15px;
  }
}
