.landingpage {
  &__topbanner,
  &__videotopbanner {
    background-color: $color2;
    text-align: left;
    &__caption {
      h1,
      .heading--1,
      p {
        text-align: left;
        margin-left: 0;
        margin-right: 0;
      }

    }
    &__button {
      margin-left: 0;
      margin-right: 0;
      text-align: left;
    }

    width: 100%;
  }
}
