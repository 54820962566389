#ao-internal-icon {
  position: fixed;
  right: 10px;
  bottom: 10px;
  height: 50px;
  width: 50px;
  z-index: 1000000;
  background-color: #444;
  border-radius: 25px;
  border: #333333 solid 2px;
  box-shadow: 1px 1px 6px #333;
  opacity: 0.6;
  display: block;
}
#ao-internal-icon:hover {
  cursor: pointer;
  opacity: 1;
}
#ao-internal-icon svg {
  margin-top: 7px;
  margin-left: 7px;
  fill: white;
}
