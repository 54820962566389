@import "~styles/utils/media-queries";

.wrapper {
  position: relative;

  ::placeholder {
    color: var(--muted);
    opacity: 1;

    :global(.design-vvskupp) & {
      color: var(--white);
    }
  }
}

.input {
  padding: 0 50px 0 20px;
  border-radius: var(--rounded-corners);
  width: 100%;
  background-color: var(--primary-background-color);
  border: var(--border-thin) var(--primary-background-color);
  outline: none;
  font-size: 15px;
  height: 48px;
  font-weight: var(--font-weight-regular);

  :global(.design-completvvs) & {
    border-bottom: var(--border-thin) var(--border);
  }

  :global(.design-vvskupp) & {
    background-color: var(--offset-of-brand-color);
    border-color: var(--offset-of-brand-color);
    color: var(--color-white);

    &:active,
    &:focus {
      background-color: var(--white);
      color: var(--standard-text-color);
      border-color: var(--secondary-color);

      :global(.icon-stroke) {
        stroke: var(--dark) !important;
      }
    }
  }

  @media (--mobile) {
    padding: 0 45px 0 15px;
    margin: 2px 0;
    font-size: 14px;
    height: 44px;
    border-radius: var(--rounded-corners-sm);
  }

  @media (--mobile-sm) {
    font-size: 13px;
  }
}

.input:focus {
  border: var(--border-thin) var(--primary-color);

  :global(.design-billigvvs) & {
    border-color: var(--secondary-color);
  }

  :global(.design-completvvs) & {
    border-top: none;
    border-left: none;
    border-right: none;
  }
}

.magnifying-glass {
  position: absolute;
  width: 38px;
  height: 38px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  border: 0;
  transform: translateY(-50%);
  z-index: 1;
  border-radius: var(--rounded-corners);
  cursor: pointer;
  background-color: transparent;
  fill: transparent;
  padding: 0;

  :global(.icon-stroke) {
    :global(.design-billigvvs) & {
      stroke: var(--primary-color);
    }

    :global(.design-vvskupp) & {
      stroke: var(--white);
      background-color: transparent;
    }
  }

  &:hover,
  .wrapper:focus-within & {
    background-color: var(--primary-color);
    fill: var(--color-on-primary-color);

    :global(.design-billigvvs) & {
      background-color: var(--secondary-color);
      fill: var(--color-on-secondary-color);
      stroke: var(--color-on-cta-color) !important;
    }

    :global(.design-lampeguru) & {
      background-color: var(--secondary-color);
      fill: transparent;
      stroke: var(--color-on-secondary-color);
    }

    :global(.design-vvskupp) & {
      background-color: transparent;
    }

    :global(.design-greenline) & {
      fill: transparent;
      stroke: var(--white);
    }

    :global(.icon-stroke) {
      stroke: var(--color-on-cta-color);
    }
  }

  @media (--mobile) {
    right: 5px;
    width: 34px;
    height: 34px;
  }
}
