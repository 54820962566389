.pay-methods {
  &--footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4px;
    @media (--mobile) {
      margin: 20px 0 10px 0;
      gap: 5px;
    }
  }
  &__icon {
    margin: 0 1px;
    width: 42px;
    height: 25px;
    @media (--mobile) {
      height: 22px;
    }
    &__nomargin {
      width: 53px;
      height: 32px;
      margin: 0 0 1px 5px;
      float: left;
      margin-left: 5px;
      @media (--mobile) {
        margin-bottom: 0;
      }
    }
  }
}
