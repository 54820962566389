.btn-black {
  background: $colorBg7;
  color: $colorFont3 !important;
  transition: all 0.4s ease;

  &:hover,
  &:focus,
  &:active {
    background-color: #494949;
    color: $colorFont3;
  }
}

.btn-outline {
  color: $colorFont1;
  transition: background-color 0.4s ease-out;
  border: 1px solid $colorFont1;
  padding: 5px 15px;

  &:hover,
  &:focus,
  &:active {
    background-color: $colorFont1;
    color: $colorFont3;
    transition: all 0.4s ease;
  }
}
