.product-card {
  &__related-article {
    position: relative;
    background-color: $colorBg1;
    text-align: center;
    height: 80dvh;
    width: 100%;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
    background-repeat: no-repeat;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    color: var(--white);
    padding: 40px;
    @media (--tablet-sm) {
      padding: 30px;
    }
    @media (--mobile) {
      padding: 15px;
      height: 70dvh;
      border-top: var(--border-thin) var(--border);
      background-attachment: initial;
    }

    img {
      position: absolute;
      overflow: hidden;
      height: 100%;
      width: 100%;
      object-fit: cover;
      top: 0;
      left: 0;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      bottom: 0;
      background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6));
      z-index: 1;
    }

    h2 {
      text-decoration: none;
      color: var(--white);
      font-size: 3em;
      line-height: 1.2em;
      @media (--tablet-lg) {
        font-size: 2.9em;
      }
      @media (--tablet-sm) {
        font-size: 2.7em;
      }
      @media (--mobile) {
        font-size: 2.5em;
      }
    }
    a {
      text-decoration: none;
      color: var(--white);

      p {
        font-size: 1.2em;
        line-height: 1.6em;
        &:hover {
          text-decoration: underline;
        }
        @media (--tablet-sm-and-less) {
          font-size: 1.1em;
        }
        @media (--mobile) {
          display: none;
        }
        @media (--mobile-xl) {
          display: block;
        }
      }
      .product-card__read-more {
        color: var(--white);
        font-size: 1.2em;
        margin: 0;
        @media (--tablet-sm-and-less) {
          font-size: 1.1em;
        }
      }
    }
    &__splash-tag {
      top: 40px;
      left: 40px;
      bottom: initial;
      background-color: var(--primary-color);
      @media (--tablet-sm) {
        top: 30px;
        left: 30px;
      }
      @media (--mobile) {
        top: 15px;
        left: 15px;
      }
    }
    &__image {
      margin-bottom: 20px;
      position: relative;
      display: block;
      transition: var(--standard-transition-all);
      opacity: 1;
      &:hover {
        img {
          opacity: 0.8;
        }
      }
    }

    &__text {
      max-width: 852px;
      z-index: 10;
      color: var(--white);
    }
  }
}
