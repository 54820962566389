@import "~styles/utils/media-queries";

.btn {
  color: var(--button-color);
  fill: var(--button-color);
  background-color: var(--button-background-color, transparent);
  min-height: max-content;
  height: min-content;
  border-color: var(--button-border-color, transparent);
  border-width: var(--button-border-width, 2px);
  border-style: solid;
  border-radius: var(--button-rounded-corners-md);
  font-family: var(--default-font);
  font-weight: var(--button-font-weight);
  font-size: var(--button-font-size);
  padding: 4px var(--button-padding);
  line-height: normal;
  position: relative;
  text-align: center;
  transition:
    border var(--standard-transition),
    background-color var(--standard-transition),
    color var(--standard-transition),
    fill var(--standard-transition);
  text-decoration: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--button-gap, 10px);
  width: fit-content;
  --button-border-width: 2px;

  @media (--mobile) {
    border-radius: var(--button-rounded-corners-sm);
  }

  .icon-stroke {
    stroke: var(--button-color);
    stroke-width: 1.5px;
  }

  .design-lampeguru & {
    border-width: 1px;
  }

  &:hover {
    color: var(--button-color);
    background-color: var(--button-background-color, transparent);
    border-color: var(--button-border-color, transparent);

    .icon-stroke {
      stroke: var(--button-color);
    }
  }

  &-lg {
    --button-gap: var(--spacing-300);
    --button-border-width: 3px;
    --button-font-size: 18px;
    --button-icon: var(--button-font-size);

    min-height: var(--height-input-lg);
    padding-left: var(--spacing-500);
    padding-right: var(--spacing-500);

    @media (--new-desktop-sm) {
      --button-border-width: 2px;
      --button-font-size: 17px;
    }

    @media (--new-tablet) {
      --button-font-size: 16px;
      --button-border-width: 2px;
    }

    @media (--new-mobile) {
      --button-font-size: 15px;
      --button-border-width: 2px;
    }
  }

  &-md {
    --button-font-size: 16px;
    --button-gap: var(--spacing-300);
    --button-icon: var(--button-font-size);

    min-height: var(--height-input-md);
    padding-left: var(--spacing-400);
    padding-right: var(--spacing-400);

    @media (--new-tablet) {
      --button-font-size: 15px;

      border-radius: var(--button-rounded-corners-sm);
    }

    @media (--new-mobile) {
      --button-font-size: 14px;

      border-radius: var(--button-rounded-corners-sm);
    }
  }

  &-sm {
    --button-font-size: 14px;
    --button-gap: var(--spacing-200);
    --button-icon: var(--button-font-size);

    min-height: var(--height-input-sm);
    padding-left: var(--spacing-300);
    padding-right: var(--spacing-300);
    border-radius: var(--button-rounded-corners-sm);

    @media (--new-tablet) {
      --button-font-size: 13px;

      border-radius: var(--button-rounded-corners-sm);
    }

    @media (--new-mobile) {
      --button-font-size: 12px;
    }
  }

  &-xs {
    --button-gap: var(--spacing-100);
    --button-font-size: 12px;
    --button-icon: calc(var(--button-font-size) - 2px);

    min-height: var(--height-input-xs);
    padding: 2px var(--spacing-200);
    border-radius: var(--button-rounded-corners-sm);

    @media (--new-tablet) {
      --button-font-size: 11px;

      border-radius: var(--button-rounded-corners-xs);
    }

    @media (--new-mobile) {
      --button-font-size: 11px;

      border-radius: var(--button-rounded-corners-xs);
    }
  }

  &-cta {
    --button-color: var(--color-on-cta-color);
    --button-background-color: var(--cta-color);
    --button-border-color: var(--cta-color);
    --button-border-width: 0 !important;

    &:hover {
      --button-color: var(--color-on-cta-hover-color);
      --button-background-color: var(--cta-hover-color);
      --button-border-color: var(--cta-hover-color);
    }
    &:active,
    &:focus {
      color: var(--color-on-cta-color);
      outline: none;
    }
  }

  &-primary {
    --button-color: var(--white);
    --button-background-color: var(--primary-color);
    --button-border-color: var(--primary-color);

    .design-lampeguru & {
      --button-color: var(--color-on-brand-color);
      --button-background-color: var(--brand-color);
      --button-border-color: var(--brand-color);
    }

    &:hover {
      --button-color: var(--white);
      --button-background-color: var(--hover-of-primary-color);
      --button-border-color: var(--hover-of-primary-color);
      .design-lavpris & {
        --button-background-color: color-mix(in srgb, var(--cta-color), var(--white) 15%);
        --button-border-color: color-mix(in srgb, var(--cta-color), var(--white) 15%);
      }
      .design-lampeguru & {
        --button-color: var(--color-on-brand-color);
        --button-background-color: var(--hover-of-brand-color);
        --button-border-color: var(--hover-of-brand-color);
      }
    }
  }

  &-brand {
    background-color: var(--primary-color);
    color: var(--color-on-primary-color);

    .design-lavpris & {
      background-color: var(--brand-color);
      color: var(--color-on-brand-color);
    }

    &:hover,
    &:active,
    &:focus {
      background-color: var(--hover-of-primary-color);
      color: var(--color-on-primary-color);
      .design-lavpris & {
        background-color: var(--hover-of-brand-color);
        color: var(--color-on-primary-color);
      }
    }
  }

  &-blackBg {
    background: var(--dark);
    color: var(--white);
    transition: var(--standard-transition-all);

    &:hover {
      background-color: color-mix(in srgb, var(--dark), black 10%);
      color: var(--white);
    }

    &:active,
    &:focus {
      color: var(--white);
      outline: none;
    }
  }

  &-campaign {
    --button-color: var(--color-on-cta-color);
    --button-background-color: var(--cta-color);
    --button-border-width: 0 !important;

    .design-lavpris &, .design-greenline & {
      --button-color: var(--color-on-campaign-color);
      --button-background-color: var(--campaign-color);
      --button-border-width: 0 !important;
    }
    .design-lavpris & {
      font-family: var(--button-font);
    font-size: calc(var(--button-font-size)* 1.4);
  }

    &:hover, &:active,
    &:focus {
      --button-background-color: var(--cta-hover-color);
      --button-border-color: var(--cta-hover-color);
      color: var(--color-on-cta-color);
      outline: none;
      .design-lavpris &, .design-greenline & {
        background-color: color-mix(in srgb, var(--campaign-color), var(--white) 10%);
        color: var(--color-on-campaign-color);
      }
    }
  }

  &-blackBorder {
    border: var(--button-border-width) solid var(--dark);
    background: transparent;
    transition: var(--standard-transition-all);
    color: var(--dark);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--dark);
      color: var(--white);
      outline: none;
    }
  }

  &-whiteBorder {
    border: var(--button-border-width) solid var(--white);
    color: var(--white);
    transition: var(--standard-transition-all);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--white);
      color: var(--dark);
      outline: none;
    }
  }

  &-default {
    border: var(--border-medium) var(--dark);
    color: var(--dark);
    background-color: transparent;
    &:hover {
      color: var(--white);
      background-color: var(--dark);
      transition: var(--standard-transition-all);
      border-color: var(--dark);
    }

    &:focus {
      outline: none;
    }
  }

  &.disabled,
  &:disabled,
  &[disabled],
  &.disabled:hover,
  &:disabled:hover,
  &[disabled]:hover {
    background-color: var(--disabled-color);
    border-color: var(--disabled-color);
    color: var(--muted);
    cursor: initial;
    fill: var(--muted);

    .design-lampeguru & {
      background-color: var(--primary-grey);
      border-color: var(--primary-grey);
    }

    .icon-stroke {
      stroke: var(--muted);
    }
  }

  &-success {
    --button-color: var(--white);
    --button-background-color: var(--color-success);
    --button-border-color: var(--color-success);

    &:hover {
      --button-background-color: color-mix(in srgb, var(--color-success), #fff 10%);
    }
  }

  &-info {
    --button-color: var(--color-info-bg);
    --button-background-color: var(--color-info);
    --button-border-color: var(--color-info);

    &:hover {
      --button-color: var(--color-info-bg);
      --button-background-color: color-mix(in srgb, var(--color-info), #fff 10%);
      --button-border-color: color-mix(in srgb, var(--color-info), #fff 10%);
    }
  }

  &-warning {
    --button-color: var(--color-warning-bg);
    --button-background-color: var(--color-warning);
    --button-border-color: var(--color-warning);

    &:hover {
      --button-color: var(--color-warning-bg);
      --button-background-color: color-mix(in srgb, var(--color-warning), #fff 10%);
      --button-border-color: color-mix(in srgb, var(--color-warning), #fff 10%);
    }
  }

  &-error {
    --button-color: var(--color-error-bg);
    --button-background-color: var(--color-error);
    --button-border-color: var(--color-error);

    &:hover {
      --button-color: var(--color-error-bg);
      --button-background-color: color-mix(in srgb, var(--color-error), #fff 10%);
      --button-border-color: color-mix(in srgb, var(--color-error), #fff 10%);
    }
  }

  &.btn-fade {
    transition: var(--standard-transition-all);
  }
}
