.landingpage {
  &__campaign-banner,
  &__campaign-banner.a {
    text-align: center;
    height: auto;
    font: var(--text-header-md);
    font-family: var(--brand-font) !important;
    width: 100%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-100) var(--spacing-500);
    text-decoration: none;
    font-weight: var(--brand-font-weight) !important;
    text-transform: var(--title-uppercase);
    transition: var(--standard-transition-all);
    letter-spacing: var(--letter-spacing-sm);
    word-spacing: var(--word-spacing-sm);
    background-color: var(--highligted-background-color);
    color: var(--color-on-highligted-background-color);
    .design-completvvs &, .design-greenline & {
      background-color: var(--brand-color);
    }
    .design-lavpris & {
    font-family: var(--button-font) !important;
    font-size: 1.65em;
    line-height: normal;
    font-weight: var(--font-weight-regular) !important;
    }
    @media (--tablet-xs-and-less) {
      letter-spacing: var(--letter-spacing-xs);
      word-spacing: var(--word-spacing-xs);
      font: var(--text-header-sm);
      .design-lavpris & {
         font-size: 1.4em;
        
        }
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:hover {
      text-decoration: none;
      transition: var(--standard-transition-all);
      background-color: var(--hover-of-highligted-background-color);
      color: var(--color-on-highligted-background-color);
      .design-completvvs & {
        color: var(--color-white);
        background-color: var(--offset-of-brand-color-darker);
      }


      .design-greenline & {
        color: var(--color-white);
        background-color: var(--secondary-color);
        .landingpage__campaign-banner__arrow {
          background-color: var(--color-white);
         .icon-stroke  {
            stroke:  var(--secondary-color);
          }
      }
    }
  }

    &__info {
      display: flex !important;
      justify-content: space-between;
    }

    &__text {
      display: inline-block;
      text-align: center;
    }

    &__divider {
      background-color: $color11;
      width: 2px;
      display: inline-block;
      margin: 0 20px;
      padding: 5px 0;
      @media (--tablet) {
        padding: 3px 0;
      }
      @media (--mobile) {
        display: none;
      }
    }

    &__arrow {
      display: flex;
      margin: 0 0 0 10px;
      padding: 6px;
      background-color: transparent;
      border: none;
      
      border-radius: var(--rounded-corners-circle-or-square);

      .design-billigvvs & {
        background-color: var(--color-secondary-600);

      }

      .design-greenline & {
        border: var(--border-thin) var(--color-on-brand-color);
      }


      svg {
        height: 11px;
        width: 11px;
        transform: rotate(90deg);

        .design-completvvs &, .design-lampeguru &{
          width: 15px;
          height: 15px;

      }
        .design-lavpris & {
          display: none;
        }
        
.icon-stroke {
      stroke:  var(--color-on-highligted-background-color);
      stroke-width: 2;
    }

      }
      @media (--mobile) {
        display: none;
                }
    }
  }
}
