.react__fadein--200 {
  &-enter,
  &-appear {
    opacity: 0;
    &-done {
      opacity: 1;
    }
  }
  &-enter-active,
  &-appear-active {
    opacity: 1;
    transition: opacity 200ms ease-in;
  }
  &-exit {
    opacity: 1;
  }
  &-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in;
  }
}
.react__blurin--350 {
  &-enter,
  &-appear {
    opacity: 0;
    filter: blur(10px);
    &-done {
      opacity: 1;
      filter: blur(0px);
    }
  }
  &-enter-active,
  &-appear-active {
    opacity: 1;
    filter: blur(0px);
    transition: filter 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 350ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  &-exit {
    opacity: 1;
    filter: blur(0px);
  }
  &-exit-active {
    opacity: 0;
    filter: blur(10px);
    transition: filter 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 350ms cubic-bezier(0.4, 0, 0.2, 1);
  }
}
.react__slidein__right--250 {
  overflow-x: hidden;
  &-enter,
  &-appear {
    transform: translate3d(100%, 0, 0);
    &-done {
      transform: translate3d(0, 0, 0);
    }
  }
  &-enter-active,
  &-appear-active {
    transform: translate3d(0, 0, 0);
    transition: transform 250ms ease-in-out;
  }
  &-exit {
    transform: translate3d(0, 0, 0);
  }
  &-exit-active {
    transform: translate3d(100%, 0, 0);
    transition: transform 250ms ease-in-out;
  }
  &-exit-done {
    transform: translate3d(100%, 0, 0);
  }
}

.react__slidein__left--250 {
  $from: translate3d(-100%, 0, 0);
  $to: translate3d(0, 0, 0);
  overflow-x: hidden;
  &-enter,
  &-appear {
    transform: $from;
    &-done {
      transform: $to;
    }
  }
  &-enter-active,
  &-appear-active {
    transform: $to;
    transition: transform 250ms ease-in-out;
  }
  &-exit {
    transform: $to;
  }
  &-exit-active {
    transform: $from;
    transition: transform 250ms ease-in-out;
  }
  &-exit-done {
    transform: $from;
  }
}
