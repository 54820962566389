.contact-block {
  &--checkout {
    display: inline-block;
    padding-top: 5px;
    @media (--mobile) {
      display: none;
    }

  }
  &__link {
    display: inline-block;
    text-decoration: none;
    &:hover {
      .contact-block__title-and-info {
        text-decoration: underline;
      }
      .contact-block__icon {
        border-color: $color1;
        background-color: $color1;
        svg {
          fill: $color5;
          .design-greenline & {
            stroke: var(--white);
            }
        }
      }
    }
    &:focus {
      color: var(--standard-text-color);
    }
  }
  &__icon {
    height: 40px;
    width: 40px;
    border-radius: var(--rounded-corners-circle-or-square);
    stroke: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .design-greenline & {
    stroke-width: 1.5px;
    stroke: var(--dark);
    }

    svg {
      height: 24px;
      width: 24px;
    }
  }
  &__title {
    font-family: var(--brand-font);
    text-transform: var(--minor-header-uppercase);
    font-weight: var(--brand-font-weight);
  }

  &--mobile-menu {
    color: $gColorFont3;
    border-bottom: 1px solid $gColorBorder3;
    margin: 0 -30px;
    padding: 5px 30px;
    a {
      text-decoration: none;
    }
    .contact-block {
      &__block {
        color: $gColorFont3;
        max-width: 100%;
        float: none;
        margin: 0;
        display: flex;
        align-items: center;
        margin: 15px 0;
      }
      &__icon {
        fill: $gColorFont3;
        background-color: transparent;
        border: none;
      }
      &__icon-and-title {
        display: inline-block;
        vertical-align: middle;
        color: var(--standard-text-color);
        .contact-block__title {
          margin-left: 15px;
        }
      }
      &__info {
        margin-left: 5px;
        font-size: 0.8rem;
        align-self: flex-end;
        line-height: 1em;
        color: var(--standard-text-color);
      }
      &__title {
        font-weight: 300;
        color: var(--standard-text-color);
      }
    }
  }
}
@media (--mobile) {
  .contact-block {
    &--footer {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-self: center;
      margin: 10px 0 10px;

      .contact-block {
        &__block {
          max-width: 100%;
          margin: 5px 0 5px;
          float: none;
          text-align: center;
        }
        &__icon-and-title {
          display: inline-block;
          white-space: nowrap;
          .contact-block__title {
            margin-left: 20px;
          }
        }
        &__title-and-info {
          display: inline-block;
          white-space: nowrap;
        }
        &__title,
        &__info {
          align-self: flex-end;
          line-height: 1em;
        }
        &__info {
          margin-left: 15px;
        }
        &__icon {
          &--phone {
            position: relative;
            top: 5px;

            float: none;
          }
        }
      }
    }
  }
}
