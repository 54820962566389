.design-vvskupp.article .main-container,
.design-completvvs.article .main-container,
.design-lavprisvvs.article .main-container,
.design-lampeguru.article .main-container {
  @media (--tablet-and-above) {
background-color: var(--secondary-background-color);
  }
}


.article.container {
  @media (--tablet-lg) {
  padding-left: 35px;
  padding-right: 35px;
}
@media (--tablet-sm) {
  padding-left: 30px;
  padding-right: 30px;
}
}

.article {
  @media (--mobile) {
    background-color: var(--white);
  }
  .font-22 {
    font-size: 22px;
  }
  .productlist-footer {
    max-height: none;
    overflow: initial;
    background-color: $color5;
    padding-left: 0;
    padding-right: 0;
    max-width: initial;
  }

  figure {
    margin: 0;
    border-radius: var(--rounded-corners-only-top);
    background-color: $gColorFont3;
    overflow: hidden;
    position: relative;
  }
  .a-flex {
    display: flex;
    flex-wrap: wrap;
    .a-col {
      flex-grow: 1;
    }
  }
  .a-col {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin-bottom: 10px;
    line-height: normal;
    transition: all 0.4s ease;
    .a-block {
      color: $gColorFont4;
      .a-icon {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
        &--youtube {
          width: 100px;
          height: 90px;
          fill: $color5;
          &:hover {
            opacity: 0.7;
          }
        }
      }
      h1,
      h2,
      h3 {
        color: var(--dark);
        margin-bottom: 10px;
        @media (--mobile) {
          margin-bottom: 5px;
        }
      }
      h3 {
        letter-spacing: var(--letter-spacing-sm);
        word-spacing: var(--word-spacing-sm);
        text-transform: var(--title-uppercase);
      }
      .heading {
        color: $gColorFont4;
        margin-bottom: 15px;
      }

      ul {
        margin: 0 0 20px;
        list-style-type: disc;
        padding: 0 0 0 20px;
        li {
          padding: 0;
          margin: 0 0 20px;
          @media (--mobile) {
            font-size: 15px;
            margin: 0 0 10px;
          }
        }
      }
      @media (--mobile) {
        .font-large {
          font-size: 30px !important;
        }
      }
    }
  }
  .a-right {
    text-align: right;
  }
  .a-center {
    text-align: center;
  }
  .a-spacebetween {
    justify-content: space-between;
  }
  .tags {
    text-align: center;
    margin-bottom: 20px;
    .tag {
      border: 1px solid #ebebeb;
      background: #f5f5f5;
      color: #6e6e6e;
      margin-left: 10px;
      font-weight: normal;
      &-active {
        background: $gColorFont6;
        color: $color5;
      }
    }
  }
  .article-list {
    @media (--desktop) {
      margin-left: -10px;
      margin-right: -10px;
    }
    @media (--mobile) {
      margin: 0 -5px;
    }

    .article-list-item {
      text-decoration: none;
      background-color: transparent;
      &:hover {
        .a-col {
          box-shadow: 0 2px 10px -2px #bbb;
        }
        .article-image {
          opacity: 0.8;
        }
        .read-more {
          text-decoration: underline;
        }
        .read-more-arrow {
          fill: $color5;
          background-color: $color1;
        }
      }
      .a-col {
        background: $color5;
        border-radius: var(--rounded-corners);
        border: var(--border-thin) var(--border);
        margin: 0 5px 15px 5px;
        @media (--tablet-lg) {
          margin: 0 5px 10px 5px;
        }
        @media (--tablet-sm) {
          margin: 0 0 10px 0;
        }
        @media (--mobile) {
          margin: 0 0 10px 0;
        }
      }
      .article-list-text {
        padding: 30px;
        @media (--tablet) {
          padding: 20px;
        }
        @media (--mobile) {
          padding: 15px;
        }
      }
      .tags {
        height: 20px;
        width: 95%;
        overflow: hidden;
        position: absolute;
        bottom: 0;
      }
    }
    .a-block {
      a {
        @media (--tablet) {
          height: 216px;
          overflow: hidden;
          display: block;
        }
        @media (--desktop) {
          height: 280px;
          overflow: hidden;
          display: block;
        }
      }
    }
    .article-image {
      width: 100%;
      height: auto;
      transition: all 0.4s ease;
      opacity: 1;
    }
  }

  .read-more {
    font-size: 12px;
    color: $color1;
    text-transform: uppercase;
    display: inline-flex;
    width: 100%;
    align-items: center;
  }
  .read-more-arrow {
    background-color: transparent;
    display: inline-block;
    padding: 0;
    height: 24px;
    width: 24px;
    margin-left: 10px;
    fill: $color1;
    border-radius: var(--rounded-corners-circle-or-square);
    transition: all 0.2s ease;
    border: 1px solid $color1;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 10px;
      width: 10px;
      @media (--mobile) {
        height: 8px;
      width: 8px;
      }
    }
    @media (--mobile) {
      height: 20px;
      width: 20px;
    }
  }

  .cms-article_header,
  .cms-article_social_profile {
    z-index: 2;
  }

  .cms-article_header,
  .cms-article_social_profile,
  .cms-article_body,
  .cms-article_step_by_step,
  .cms-article_step_by_step_image,
  .cms-points,
  .cms-article_offer_banner {
    width: 972px;
    max-width: 972px;
    margin: 0 auto;
    padding: 30px 60px;
    background-color: var(--white);
    @media (--tablet-lg) {
      max-width: 941px;
    }
    @media (--tablet-sm) {
      width: 100%;
      max-width: 901px;
      padding: 30px 40px;
    }
    @media (--mobile) {
      width: 100%;
      max-width: 707px;
      padding: 15px;
    }
  }

  .cms-article_header:has(.cms-article_header__logo) {
    h1 {
      @media (--desktop) {
        padding-right: 50px;
      }
      @media (--tablet-lg) {
        padding-right: 75px;
      }
      @media (--tablet-sm) {
        padding-right: 85px;
      }
    }
  }

  .cms-article_body {
    padding-top: 0;
    padding-bottom: 0;
  }

  .cms-article_social_profile,
  .cms-article_step_by_step .cms-points,
  .cms-article_offer_banner {
    padding: 0;
    @media (--mobile) {
      width: 100%;
      max-width: 100%;
    }
  }

  .cms-article_step_by_step_image {
    padding: 0 60px;

    @media (--tablet-sm) {
      padding: 0 40px;
    }
    @media (--mobile) {
      padding: 0;
      max-width: 677px;
    }
  }

  .cms-article_social_profile {
    border-bottom: var(--border-thin) var(--border);
    border-top: var(--border-thin) var(--border);
  }
  &__points {
    text-align: center;
    .btn {
      margin-bottom: 35px;
      @media (--tablet-sm) {
        margin-bottom: 25px;
      }
      @media (--mobile) {
        margin-bottom: 20px;
      }
    }
  }
}

.a-form {
  background: $gColorFont3;
  overflow: hidden;
  padding: var(--spacing-400) !important;
  @media (--desktop) {
    padding-left: var(--spacing-500) !important;
    padding-right: var(--spacing-500) !important;
  }
  border-radius: var(--rounded-corners);
  border: var(--border-framing) var(--border);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .checkout__my-info-section {
    h4 {
      margin-bottom: 30px;
      @media (--mobile) {
        margin-bottom: 15px;
      }
    }
  }

  @media (--mobile) {
    padding: 10px;
  }

  .a-row {
    justify-content: center;
  }

  .must {
    font-size: 14px;
    color: var(--subdued-color);
    margin-bottom: 20px;
    text-align: left;
  }
}

.article .a-col .a-block .order {
  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }

  &__item--header {
    font-weight: bold;
    padding: 15px 25px 10px;
    margin: 0;

    @media (--mobile) {
      padding: 10px 5px;
    }
  }

  &__item {
    background: var(--white);
    padding: 15px 25px;
    margin: 0 0 15px 0;
    border-radius: var(--rounded-corners);
    border: var(--border-thin) var(--border);
    &:last-of-type {
      margin: 0 0 60px 0;
    }
    @media (--tablet) {
      margin: 0 0 10px 0;
      padding: 15px;
      &:last-of-type {
        margin: 0 0 50px 0;
      }
    }
    @media (--mobile) {
      padding: 10px;
      font-size: 15px;
      margin: 0 0 5px 0;
      &:last-of-type {
        margin: 0 0 30px 0;
      }
    }
  }
}
.cms-subpages {
  .article {
    background-color: transparent;
  }
}
