.article {
  &__social-media {
    background-color: $color5;
    line-height: 1.5;
    font-size: 0.9rem;
    padding: 10px 60px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @media (--tablet-sm) {
           padding: 10px 40px;
    }

    @media (--mobile) {
      padding: 10px 15px;
      max-width: 707px;
    }
    &__flex {
      display: flex;
      align-items: center;

    }
    &__text {
      margin-right: 8px;
      @media (--mobile) {
        display: none;
      }
    }
    &__facebook {
      padding: 15px;
      margin: 5px;
      background-color: #3b5998;
      border-radius: 4px;
      font-weight: bold;
    }
    &__twitter {
      padding: 15px;
      margin: 5px;
      background-color: #55acee;
      border-radius: 4px;
      font-weight: bold;
    }
    &__email {
      padding: 15px;
      margin: 5px;
      background-color: $color1;
      border-radius: 4px;
      font-weight: var(--font-weight-bold);
    }
  }
  &__author {
    display: flex;
    text-align: right;
    z-index: 9;

  }
  &__author__name {
    align-self: center;
    @media (--mobile-sm) {
      font-size: 13px;
    }
  }
  &__author__name__date {
    color: var(--subdued-color);
    text-transform: var(--minor-header-uppercase);
    font-size: 12px;
    display: block;
    margin-top: -2px;
    @media (--mobile-sm) {
      font-size: 11px;
    }
  }
  &__author img {
    height: 60px;
    border-radius: var(--rounded-corners-circle-or-square);
    margin-left: 10px;
    filter: grayscale(100%);

    @media (--mobile) {
      height: 50px;
      margin-left: 7px;
    }
  }
}
