.product-card {
  &__read-more {
    color: $color1;
    font-size: 15px;
    line-height: 25px;
    margin: 0 0 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    @media (--mobile) {
      font-size: 14px;
      line-height: 24px;
    }
    &:hover {
      text-decoration: underline;
      .product-card__read-more__arrow {
        background-color: $color2;
      }
      svg {
        fill: $color1;
      }
    }
    &__arrow {
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color1;
      margin: 10px;
      border-radius: 100%;
      padding: 13px;
      @media (--mobile) {
        height: 30px;
        width: 30px;
        padding: 0;
      }
      &:hover {
        background-color: $color2;
        svg {
          fill: $color1;
        }
      }
      svg {
        fill: $color5;
        height: fit-content;
      }
      @media (--mobile) {
        height: 20px;
        width: 20px;
        svg {
          height: 8px;
          width: 8px;
        }
      }
    }
    &__toggle {
      height: 10px;
      width: 10px;
      transform: rotate(90deg);
      fill: $color1;
      margin-left: 10px;
      display: none;
      @media (--mobile) {
        display: block;
      }
    }
  }

  &__content {
    overflow: hidden;
    padding-top: 40px;
    padding-bottom: 60px;
    @media (--tablet) {
      padding-top: 30px;
      padding-bottom: 50px;
    }
    @media (--mobile) {
      padding-top: 11px;
      padding-bottom: 12px;
      width: 100%;
      border-bottom: var(--border-thin) $gColorBorder1;
    }

    &__header__h2 {
      display: flex;
      align-items: center;
      font-family: var(--brand-font);
      color: var(--dark);
      font-size: 32px;
      line-height: 42px;
      position: relative;
      .design-billigvvs & {
        color: var(--brand-color);
      }

      @media (--tablet-lg) {
        font-size: 30px;
        line-height: 40px;
      }
      @media (--tablet-sm) {
        font-size: 28px;
        line-height: 36px;
      }
      @media (--mobile) {
        padding: 0 40px 0 15px;
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        color: $gColorFont1;
      }
      @media (--mobile-sm) {
        font-size: 22px;
        line-height: 28px;
      }
    }
    &__header__h3 {
      font-size: 26px;
      line-height: 36px;
      font-family: var(--brand-font);
      color: var(--subdued-color);
      font-weight: var(--font-weight-regular);
        @media (--tablet-lg) {
          font-size: 24px;
          line-height: 34px;
        }
        @media (--tablet-sm) {
          font-size: 22px;
          line-height: 30px;
        }
        @media (--mobile) {
          display: none;
      }
    }
    &__toggle {
      position: absolute;
      right: 15px;
      top: 11px;
      height: 12px;
      width: 12px;
      fill: $color1;
      transform: rotate(0deg);
      display: none;
      .design-vvskupp & {
        fill: var(--dark);
      }
      @media (--mobile) {
        display: block;
      }
    }
    &__body {
      font-size: 15px;
      line-height: 25px;
      margin-top: 20px;
      @media (--mobile) {
        padding: 0 15px;
        font-size: 14px;
        line-height: 24px;
        margin: 10px 0 15px;
      }
      &--closed {
        @media (--mobile) {
          display: none !important;
        }
      }

      h1,
      h2 {
        font-size: 26px;
        line-height: 36px;

        @media (--mobile) {
          font-size: 24px;
          line-height: 32px;
        }
      }

      h3 {
        font-size: 22px;
        line-height: 32px;

        @media (--mobile) {
          font-size: 20px;
          line-height: 28px;
        }
      }

      h4,
      h5,
      h6 {
        font-size: 18px;
        line-height: 28px;

        @media (--mobile) {
          font-size: 16px;
          line-height: 24px;
        }
      }

      ul {
        font-size: 15px;
        line-height: 25px;
        list-style: none;
        padding-inline-start: 20px;
        @media (--mobile) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      li {
        font-size: 15px;
        line-height: 25px;
        display: table;
        margin: 10px;
        &:before {
          content: "\25AA ";
          color: $color1;
          display: table-cell;
          text-indent: -20px;
        }
        @media (--mobile) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      blockquote {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        font-family: $fontFamilyOswald;
        color: $gColorFont1;
        @media (--mobile) {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: $gColorFont1;
        }
      }
      &__blockquote {
        background-color: $color1;
        height: 30px;
        width: 30px;
        border-radius: 100px;
        margin: 0 5px 0 0;
        color: $gColorFont1;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        svg {
          height: 14px;
          width: 14px;
          fill: $color5;
          @media (--mobile) {
            height: 12px;
            width: 12px;
          }
        }
        @media (--mobile) {
          height: 25px;
          width: 25px;
        }
      }
      &__centered {
        text-align: center;
        max-width: 884px;
        margin: 0 auto;
        @media (--mobile) {
          padding: 0 5%;
        }
        &__h2 {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $fontFamilyOswald;
          color: $color5;
          font-size: 30px;
          line-height: 40px;
          position: relative;
          margin-bottom: 20px;
          @media (--mobile) {
            display: none;
          }
        }
        ul {
          padding-inline-start: 0;
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
          color: $color5;
        }
        li {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          text-transform: uppercase;
          line-height: 28px;
          font-family: $fontFamilyOswald;
          color: $color5;
          &:before {
            content: none;
          }
          @media (--mobile) {
            color: $color1;
            font-size: 16px;
            line-height: 26px;
          }
        }
        blockquote {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          padding: 0 10%;
          @media (--mobile) {
            padding: 0 5%;
          }
        }
      }
      &__reviews {
        margin-top: 20px;
        padding: 0 20px;
        @media (--mobile) {
          padding: 0 15px;
        }
      }
      &__faq {
        &__question {
          border-radius: 4px;
          overflow: hidden;
          position: relative;
          background-color: $color5;
          margin-bottom: 10px;
          @media (--mobile) {
            background-color: $colorBg1;
          }
          &__header {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 15px 40px 15px 20px;
            font-size: 14px;
            text-transform: uppercase;
            line-height: 24px;
            @media (--mobile) {
              font-size: 12px;
              line-height: 22px;
            }
          }
          &__answer {
            font-size: 14px;
            text-transform: uppercase;
            padding: 15px 20px;
            border-radius: 0 0 14px 14px;
            background-color: $colorBg10;
            p {
              text-transform: initial;
              font-weight: 400;
            }
            @media (--mobile) {
              font-size: 12px;
              background-color: $colorBg11;
            }
          }
        }
      }
    }
    &__read-more {
      color: $color1;
      text-decoration: underline;
      &:hover {
        color: $color9;
      }
    }

    &__ratings,
    &__faq,
    &__questions,
    &__delivery {
      @media (--desktop) {
        width: 50%;
        padding-right: 4%;
        padding-bottom: 0;
      }
      @media (--tablet-lg) {
        width: 50%;
        padding-right: 20px;
        padding-bottom: 0;
        .outer-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
      @media (--tablet-sm) {
        width: 100%;
        .outer-container {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    &__questions {
      &__product-name {
        font-size: 14px;
        display: block;
        color: #777;
        &__sm {
          font-size: 10px;
          text-transform: uppercase;
          display: block;
          color: #999;
        }
        &__wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        }
      }
    }
    &__ratings {
      &__total {
        background-color: $gColorbg1;
        color: $color5;
        padding: 20px;
        text-align: center;
        border-radius: 4px;
        font-size: 10px;
        text-transform: uppercase;
        &__title {
          font-family: $fontFamilyOswald;
          display: block;
          padding: 5px 20px;
          font-size: 16px;
          text-transform: initial;
        }
        .product-card__recommendations {
          justify-content: center;
          padding: 5px 0 0 0;
          span {
            font-size: 12px;
            margin-top: 2px;
            margin-left: 5px;
            text-transform: initial;
          }
        }
        button {
          color: $color5;
          border-color: $color5;
          margin-top: 15px;
          &:hover {
            background-color: $color5;
            color: $gColorFont1;
          }
        }
      }
      &__review {
        height: 100%;
        width: 245px;
        margin-right: 5px;
        border-radius: 4px;
        display: inline-block;
        background-color: $colorBg1;
        padding: 20px;
        white-space: normal;
        position: relative;
        .product-card__recommendations {
          padding: 0;
          &__star {
            height: 15px;
            width: 15px;
          }
        }

        &__header {
          font-family: $fontFamilyOswald;
          font-size: 14px;
          margin: 15px 0 10px;
          line-height: 20px;
          /* autoprefixer: ignore next */
          overflow: hidden;
          -webkit-line-clamp: 3;
          text-overflow: ellipsis;
          max-height: 62px;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
        &__text {
          font-size: 12px;
          line-height: 18px;
          margin: 5px 0;
        }
        &__name {
          font-size: 12px;
          margin-top: 10px;
        }
        &__date {
          font-size: 11px;
          color: #999;
          margin-top: 10px;
          font-weight: bold;
        }
        &__footer {
          font-size: 11px;
          color: #999;
          position: absolute;
          bottom: 15px;
          left: 20px;
          &__relevant {
            display: flex;
            align-items: center;
            font-weight: bold;

            font-size: 12px;
            svg {
              height: 16px;
              width: 16px;
              fill: #999;
              margin-right: 7px;
            }
            span {
              margin-right: 20px;
            }
            .thumbs-down {
              transform: rotate(180deg);
              margin-top: 11px;
            }
            .active {
              fill: $color1;
              color: #444;
            }
          }
        }

        &__wrapper {
          display: flex;
          justify-content: flex-start;
          margin: 10px 0;
          overflow-y: hidden;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          overflow-x: auto;
        }
      }
    }
  }

  
.productbox__slider {

  @media (--mobile) {
    .design-billigvvs &,
    .design-greenline & {
      background-color: var(--primary-background-color);
    }
  }
}
}
