@import "~styles/utils/media-queries";

.productlist-footer {
  width: 852px;
  position: relative;
  margin: 0 auto;
  padding: 40px 0;
  word-spacing: 0.5px;
  @media (--tablet) {
    width: 100%;
    max-width: 821px;
  }
  @media (--tablet-sm) {
    padding: 30px 0;
  }
  @media (--mobile) {
    padding: 15px;
    width: 100%;
    max-width: 707px;
  }

 .btn {
    margin-bottom: 15px;
    @media (--mobile) {
      margin-bottom: 10px;
    }
  }

  .article {
    &__guide,
    &__factbox {
      padding: 10px 20px 20px 20px;
      @media (--mobile) {
        padding: 10px 15px 15px 15px;
      }
      @media (--mobile-md) {
        float: none;
        margin: 10px 0;
        width: 100%;
        max-width: 100%;
      }
    }
    &__video,
    &__image {
      overflow: hidden;
      height: auto;
      max-width: 100%;
      float: none;
      margin: 30px 0;
      padding: 0;
      @media (--tablet-sm) {
        margin: 20px 0;
      }
      @media (--mobile) {
        margin: 15px 0;
      }
      img {
        width: 100%;
        height: auto;
      }
      &.small-img.align-left,
      &.small-img {
        width: auto;
        float: left;
        max-width: 60%;
        margin: 15px 30px 15px 0;
        @media (--tablet-sm) {
          margin: 10px 20px 10px 0;
        }
        @media (--mobile) {
          margin: 10px 20px 20px 0;
        }
        @media (--mobile-md) {
          float: none;
          margin: 15px 0;
          max-width: 100%;
        }
      }
      &.small-img.align-right {
        margin: 15px 0 15px 30px;
        float: right;

        max-height: auto;
        min-width: auto;
        min-height: auto;
        @media (--tablet-sm) {
          margin: 10px 0 10px 20px;
        }
        @media (--mobile) {
          margin: 10px 0 20px 20px;
        }
        @media (--mobile-md) {
          float: none;
          margin: 15px 0;
          max-width: 100%;
        }
      }
    }
    &__image img {
      border-radius: var(--rounded-corners);
      @media (--mobile) {
        border-radius: var(--rounded-corners-sm);
      }
    }
    &__image.align-right {
      float: right;
      margin: 0 -28% 0px 30px;
      max-width: 497px;
      max-height: 497px;
      @media (--tablet-lg) {
        margin: 0 -20% 0px 30px;
      }
      @media (--tablet-sm) {
        margin: 0 0 0px 30px;
      }
      @media (--mobile) {
        float: none;
        margin: 15px 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }
      @media (--mobile-xl) {
        float: right;
        margin: 15px 0 15px 15px;
        max-width: 395px;
        max-height: 395px;
        min-height: 300px;
        min-width: 300px;
      }
    }
    &__image.align-left {
      float: left;
      margin: 0px 30px 0 -28%;
      max-width: 497px;
      max-height: 497px;
      @media (--tablet-lg) {
        margin: 0px 30px 0 -20%;
      }
      @media (--tablet-sm) {
        margin: 0px 30px 0 0;
      }
      @media (--mobile) {
        float: none;
        margin: 15px 0;
        width: 100%;
        height: 100%;
        max-height: 100%;
        max-width: 100%;
      }
      @media (--mobile-xl) {
        float: left;
        margin: 15px 15px 15px 0;
        max-width: 395px;
        max-height: 395px;
        min-height: 300px;
        min-width: 300px;
      }
    }
    &__image.align-center {
      width: 100%;
      margin: 30px auto;
      float: none;
      @media (--tablet-sm) {
        margin: 20px auto;
      }
      @media (--mobile) {
        margin: 15px auto;
      }
    }
  }

  .youtube_codegena {
    display: contents;
  }
  .icon-guide,
  .icon-factbox {
    background-color: $color1;
    height: 40px;
    width: 40px;
    border-radius: var(--rounded-corners-circle-or-square);
    color: $gColorFont3;
    position: absolute;
    top: 16px;
    left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (--mobile) {
      height: 30px;
      width: 30px;
      top: 15px;
      left: 15px;
    }
    svg {
      height: 18px;
      width: 18px;
      .icon-stroke {
        stroke: var(--color-on-primary-color);
      }
      @media (--mobile) {
        height: 13px;
        width: 13px;
      }
    }
  }
  &__header {
    h2 {
      font-size: 40px;
      line-height: 50px;
      word-spacing: 0.5px;
      @media (--tablet) {
        font-size: 35px;
        line-height: 45px;
      }
      @media (--mobile) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
  ul,
  ol {
    display: table;
    margin: 10px 0 15px;
    padding: 0 15px;
    @media (--mobile) {
      margin: 0 0 15px 0;
    }
  }
  ol li {
    text-indent: 4px;
  }
  ul {
    list-style: none;
  }
  ul li {
    display: block;
  }
  ol li,
  ul li {
    padding: 0;
    font-size: 15px;
    line-height: 25px;
    margin: 10px 0 20px 0;
    @media (--mobile) {
      margin: 5px 0 10px;
    }
    p {
      display: initial;
    }
  }
  ul li:before {
    color: $color1;
    content: "\25AA\fe0e";
    display: inline-block;
    margin-right: 10px;
  }
  ol li::marker {
    font-weight: 700;
  }
  p {
    a:not(.btn) {
      color: $color1;
      text-decoration: underline;
      .design-lavpris & {
        color: var(--dark);
      }
      &:hover,
      &:focus,
      &:active {
        color: $color9;
        text-decoration: underline;
        .design-lavpris & {
          color: color-mix(in srgb, $color3, #fff 20%);
        }
      }
    }
  }

  blockquote {
    font-family: var(--brand-font);
    font-size: 26px;
    line-height: 36px;
    font-weight: var(--brand-font-weight);
    color: var(--dark);
    margin: 20px;
    display: inline-flex;
    width: 50%;
    float: right;
    @media (--tablet) {
      font-size: 23px;
      line-height: 33px;
    }
    @media (--mobile) {
      font-size: 20px;
      line-height: 30px;
      width: 100%;
      clear: both;
      padding: 15px 20px 20px;
      flex-direction: column;
      text-align: center;
      margin: 0;
    }
  }
  blockquote.align-left {
    float: left;
  }

  &__gradient {
    position: absolute;
    bottom: 98px;
    height: 300px;
    z-index: 10;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 1%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    left: 0;
    width: 100%;
  }
  &__button {
    width: 100%;
    background-color: $color5;
    padding: 10px 0 30px 0;
    position: absolute;
    bottom: 0;
    display: block;
  }
}
