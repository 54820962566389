.article {
  &__offer-banner {
    background-color: var(--offset-of-brand-color-lighter);
    .design-vvskupp & {
      background-color: var(--campaign-color);
      .btn {
        
      }
    }
    h3 {
      font-weight: var(--font-weight-regular);
      text-transform: var(--title-uppercase);
      letter-spacing: var(--letter-spacing-sm);
      word-spacing: var(--word-spacing-sm);
      @media (--mobile) {
        letter-spacing: var(--letter-spacing-xs);
        word-spacing: var(--word-spacing-xs);
      }
    }
    h2 {
      text-transform: var(--title-uppercase);
      letter-spacing: var(--letter-spacing);
      word-spacing: var(--word-spacing);
      margin: 10px 0 15px 0;
      @media (--tablet-sm) {
        letter-spacing: var(--letter-spacing-sm);
        word-spacing: var(--word-spacing-sm);
      }

      @media (--mobile) {
        max-width: none;
        margin: 5px 0 10px 0;
        letter-spacing: var(--letter-spacing-xs);
        word-spacing: var(--word-spacing-xs);
      }
    }
    p {
      margin: -5px 0 0 0;
    }
    .btn {
      margin: 15px 0 0 0;
      min-width: 50%;
      @media (--mobile) {
        width: 100%;
      }
      @media (--mobile-lg) {
        min-width: 60%;
      }
    }
    @media (--mobile) {
      & > .container {
        margin: 0 -5px;
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
    }
    &__period {
      font-size: 0.8rem;
      margin-top: 20px;
      color: var(--subdued-color);
      .design-greenline & {
        color: var(--white);
        opacity: 0.8;
      }
    }
    &__campaign-end {
      background-color: var(--attention-color);
      padding: 10px 15px;
      text-align: center;
      color: $gColorFont3;
      text-transform: var(--title-uppercase);
      display: flex;
      justify-content: center;
      align-items: center;
      .icon-urgency {
        fill: var(--white);
        height: 20px;
        width: 20px;
        margin: 0 7px 0 0;
        align-self: center;
      }

      &:hover {
        transition: all 0.4s ease;
        text-decoration: underline;
        cursor: pointer;
        color: var(--white);
      }
      a {
        color: var(--white);
        text-decoration: none;
        &:hover,
        &:active,
        &:focus {
          color: var(--white);
        }
      }
      @media (--mobile) {
        font-size: 12px;
        padding: 5px 15px;
      }
    }
    &__splash {
      &--text {
        right: 25px;
        top: 25px;
        position: absolute;
        .design-vvskupp & {
          border: var(--border-thin) color-mix(in srgb, var(--highligted-background-color), black 20%);
        }
        @media (--mobile) {
          position: unset;
          margin: 0 15px 10px 5px !important;
        }
      }
    }
    .highlight__padding-container {
      padding: 60px;

      @media (--mobile) {
        padding: 15px;
      }
      @media (--mobile-lg) {
        padding: 15px 30px;
      }
    }
  }
}

.icon-offer {
  height: 45px;
  width: 45px;
  color: $colorBg7;
  position: absolute;
  left: 40px;
  background-color: $colorBg4;
  padding: 11px 10px 10px 10px;
  border-radius: 0 0 4px 4px;
  font-size: 1.5rem;
  font-family: var(--brand-font);
  font-weight: bold;
  text-align: center;
  @media (--tablet) {
    left: 35px;
  }
  @media (--mobile) {
    position: initial;
    align-self: center;
    left: initial;
  }
}
