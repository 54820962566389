.article {
  &__step-by-step {
    background-color: transparent;
    max-width: 852px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    @media (--tablet) {
      max-width: 821px;
    }
    @media (--mobile) {
      max-width: 677px;
    }
    ul {
      padding-left: 0;
      counter-reset: ranking;
    }
    li {
      background-color: var(--secondary-background-color);
      border-radius: var(--rounded-corners);
      padding: 10px 20px 10px 10px;
      margin: 5px 0;
      list-style: none;
      display: flex;
      align-items: center;
      @media (--mobile) {
        border-radius: var(--rounded-corners-sm);
      }
    }
    li::before {
      padding: 0;
      height: 50px;
      width: 50px;
      font-size: 20px;
      background-color: var(--brand-color);
      border-radius: var(--rounded-corners-circle-or-square);
      color: var(--color-on-brand-color);
      font-family: var(--brand-font);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-right: 10px;
      counter-increment: ranking;
      content: counter(ranking);
      @media (--mobile) {
        height: 40px;
        width: 40px;
        font-size: 18px;
      }
    }
    li span {
      margin: auto 0;
      font-size: 15px;
      line-height: 25px;
      width: calc(100% - 60px);
      &::first-line {
        font-weight: var(--font-weight-bold);
      }
      @media (--mobile) {
        font-size: 14px;
        line-height: 24px;
        width: calc(100% - 50px);
      }
      strong {
        font-family: $fontFamilyOswald;
        font-size: 17px;
        line-height: 27px;
        @media (--mobile) {
          font-size: 16px;
          line-height: 26px;
        }
      }
    }
  }
}
