.cms-parallax {
  padding: 0;
}
.parallax-wrapper {
  width: 100%;
  position: relative;
}
.parallax-clip-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  clip-path: inset(0 0 0 0);
}
.parallax {
  top: 0;
  min-width: 100%;
  height: 100dvh;
  background-size: cover;
  position: fixed;
  video,img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &__img {
    &-desktop {
      @media only screen and (max-width: 666px) {
        display: none;
      }
    }
    &-mobile {
      @media only screen and (min-width: 667px) {
        display: none;
      }
    }
  }
}

