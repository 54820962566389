@custom-media --desktop only screen and (min-width: 1516px);
@custom-media --desktop-lg (min-width: 1805px);
@custom-media --desktop-short (min-width: 1516px) and (max-height: 680px);

@custom-media --tablet (min-width: 961px) and (max-width: 1515px);
@custom-media --tablet-lg (max-width: 1515px) and (min-width: 1280px);
@custom-media --tablet-sm (max-width: 1279px) and (min-width: 961px);
@custom-media --tablet-xs (max-width: 1120px) and (min-width: 961px);
@custom-media --tablet-xs-and-less (max-width: 1120px);
@custom-media --tablet-and-above (min-width: 961px);
@custom-media --tablet-portrait (min-width: 961px) and (max-width: 960px);
@custom-media --tablet-and-less (max-width: 1515px);
@custom-media --tablet-lg-and-above (min-width: 1280px);
@custom-media --tablet-sm-and-less (max-width: 1279px);

@custom-media --mobile only screen and (max-width: 960px);
@custom-media --mobile-xl (min-width: 666px) and (max-width: 960px);
@custom-media --mobile-lg (min-width: 499px) and (max-width: 960px);
@custom-media --mobile-md (max-width: 767px);
@custom-media --mobile-sm (max-width: 399px);

@custom-media --no-hover (hover: none);
@custom-media --has-hover (hover: hover);
@custom-media --ios (-webkit-touch-callout: none);
@custom-media --touch (hover: none) and (pointer: coarse);

@custom-media --new-mobile (max-width: 767px);
@custom-media --new-tablet (min-width: 768px) and (max-width: 1199px);
@custom-media --new-desktop-sm (min-width: 1200px) and (max-width: 1515px);
@custom-media --new-desktop-lg (min-width: 1516px);