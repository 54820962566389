.focalFloatButton {
  bottom: calc(10px + var(--bottom-offset-guidance, 0px) + var(--bottom-offset-cta-sticky, 0px)) !important;
  transition: bottom 0.2s ease;
}

.focalFloatInviteToChat {
  bottom: calc(10px + var(--bottom-offset-guidance, 0px) + var(--bottom-offset-cta-sticky, 0px)) !important;
  transition: bottom 0.2s ease;
}

.sleeknote-anchor {
  bottom: calc(20px + var(--bottom-offset-guidance, 0px) + var(--bottom-offset-cta-sticky, 0px)) !important;
  transition: bottom 0.2s ease;
}