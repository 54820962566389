body {
  background-color: var(--primary-background-color);
}

a,
.link {
  color: $color1;
  text-decoration: underline;
}

// Blockqoute
blockquote {
  font-family: $fontFamilyOswald;
  color: $colorFont2;
  font-weight: 100;
  font-size: 22px;
  line-height: 30px;
  strong {
    font-weight: 400;
  }
}

// Inputs
input:focus {
  outline: none;
  border: 1px solid $color1;
}

.divider {
  border-color: $gColorBorder1;
  &--light {
    border-color: $gColorBorder5;
  }
  &--dark {
    border-color: $colorBorder5;
  }
}

a:focus {
  color: $color1;
}
a.header__nav-link:focus {
  color: $color5;
}
.nav__mega-col {
  a:focus {
    color: $gColorFont1;
  }
}

.bg-light-blue {
  background-color: $color2;
}

.bg-blue {
  background-color: $gColorbg10;
}

.custom-radio {
  .custom-control-input:checked ~ .custom-control-indicator,
  .custom-control-indicator.checked {
    background-color: $gColorFont3;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23006496'/%3E%3C/svg%3E");
  }
}
