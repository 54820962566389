@import "~styles/utils/media-queries";

.nav {
  position: absolute;
  width: 100%;
  height: var(--megamenu-height);
  top: 100%;
  background-color: var(--brand-color);
  transition: transform 0.25s;
  z-index: -1;

  a:hover {
    text-decoration: none;
  }

  :global(.design-completvvs) &,
  :global(.design-greenline) & {
    background-color: var(--white);
    border-bottom: 1px solid var(--brand-color);
  }

  :global(.design-lampeguru) & {
    background-color: var(--primary-color);
  }

  @media (--mobile) or (--no-hover) {
    display: none;
  }
}

.hidden {
  transform: translateY(calc(-100% + 1px));
}

.wrapper {
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 5px;
  max-width: 1435px;

  :global(.design-billigvvs) & {
    max-width: 1725px;
  }

  @media (--tablet-lg) {
    gap: 4px;
  }

  @media (--tablet-sm) {
    padding-left: 15px;
    padding-right: 15px;
    gap: 3px;
  }

  @media (--tablet-xs) {
    gap: 1px;
  }
}

.entry {
  padding: 0 5px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-on-brand-color);
  font: var(--mega-menu-header-font);
  text-decoration: none;
  text-transform: var(--title-uppercase);
  border: var(--border-thin) transparent;
  font-weight: var(--brand-font-weight);

  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    color: var(--standard-text-color);
    text-transform: none;
  }

  :global(.shop-billigvvs-dk) &,
  :global(.design-greenline) & {
    @media (--tablet-lg) {
      font-size: 16px;
    }

    @media (--tablet-sm) {
      font-size: 15px;
    }

    @media (--tablet-xs) {
      font-size: 14px;
    }
  }

  :global(.shop-billigvvs-no) &,
  :global(.shop-vvsochbad-se) & {
    @media (--tablet-sm) {
      font-size: 16px;
    }

    @media (--tablet-xs) {
      font-size: 15px;
    }
  }

  :global(.design-lampeguru) & {
    color: var(--brand-color);
  }

  :global(.shop-lampeguru-dk) &,
  :global(.shop-ljusochlampor-se) & {
    @media (--tablet-lg) {
      font-size: 16px;
    }

    @media (--tablet-sm) {
      font-size: 15px;
    }

    @media (--tablet-xs) {
      font-size: 14px;
    }
  }

  :global(.shop-lampeguru-no) & {
    @media (--tablet-sm) {
      font-size: 16px;
    }

    @media (--tablet-xs) {
      font-size: 15px;
    }
  }

  :global(.design-completvvs) &,
  :global(.shop-lavprisvvs-dk) & {
    @media (--tablet-lg) {
      font-size: 17px;
    }

    @media (--tablet-sm) {
      font-size: 16px;
    }

    @media (--tablet-xs) {
      font-size: 15px;
    }
  }

  :global(.shop-lavprisel-dk) & {
    @media (--tablet-lg) {
      font-size: 16px;
    }

    @media (--tablet-sm) {
      font-size: 15px;
    }

    @media (--tablet-xs) {
      font-size: 13px;
    }
  }

  :global(.shop-lavprisvaerktoej-dk) & {
    @media (--tablet-lg) {
      font-size: 17px;
    }

    @media (--tablet-sm) {
      font-size: 16px;
    }

    @media (--tablet-xs) {
      font-size: 14px;
    }
  }
}

.spacer {
  flex-grow: 10;
}

.entry:hover,
.entry.active {
  background-color: var(--color-on-brand-color);
  color: var(--standard-text-color);

  :global(.design-greenline) &:not(.no-children),
  :global(.design-completvvs) &:not(.no-children) {
    border: 1px solid var(--primary-color);
    border-bottom: 1px solid var(--white);
    box-shadow: 0 2px 0 -1px #fff; // Hack to hide the border-bottom on the nav element
    text-decoration: none !important;
  }

  :global(.design-lampeguru) &,
  :global(.design-lavpris) & {
    background-color: var(--white);
  }

  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    @media (--tablet-and-above) {
      text-decoration: underline;
    }
  }

  :global(.design-billigvvs) & {
    color: var(--brand-color);
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: var(--white);
  flex-direction: column;
  padding: 10px 0;
  z-index: 1;

  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    top: calc(100% + 1px); // Needs space to show the border
    left: 1px;
    width: calc(100% - 2px);
  }
}

.dropdown-wrapper {
  padding: 10px 20px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: space-between;
  gap: 20px;
}

.sub-header {
  font: var(--mega-menu-header-font);
  text-transform: var(--title-uppercase);
  text-decoration: none;
  color: var(--standard-text-color);
  margin-bottom: 10px;
  width: fit-content;
  font-weight: var(--title-font-weight);
}

.sub-entry {
  display: flex;
  flex-direction: column;

  // A "grid-column: span x;" is applied by react, if the sub-entry needs to span multiple columns
}

.links {
  display: grid;
  grid-auto-flow: column;
  gap: 7px;
  grid-template-columns: repeat(1, 1fr);

  @media (--tablet) {
    gap: 5px;
  }

  @media (--tablet-xs) {
    gap: 3px;
  }

  // A "grid-template-columns: repeat(x, 1fr)" is applied by react, if the sub-entry needs to span multiple columns
}

.link {
  color: var(--standard-text-color) !important;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 3px 0;
  width: fit-content;

  &:first-child {
    padding-top: 0;
  }
}

.dropdown a::after {
  content: "";
  width: 0;
  border-bottom: 1px solid var(--primary-color);
  display: block;
  transition: all 0.4s cubic-bezier(0, 0, 0, 1);
  transition-delay: 0.2s;

  :global(.design-vvskupp) & {
    border-color: var(--dark);
  }
}

.dropdown a:hover::after {
  content: "";
  width: 100%;
  transition: all 0.4s ease-in-out;
  transition-delay: 0s;
}

.overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100vh;
  pointer-events: none;
  background-color: rgb(0 0 0);
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  :global(.design-greenline) &,
  :global(.design-completvvs) & {
    top: calc(100% + 1px);
  }
}

.show .overlay {
  opacity: 0.5;
}
