
.explained-spec-one-column, .explained-spec-two-columns {
    h1, h2, h3, h4, h5, h6 {
    margin: 10px 0 0 0;
    }
    h1, h2 {
    font: var(--text-header-lg);
    }
    h3 {
    font: var(--text-header-md);
    }
    h4, h5 {
    font: var(--text-header-sm);
    }
    h5, h6 {
    font: var(--text-header-xs);
    }
    p {
      font-weight: var(--font-weight-regular);
      margin: 5px 0 15px;
      }
    img {
      margin: 1% auto;
      width: fit-content;
      display: block;
      border-radius: var(--rounded-corners);
      @media (--mobile-md) {
        width: 100%;
        border-radius: var(--rounded-corners-sm);
      }
    }
    }
    
    .explained-spec-two-columns {
    display: grid;
    gap: 2%;
    padding: 1% 0;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    @media (--mobile-md) {
        display: block;
        grid-template-columns: 100%;
    }
  }