.product-card {
  &__close {
    transform: rotate(0deg);
    height: 24px;
    width: 24px;
    z-index: 22;
    transition: var(--standard-transition-all);
    .icon-stroke {
      stroke: var(--muted);
     
    }
    @media (--mobile) {
      height: 20px;
      width: 20px;
    }
    &__wrapper {
      transition: var(--standard-transition-all);
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80px;
      width: 80px;
      z-index: 21;
      cursor: pointer;
      @media (--mobile) {
        height: 60px;
      width: 60px;
      }
      &:hover {
        svg {
          .icon-stroke {
            stroke: var(--dark);
           
          }
        }
      }
    }
  }
  &__enlarged {
    position: fixed;
    top: 0;
    left: 0;
    background-color: $gColorbg2;
    z-index: 1000;
    height: 100dvh;
    width: 100%;

    &__image-container {
      width: 100%;
      height: 100%;
      position: absolute;
      user-select: none;
      display: flex;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
        user-select: none;
        pointer-events: none;
        user-drag: none;
        -webkit-user-drag: none;
      }
      iframe {
        height: 81%;
        width: 100%;
        margin-top: 125px;
        @media (--mobile) {
          height: 71%;
          margin-top: 105px;
        }
      }
    }

    &__number-of-images {
      font-family: var(--brand-font);
      font-size: 16px;
      position: absolute;
      top: 100px;
      left: 30px;
      z-index: 2;
      @media (--mobile) {
        top: 76px;
        font-size: 14px;
        left: 15px;
      }
    }
    &__zoom {
      position: absolute;
      top: 30px;
      left: 30px;
      height: 60px;
      width: 124px;
      transition: var(--standard-transition-all);
      display: flex;
      flex-direction: row;
      z-index: 20;
      cursor: pointer;
      @media (--mobile) {
        height: 50px;
        width: 104px;
        top: 15px;
        left: 15px;
      }
      svg {
        height: 24px;
        width: 24px;
        opacity: 0.3;
        @media (--mobile) {
          height: 20px;
          width: 20px;
        }
      }
      &__in,
      &__out {
        background-color: var(--primary-background-color);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: 0 2px 0 0;
        border-radius: var(--rounded-corners-only-left);
        .design-vvskupp &,
        .design-completvvs &,
        .design-lavpris & {
          background-color: var(--secondary-background-color);
        }
        &:hover {
          background-color: $colorBg11;
        }
      }
      &__out {
        border-radius: var(--rounded-corners-only-right);
      }
      &__active {
        svg {
          opacity: 1;
        }
      }
    }
    &__image {
      height: calc(100% - 100px);
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @media (--mobile) {
        height: 100%;
        flex-direction: column-reverse;
      }
      .product-card__product-image__arrow {
        display: block;
        svg {
          height: 32px;
          width: 32px;
          @media (--tablet) {
            height: 24px;
            width: 24px;
          }
          @media (--mobile) {
            height: 20px;
            width: 20px;
          }
          .icon-stroke {
            stroke: var(--muted);
           
          }
        }
        &:hover {
          .icon-stroke {
            stroke: var(--dark);
           
          }
        }
      }
    }
    &__info {
      display: flex;
      width: 100%;
      position: absolute;
      bottom: 10px;
      align-items: flex-end;
      left: 0;
      @media (--mobile) {
        flex-direction: column-reverse;
        bottom: 0;
        padding: 20px 0;
      }
      .drop-down {
        border: var(--border-thin) var(--primary-grey);
      }
      &__left {
        width: 65%;
        padding: 0 20px;
        @media (--tablet-sm) {
          width: 50%;
        }
        @media (--mobile) {
          width: 100%;
          display: none;
        }
        .product-card__product-name {
          text-align: left;
          /* autoprefixer: ignore next */
          overflow: hidden;
          -webkit-line-clamp: 1;
          text-overflow: ellipsis;
          height: auto;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          width: 100%;
          font-size: 24px;
          line-height: 34px;
          margin: 0;
          color: $gColorFont1;
          @media (--mobile) {
            -webkit-line-clamp: 2;
            max-height: 55px;
            padding: 0 10%;
            text-align: center;
          }
        }
        .product-card__current-price {
          margin: 0;
          font-size: 28px;
        }
      }
      &__right {
        padding: 0 20px;
        width: 35%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        align-items: flex-end;
        @media (--tablet-sm) {
          width: 50%;
        }
        @media (--mobile) {
          width: 100%;
          justify-content: center;
        }
        @media only screen and (max-width: 960px) and (min-width: 500px) {
          display: none;
        }
        .product-card__variants {
          padding: 0;
          max-width: 530px;
          text-align: left;
          @media (--mobile) {
            text-align: center;
          }
          &__drop-down__arrow {
            transform: rotate(270deg);
          }
          &__drop-down__open {
            top: auto;
            position: initial;
          }
          &__images {
            justify-content: flex-end;
            @media (--mobile) {
              justify-content: center;
            }
          }
          &__colors {
            margin: 10px 0 0 0;
            justify-content: flex-end;
            @media (--mobile) {
              justify-content: center;
              padding: 0 10%;
            }
          }
        }
      }
    }
  }
}
