@import "~styles/utils/media-queries";
@import "~styles/utils/mixins";

.content {
  @include custom-scrollbar;

  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px 15px 15px;

  p:first-of-type {
    margin-top: 0;
  }
}
