.usp {
  &__icon {
    align-self: center;
    height: 12px;
    width: 12px;
    margin-right: 2px;
    fill: $color5;
    &.icon {
      &--other-logo {
        width: 21px;
        position: relative;
        padding-top: 0px;
        margin-bottom: 4px;
        @media (--mobile) {
          width: 25px;
        }
      }
      &--rating-star {
        fill: var(--secondary-color); // TODO
        width: 17px;
        height: 17px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        display: block;
        .design-lavpris &, .design-greenline & {
          fill: var(--brand-color);
        }

        svg {
          height: 18px;
          width: 18px;
        }
        &:nth-child(5) {
          margin-right: 7px;
          @media (--mobile) {
            margin-right: 5px;
          }
        }
      }
    }
    @media (--mobile) {
      height: 11px;
      width: 11px;
    }
    &:last-of-type {
      margin-right: 7px;
      @media (--mobile) {
        margin-right: 5px;
      }
    }
  }
}
