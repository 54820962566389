//Global footer styling
.footer {
  a:focus {
    color: $gColorbg3;
  }
  position: relative;
  &__list {
    padding-left: 0;
    @media (--tablet-and-above) {
      margin-top: 0;
    }
  }
  &__list-item {
    list-style: none;
  }

  &__top > .container > .row > * {
    display: flex;
    align-items: center;
  }
  &__top {
    .container {
      border-bottom: var(--border-thin) color-mix(in srgb, var(--primary-color), black 20%);
       
    }
  }
  &__contact-and-social {
    width: 100%;
    padding: 15px 5px;
    justify-content: space-between;
    @media (--mobile) {
      padding: 10px 5px;
    }
    @media (--mobile-md) {
      flex-direction: column;
    }
  }
  &__contact {
    display: flex;
    flex-wrap: wrap;
    @media (--mobile-md) {
      justify-content: space-evenly;
      width: 100%;
    }
    a {
      transition: var(--standard-transition-all);
      text-decoration: none;
      &:hover {
        .footer__contact--icon {
          background-color: color-mix(in srgb, var(--primary-color), black 20%);
          border-color: color-mix(in srgb, var(--primary-color), black 20%);
          .design-greenline & {
            background-color: var(--brand-color);
            border-color: var(--brand-color);
          }
        }
      }
    }
    &__block {
      display: flex;
      align-items: center;
      margin-right: 40px;
      color: $gColorFont3;
      @media (--mobile) {
        margin-right: 30px;
      }
      @media (--mobile-md) {
        align-items: center;
        margin: 10px 5px;
        flex-direction: column;
        text-align: center;
        .contact-block__info {
          margin: 3px 0;
          display: block;
          font-size: 12px;
        }
        .contact-block__title {
          margin: 3px 0;
          display: block;
          font-size: 1rem;
        }
      }
      &--status {
        margin-top: 5px;
        @media (--mobile) {
          margin-top: 0;
          flex-direction: column;
          display: flex;
        }
        @media (--mobile-md) {
          margin-top: 7px;
        }
      }
    }
    &--icon {
      background-color: transparent;
      border: var(--border-thin) color-mix(in srgb, var(--brand-color), black 20%);
      border-radius: var(--rounded-corners-circle-or-square);
      justify-content: center;
      margin: 0 5px 0 0;
      display: flex;
      align-items: center;
      transition: var(--standard-transition-all);
      height: 50px;
      width: 50px;
      @media (--tablet-and-less) {
        height: 40px;
        width: 40px;
      }
      &:hover {
        background-color: color-mix(in srgb, var(--brand-color), black 20%);
        border-color: color-mix(in srgb, var(--brand-color), black 20%);
        .design-greenline & {
          background-color: var(--brand-color);
          border-color: var(--brand-color);
        }
      }
      svg {
        height: 24px;
        width: 24px;
        display: block;
        .icon-stroke {
          stroke: var(--color-on-primary-color);

        }
        @media (--tablet-and-less) {
          height: 20px;
          width: 20px;
        }
      }

      @media (--mobile-md) {
        margin: 0;
      }
    }
  }
  &__nav-link {
    text-decoration: none;
    padding: 4px 0;
    display: inline-block;
    font-size: 15px;
  }
  .footer__list-item {
    .footer__nav-link {
      text-decoration: none;
      padding: 4px 0;
      display: inline-block;
      font-size: 15px;
      &:hover,
      &:focus,
      &:active {
        text-decoration: underline;
        color: $color5;
      }
    }
  }
  .heading {
    margin-bottom: 15px;
  }
  &__nav-icon {
    display: none;
  }
  &__nav-link {
    &:hover {
      text-decoration: underline;
    }
  }
  &__usp {
    background-color: var(--primary-color);
    padding: 30px 0 0 0;
    @media (--mobile) {
      padding: 15px 0 0 0;
    }
    .container {
      border-bottom: var(--border-thin) color-mix(in srgb, var(--primary-color), black 20%);
    }
    a,
    .element {
      font-size: 30px;
      color: var(--white);
      font-family: var(--brand-font);
      font-weight: var(--brand-font-weight);
      text-transform: var(--text-uppercase-on-splash-notification);
      line-height: 1.1;
      background: transparent;
      padding: 0 10px 20px 0;
      width: 25%;
      .design-completvvs & {
        font-weight: var(--font-weight-bold);
      }
      @media (--tablet) {
        font-size: 26px;
      }
      @media (--tablet-xs) {
        font-size: 22px;
      }
      @media (--mobile) {
        font-size: 16px;
        width: 50%;
        padding: 0 10px 10px 0;
      }
      small {
        font-size: 14px;
        display: block;
        margin: 4px 0 10px 0;
        line-height: 1.3;
        font-family: var(--default-font);
        font-weight: var(--brand-font-weight);
        @media (--tablet) {
          font-size: 14px;
        }
        @media (--tablet-xs-and-less) {
          font-size: 12px;
        }
        @media (--mobile) {
          margin: 3px 0 7px 0;
        }
      }
      .icon--other-logo svg {
        display: block;
        width: 38px;
        height: 24px;
        fill: $color5;
        margin-bottom: 5px;
        @media (--tablet) {
          height: 20px;
        }
        @media (--mobile) {
          height: 18px;
        }
      }
      .usp__icon {
        margin: 0;

        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          display: block;
          height: 10px;
          width: 10px;
          .icon-stroke {
            stroke: var(--color-on-brand-color);
            stroke-width: 2px;
            .design-lampeguru & {
              stroke: var(--color-on-primary-color);
            }
          }
          @media (--mobile) {
            height: 8px;
            width: 8px;
          }
        }
        &__arrow {
          fill: $gColorFont3;
          height: 10px;
          width: 10px;
          opacity: 0;
          margin: 0;
          transition: var(--standard-transition-all);
          display: block;
          @media (--mobile) {
            display: none;
          }
          svg {
            display: block;
            .icon-stroke {
              stroke: var(--color-on-primary-color)

            }
          }
        }
      }
      .usp__icon.icon--rating-star {
        height: 24px;
        width: 24px;
        margin-bottom: 0;
        margin-left: 0;
        &:nth-child(5) {
          margin-right: 4px;
        }
        &:nth-child(6) {
          margin: 0;
        }
        @media (--tablet) {
          height: 20px;
          width: 20px;
        }
        @media (--mobile) {
          margin-top: 0;
          height: 18px;
          width: 18px;
          margin-right: 2px;
        }
        svg {
          height: 24px;
          width: 24px;
          fill: var(--brand-color);

          .icon-stroke {
            stroke: var(--brand-color);
            stroke-width: 0;
            .design-billigvvs & {
              stroke: var(--secondary-color);
            }
            .design-completvvs & {
              stroke: var(--offset-of-brand-color);
            }
          }
          .design-billigvvs & {
            fill: var(--secondary-color);
          }
          .design-completvvs & {
            fill: var(--offset-of-brand-color);
          }
        }
      }
    }
    & > .container > div {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
    a {
      text-decoration: none;
      color: $gColorFont3;
      &:hover {
        color: $gColorFont3;
        text-decoration: none;
      }
      &:focus {
        color: $gColorFont3;
      }
    }
    &__rating {
      &--stars {
        display: flex;
        align-self: flex-end;
        margin-bottom: 5px;
      }
      &--score {
        display: flex;
        justify-content: flex-start;
      }
    }
    &__item {
      display: flex;
      background-color: color-mix(in srgb, var(--primary-color), black 20%);
      border: var(--border-thin) color-mix(in srgb, var(--primary-color), black 20%);
      width: 24px;
      height: 24px;
      border-radius: var(--rounded-corners-circle-or-square);
      justify-content: center;
      align-items: center;
      margin-bottom: 5px;

       

      @media (--tablet) {
        height: 20px;
        width: 20px;
      }
      @media (--mobile) {
        height: 18px;
        width: 18px;
      }
   
    }
  }
  &__partners__logo {
    height: 42px;
    margin-left: 15px;
    img {
      height: 100%;
    }
    @media (--mobile) {
      margin: 5px;
    }
  }
  &__mid {
    background-color: var(--primary-color);
    padding: 30px 0 20px 0;
    .container {
      border: none;
    }
    @media (--mobile) {
      padding: 0 0 20px 0;
      height: auto;
    }
  }
  &__layout {
    display: flex;
    @media (--mobile) {
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;
      justify-content: center;
    }
  }
  &__content {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 20%;
    @media (--mobile) {
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      width: 100%;
      flex-basis: 100%;
      flex-grow: 0;
      max-width: 100%;
    }
  }
  &__newsletter__content {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 25%;
    margin-left: 5%;
    @media (--mobile) {
      padding-left: 5px;
      padding-right: 5px;
      position: relative;
      width: 100%;
      flex-basis: 100%;
      flex-grow: 0;
      margin: 0;
      max-width: 100%;
    }
    @media (--tablet-sm) {
      max-width: 33%;
    }
  }
  &__newsletter__btn {
    @media (--mobile) {
      max-width: 500px !important;
    }
  }
  .newsletter-subscribe-form {
    margin: auto;
    display: block;
    max-width: 500px;
  }
  &__bot {
    background-color: var(--primary-color);
    border-top: var(--border-thin) color-mix(in srgb, var(--primary-color), black 20%);

    @media (--mobile) {
      padding: 0 0 60px 0;
      border: none !important;
    }
    &--shopinfo {
      display: flex;
      flex-direction: row;
      color: $gColorFont3;
      align-items: center;
      font-size: 14px;
      @media only screen and (max-width: 1024px) and (min-width: 961px) {
        font-size: 12px;
      }
      span {
        border-left: 1px solid $gColorFont3;
        padding: 0 15px;
        a {
          color: $gColorFont3;
        }
        @media (--mobile) {
          border: none;
          padding: 2px;
        }
      }
      @media (--mobile) {
        flex-direction: column;
      }
    }
    &--inner {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      padding: 0;
      @media (--mobile) {
        flex-direction: column;
        padding: 15px;
      }
    }
  }
  &__list-section {
    position: relative;
    height: 100%;
    .heading {
      width: 85%;
      @media (--tablet-sm) {
        width: 95%;
      }
      @media (--mobile) {
        width: 100%;
        border: none;
      }
    }
  }
  &__logo-img {
    .design-vvskupp & {
      width: 140px;
      height: 31px;
    }
  }
}

//Mobile
@media (--mobile) {
  .footer {
    &__top {
      padding: 0 15px;
    }
    &__mid {
      .container {
        padding-top: 0 !important;
        border: none !important;
      }
    }
    &__nav {
      .row > [class*="col-"] {
        //override cols' padding to get 100% width:
        padding-right: 0;
        padding-left: 0;
      }
    }
    &__list-section.active {
      .footer__list {
        height: auto;
      }
      .icon--arrow--down {
        transform: rotate(-90deg);
      }
    }
    &__nav-icon {
      display: initial;
      position: absolute;
      top: 16px;
      right: 15px;
      pointer-events: none;
      height: 15px;
      width: 15px;
      transform: rotate(0deg);
    }
    &__nav-link {
      line-height: 1.5em;
    }
    &__list {
      display: block;
      overflow: hidden;
      margin: 0;
      height: 0;
    }
    &__list-item {
      margin-left: 15px;
      &:last-of-type {
        margin-bottom: 15px;
      }
    }
    .heading {
      margin: 0;
      padding: 11px 0;
    }
    &__news-letter {
      margin: 30px 15px 20px 15px;
    }

  }
}
