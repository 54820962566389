.brandbox {
  border-radius: 14px;
  width: 250px;
  margin: 0;
  display: inline-block;
  white-space: initial;
  position: relative;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  filter: grayscale(100%);
  @media (--tablet-lg) {
    width: 17vw;
  }
  @media (--tablet-sm) {
    width: 21vw;
  }
  @media (--mobile) {
    width: 38vw;
    &:last-of-type {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 960px) and (min-width: 591px) {
    width: 20vw;
  }

  &:hover {
    transition: all 0.2s ease-in-out;
    filter: grayscale(0);
  }

  &__image {
    position: relative;
    height: 80px;
    max-width: 240px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 14px 14px 0 0;
    width: 100%;
    padding: 0;
    margin: auto;
    &:hover {
      cursor: pointer;
    }
    @media (--mobile) {
      height: 50px;
    }
    img {
      transition: opacity 0.2s ease-in-out;
      max-height: 60px;
      max-width: 130px;
      @media (--mobile) {
        max-height: 50px;
        max-width: 120px;
      }
    }
  }
}

.brands-slider-target {
  .outer-container {
    @media (--tablet-and-above) {
      min-height: 50px;
    }
  }
}

.cms-partnerlist {
  .brandbox,
  .brandbox__image,
  img {
    border-radius: 0;
  }
  .product-card__content__body__productbox__list {
    @media (--mobile) {
      padding: 25px 0;
    }
  }
  .brandbox {
    img {
      @media (--mobile) {
        max-height: 50px;
        max-width: 28vw;
      }
      @media only screen and (max-width: 960px) and (min-width: 591px) {
        max-width: 14vw;
      }
    }
  }
  .productbox__list {
    padding-right: 0 !important;
  }
}

.brandgrid-list-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 1435px;
  margin-left: auto;
  margin-right: auto;
  @media (--desktop-lg) {
    max-width: 1725px;
  }
  .brandbox {
    padding: 20px;

    @media (--tablet-sm) {
      padding: 15px;
    }
    @media (--mobile) {
      padding: 10px;
      .brandbox__image {
        height: 60px;
      }
      .brandbox__image img {
        height: auto;
        max-height: 60px;
      }
    }
  }
}
