@import "~styles/utils/media-queries";

.breadcrumb {
  overflow: hidden;
  padding: 8px 0;
  margin: 0;
  display: flex;
  font-family: $fontFamilyArial;
  @media (--tablet) {
    margin: 0;
  }
  @media (--mobile) {
    padding: 11px 5px 5px 12px;
    margin: 0 5px;
  }

  &__list {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    width: 100%;
    gap: 5px 0;

    list-style: none;
    margin: 0;
    padding: 0;
    z-index: 2;
  }

  &__item {
    margin: 0;
    padding: 0 10px 0 0;

    font-size: 11px;
    color: var(--subdued-color);
    text-transform: var(--minor-header-uppercase);
    align-items: center;
    height: 17px;
    display: flex;

    .design-greenline &, .design-completvvs &, .design-lampeguru & {
      font-size: 13px;
    }

    @media (--mobile) {
      font-size: 10px;
      .design-greenline &, .design-completvvs &, .design-lampeguru & {
        font-size: 12px;
      }
    }

    &.breadcrumb__expander {
      display: none;
    }

    &:first-child {
      display: none;
    }

    &__arrow svg {
      height: 9px;
      width: 9px;
      transform: rotate(270deg);
      margin-right: 0px;
      margin-bottom: -1px;
      margin-top: 4px;
      .icon-stroke {
        stroke: var(--subdued-color);
      } 
      @media (--mobile) {
        height: 12px;
        width: 12px;
        margin-top: 3px;
      }
    }
  }

  &__link {
    text-decoration: none;
    color: var(--subdued-color);
    font-size: 12px;
    
    .design-greenline &, .design-completvvs &, .design-lampeguru & {
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      color: var(--standard-text-color);
    }
  }

  &__print {
    display: inline-block;
    margin-left: 20px;
    font-size: 14px;
  }

  &__social-icon {
    width: 20px;
    height: 20px;

    &.icon--mail {
      width: 25px;
    }
  }

  &__social-icon {
    float: left;
    margin-right: 5px;

    &:hover {
      opacity: 0.7;
    }
  }

  &__arrow-icon {
    width: 8px;
    height: 8px;
    fill: $gColorFont2;

    @media (--mobile) {
      transform: rotate(270deg);
      margin-top: 2px;
    }
  }

  @media (--mobile) {
    &__link {
      font-size: 12px;
    }

    &__item {
      display: none;
      padding-right: 4px;

      &.breadcrumb__expander {
        display: flex;
      }

      &:nth-last-child(3),
      &:nth-last-child(4) {
        display: flex;
      }
    }
  }

  &__expanded {
    @media (--mobile) {
      .breadcrumb__expander {
        display: none !important;
      }
      .breadcrumb__item {
        display: flex;
        padding-right: 4px;

        &:nth-last-child(2),
        &:nth-last-child(1) {
          display: none;
        }
      }
    }
  }

  &__expander {
    font-weight: 900;
    letter-spacing: 1px;
  }
}

.landingpage .container-breadcrumb {
  color: var(--subdued-color);
  display: block;
  padding: 0 15px;
  @media (--tablet) {
    padding: 0 5px;
  }
  @media (--mobile) {
    padding: 0 5px;
  }
}

.productcard {
  background-color: $color5;

  .container-breadcrumb {
    @media (--mobile) {
      display: none;
    }
  }
}
