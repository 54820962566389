.image-rows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
    padding: 0 0 30px 0;
    &:first-of-type {
        padding-top: 15px;
    }
    &:last-of-type {
        padding-bottom: 0;
    }
    @media (--tablet-lg) {
        gap: 20px;
        padding: 0 0 20px 0;
    }
    @media (--tablet-sm) {
        gap: 10px;
        padding: 0 0 10px 0;
    }
    @media (--mobile) {
        gap: 4px;
        padding: 0 0 4px 0;
        .image-rows-arrow {
            display: block;
        }
    }
    @media (--mobile-lg) {
        .image-rows-arrow {
            display: block;
        }
    }
    @media (--mobile-sm) {
        .image-rows-arrow {
            display: none;
        }
    }
}

.image-column {
    width: 100%;
    position: relative;
    overflow: hidden;
    transition: var(--standard-transition-all);
    object-fit: cover;


    .product_image {
        transition: transform 0.5s;
        width: 100%;
        transform: translateY(0);
        height: 100%;
        object-fit: cover;
    }

    &.no-transformation {
        pointer-events: none;
        .product_image {
            transform: none;
        }
        &:hover {
            .product_image {
                transform: none;
            }
        }
    }
    &:hover {
        .product_image {
            transform: translateY(0) scale(1.1);
            .design-lavpris & {
                transform: translateY(0) scale(1);
            }
        }
        .image-wrapper {
            background-color: var(--offset-of-brand-color-darker);
            .design-greenline & {
                background-color: var(--offset-of-brand-color);
            }
        }


        .image-rows-arrow {
            fill: var(--color-on-primary-color);
            background-color: var(--primary-color);
            border-color: var(--primary-color);
            .design-lampeguru & {
                fill: var(--color-on-primary-color);
            }
            .design-lavpris & {
                fill: var(--color-on-primary-color);
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }

            .design-completvvs & {
                fill: var(--color-on-brand-color);
                background-color: var(--brand-color);
                border-color: var(--brand-color);
            }
            .image-rows-arrow-container{
                transform: translateX(0%);
                transition: transform var(--standard-transition);
            }
        }
        .image-rows-arrow-outline {
            fill: var(--brand-color);
            background-color: var(--color-on-primary-color);

            .design-lampeguru & {
                fill: var(--color-on-primary-color);
                background-color: var(--primary-color);
            }
            .design-lavpris &,
            .design-greenline &,
            .design-vvskupp & {
                fill: var(--dark);
            }
            .image-rows-arrow-container{
                transform: translateX(0%);
                transition: transform var(--standard-transition);
            }
        }
        .image-rows-text-container {
            .image-rows-arrow {
                .design-billigvvs & {
                    fill: var(--brand-color);
                    background-color: var(--offset-of-brand-color-lighter);
                    border-color: var(--offset-of-brand-color-lighter);
                }
                .design-completvvs & {
                    background-color: var(--hover-of-primary-color);
                    fill: var(--color-on-primary-color);
                    border-color: var(--hover-of-primary-color);
                }
            }
        }
    }
}

.image-wrapper {
    border-radius: var(--rounded-corners);
    overflow: hidden !important;
    background-color: var(--offset-of-brand-color);
    height: 100%;
    min-height: 178px;
    .design-lavpris &, .design-greenline & {
        background-color: var(--brand-color);
        text-align: right;
    }
    @media (--mobile) {
        height: 25vw;;
        border-radius: var(--rounded-corners-sm);
    }

}

.image-rows:has(> :last-child:nth-child(1)) {
    .image-column {
        width: 100%;
    }
    .product_image {
        max-height: 655px;
    }
    @media (--mobile) {
        gap: 4px;
        .image-rows-header {
            @media (--mobile) {
                font-size: 1.4em
            }
          }
        .image-rows-arrow {
            display: flex;
        }
        .product_image {
            min-height: 47vw;
        }
        .image-wrapper {
            height: 50vw;
        }
    }
    @media (--mobile-sm) {
        .image-wrapper {
            height: 66vw;
        }
    }
}
.image-rows:has(> :last-child:nth-child(2)) {
    .image-column {
            width: calc((100% - 30px) / 2);
        @media (--tablet-lg) {
            width: calc((100% - 20px) / 2);
        }
        @media (--tablet-sm) {
            width: calc((100% - 10px) / 2);
        }
        @media (--mobile) {
            width: calc((100% - 4px) / 2);
        }
    }
    @media (--mobile-md) {
        .image-rows-text {
            display: none;
        }
    }
    .product_image {
        max-height: 425px;
    }
    .image-rows-header {
        @media (--desktop) {
            font-size: 1.6em;
        }
        @media (--tablet-lg) {
            font-size: 1.5em;
        }
        @media (--tablet-sm) {
            font-size: 1.4em;
        }
    }
}
.image-rows:has(> :last-child:nth-child(3)) {
    .product_image {
        max-height: 310px;
    }
    .image-column {
        width: calc((100% - 60px) / 3);
        @media (--tablet-lg) {
            width: calc((100% - 42px) / 3);
        }
        @media (--tablet-sm) {
            width: calc((100% - 21px) / 3);
        }
    }
    @media (--mobile) {
        .image-column {
            width: calc((100% - 4px) / 2);
        }
        .image-column:nth-child(1) {
            width: 100%;
            justify-content: center;
        }
    }

    @media (--mobile-xl) {
        .image-column:nth-child(1),
        .image-column {
            width: calc((100% - 8px)  / 3);
        }
    }

    @media (--tablet-xs-and-less) {
        .image-rows-text {
            display: none;
        }
    }
    .image-rows-header {
        @media (--desktop) {
            font-size: 1.5em;
        }
        @media (--tablet-lg) {
            font-size: 1.4em;
        }
        @media (--tablet-sm) {
            font-size: 1.3em;
        }
    }
}
.image-rows:has(> :last-child:nth-child(4)) {
    .product_image {
        max-height: 270px;
    }
    .image-column {
        width: calc((100% - 90px) / 4);
        @media (--tablet-lg) {
            width: calc((100% - 60px) / 4);
        }
        @media (--tablet-sm) {
            width: calc((100% - 32px) / 4);
        }
    }
    @media (--tablet-sm-and-less) {
        .image-rows-text {
            display: none;
        }
    }
    @media (--mobile) {
        .image-column {
            width: calc((100% - 4px) / 2);
        }
        .image-rows-text {
            display: none;
        }
    }
    @media (--mobile-xl) {
        .image-column {
            width: calc((100% - 12px) / 4);
        }
    }
    .image-rows-header {
        @media (--desktop) {
            font-size: 1.4em;
        }
        @media (--tablet-lg) {
            font-size: 1.3em;
        }
        @media (--tablet-sm) {
            font-size: 1.2em;
        }
    }
}

.image-rows-text-container {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    color: var(--color-on-brand-color);
    margin: 0;
    padding: 0 15px 15px 15px;
    text-align: left;

    * {

        color: var(--color-on-primary-color);
        word-break: break-word;
        .design-lavpris & {
            color: var(--color-on-brand-color);
        }
    }

    @media (--tablet-sm-and-less) {
        padding: 0 10px 10px 10px;
    }
    @media (--mobile) {
        padding: 0 10px 10px 10px;
    }
}
.image-rows-text-wrapper {
    .image-rows-header,
    .image-rows-text {
        background-color: transparent !important;
        padding-left: 0;
        padding-right: 0;
    }
}

.image-rows-header {
    text-transform: var(--title-uppercase);
    line-height: normal;
    letter-spacing: var(--letter-spacing-xs);
    word-spacing: var(--word-spacing-xs);
    margin: 0;
    width: fit-content;
    padding: 0;
    hyphens: auto;
    .design-lavpris &, .design-greenline & {
        background-color: transparent;
        padding: 0;
        @media (--tablet-sm-and-less) {
            background-color: var(--offset-of-brand-color-darker);
            padding: 0 4px;
        }
    }

    @media (--mobile) {
        font-size: 16px;
    }
    @include line-clamp(2);
}

.image-rows-text {
    margin: 0;
    letter-spacing: var(--word-spacing-xs);
    word-spacing: var(--word-spacing-xs);
    @include line-clamp(2);
    max-width: 852px;
    width: fit-content;
    padding: 0;
    margin: 1px 0;

    .design-lavpris &, .design-greenline & {
        background-color: transparent;
        padding: 0;
        @media (--tablet-sm-and-less) {
            background-color: var(--offset-of-brand-color-darker);
            padding: 0 4px;
        }
    }
    @media (--mobile) {
        line-height: 1.3rem;
    }
    @media (--mobile-sm) {
        display: none;
    }
}

.image-rows-arrow {
    display: flex;
    overflow: hidden;
    justify-content: normal;
    fill: var(--color-on-brand-color);
    border-radius: var(--rounded-corners-circle-or-square);
    background-color: var(--brand-color);
    border: var(--border-medium) var(--brand-color);
    flex-shrink: 0;
    margin: 10px 0 0 0;
    align-self: center;
    height: 40px;
    width: 40px;
    .design-lampeguru & {
        border-color: transparent;
        fill: var(--color-on-primary-color);
        background-color:transparent;
    }

    svg {
        height: 15px;
        width: 15px;
        width: 50%;
        height: 100%;
        padding: 10px;
        @media (--tablet-sm) {
            padding: 7px;
        }
        @media (--tablet-xs) {
            padding: 6px;
        }
        @media (--mobile) {
            padding: 4px;
        }
        @media (--mobile-lg) {
            padding: 5px;
        }
    }

    .design-greenline & {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
    }
    @media (--tablet-sm-and-less) {
        height: 30px;
        width: 30px;
        margin-top: 5px;
    }
    @media (--tablet-xs-and-less) {
        height: 25px;
        width: 25px;
        border-width: 1px;
    }
    @media (--mobile) {
        height: 16px;
        width: 16px;
    }
    @media (--mobile-lg) {
        height: 20px;
        width: 20px;
    }
}

.image-rows-arrow-outline {
    fill: var(--color-on-primary-color);
    background-color: transparent;
    border: var(--border-medium) var(--color-on-primary-color);
    display: flex;
    overflow: hidden;
    justify-content: normal;
    border-radius: var(--rounded-corners-circle-or-square);
    flex-shrink: 0;
    margin: 10px 0 0 0;
    align-self: center;
    height: 40px;
    width: 40px;
    .design-lampeguru & {
        border-color: transparent;
        background-color:transparent;
    }


    svg {
        height: 15px;
        width: 15px;
        width: 50%;
        height: 100%;
        padding: 10px;
        @media (--tablet-sm) {
            padding: 7px;
        }
        @media (--tablet-xs) {
            padding: 6px;
        }
        @media (--mobile) {
            padding: 4px;
        }
        @media (--mobile-lg) {
            padding: 5px;
        }
    }


    @media (--tablet-sm-and-less) {
        height: 30px;
        width: 30px;
        margin-top: 5px;
    }
    @media (--tablet-xs-and-less) {
        height: 25px;
        width: 25px;
        border-width: 1px;
    }
    @media (--mobile) {
        height: 16px;
        width: 16px;
    }
    @media (--mobile-lg) {
        height: 20px;
        width: 20px;
    }
}

.image-rows-arrow-container {
    min-width: 200%;
    height: 100%;
    display: flex;
    transform: translateX(-50%);
    transition: none;
    align-items: center;
}

.text-under-image-style {
    width: 100%;
    padding: 10px 0 0 0;
    gap: 10px;
    background-color: transparent;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    * {
        color: var(--color-on-primary-color);
        word-break: break-word;

    }
    .image-rows-text {
        color: var(--standard-text-color);
    }
    .image-rows-header {
        color: var(--standard-text-color);
        @include line-clamp(2);
    }
    @media (--tablet-sm) {
        padding: 10px 0 5px 0;
    }
    @media (--tablet-xs-and-less) {
        padding: 10px 0;
        justify-content: flex-start;
    }
    @media (--mobile) {
        padding: 4px 0 8px 0;
        gap: 4px;
    }
    .image-rows-arrow{
        background-color: transparent;
        fill: var(--primary-color);
        border-color: var(--primary-color);
        margin: 0;
        .design-lampeguru & {
            border-color: transparent;
        }
        .design-lavpris & {
            border-color: var(--brand-color);
            background-color: var(--brand-color);
        }
    }
}

.no-underline {
    text-decoration: none !important;
}

.image-width-auto .product_image{
    width: auto;
     /* Safari 11+ */
    @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) and (stroke-color: transparent) {
            width: 100% !important;
        }
    }
}

.logo-style {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    transform: translate(-50%, -50%);
    max-width: max-content;
    max-height: max-content;
}
