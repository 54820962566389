.campaign--banner {
  background-color: $colorBg4;
  display: flex;
  justify-content: flex-end;
  font-family: $fontFamilyOswald;
  transition: all 1s ease;
  text-transform: uppercase;
  &__text {
    padding: 0 30px;
    align-self: center;
    min-width: 55%;
    h3 {
      font-weight: 100;
      text-align: right;
      font-size: 30px;
      margin-bottom: -8px;
    }
    h2 {
      font-weight: 700;
      text-align: right;
      font-size: 50px;
      margin-top: -7px;
      margin-bottom: 0;
    }
  }
  &__cta {
    height: 100%;
    min-height: 150px;
    min-width: 150px;
    display: flex;
    justify-content: center;
    background-color: #fff7c6;
    transition: all 1s ease;
    align-items: center;
    .display {
      display: none;
    }
  }
  &:hover {
    transition: all 1s ease;
    .campaign--banner__cta {
      margin-left: 25%;
      transition: all 1s ease;
    }
    .display {
      display: block;
    }
  }
}
