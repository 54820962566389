.cookie-policy-data {
color: var(--standard-text-color);
line-height: 1.65em;
margin-left: auto;
margin-right: auto;
}
.CookieDeclarationHeader, .CookieDeclarationIntro {
max-width: 852px;
margin-left: auto;
margin-right: auto;
}
.coitable__title span {
background: var(--primary-color);
}
.coitable__declarationtype, .CookieDeclarationType {
border-color:  var(--border);
}