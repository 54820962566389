.social-follow {
  &__article {
    text-decoration: none;
    height: 30px;
    border-radius: 100px;
    width: 30px;
    border: 1px solid $color1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    text-align: center;
    background-color: transparent;
    &:hover {
      background-color: $color1;
      svg {
        fill: $color5;
        .icon-stroke {
          fill: none;
          stroke: var(--color-on-brand-color) !important;
        }
      }
    }
    svg {
      height: 14px;
      width: 14px;
      fill: $color1;
      .icon-stroke {
        fill: none;
        stroke: var(--primary-color);
        stroke-width: 1.5px;
        .design-completvvs & {
          stroke: var(--secondary-color);
        }
            }
    
    }
  }
}
