$gColorFont1: #444444; //body font color
$gColorFont2: #999999; //grey text
$gColorFont3: #ffffff; //Mobile Nav
$gColorFont4: #222222; //article
$gColorFont5: #63891c; //success
$gColorFont6: #006c99; //info
$gColorFont7: #bca221; //warning
$gColorFont8: #721717; //danger
$gColorFont9: #a94442; //has-error input
$gColorFont10: #ad3200; //hover over $gColorbg9

$gColor1: #96c300; //green stock color
$gColor2: #bcbcbc; //grey productlist

$gColorbg1: #444444; //Mobile menu
$gColorbg2: #ffffff; //Testimonials, cart and checkout
$gColorbg3: #f5f5f5; //cart +/-
$gColorbg4: #e8f1d7; //success bg
$gColorbg5: #d8f4ff; //info bg
$gColorbg6: #fff9db; //warning bg
$gColorbg7: #ffe6e6; //danger bg
$gColorbg8: #ffd400; //BVVS offer yellow
$gColorbg9: #c81a21; //BVVS SE offer red and last chance offer for BVVS DK and NO
$gColorbg10: #006496; //BVV blue
$gColorbg11: #eeeeee; //BVV blue

$gBtnDefaultBorder: #afafaf;

$gColorBorder1: #dedede; // Lightgray productlist
$gColorBorder2: #d6d5d5; // Grey productlist header
$gColorBorder3: #717171; // Mobile nav borders
$gColorBorder4: #bdbdbd; //input
$gColorBorder5: #dddddd; //light divider d2d2d2
$gColorBorder6: #cddcb0; //success border
$gColorBorder7: #b0d6e6; //info border
$gColorBorder8: #f2e9bc; //warning border
$gColorBorder9: #ffc9c9; //danger border

$gFontFamilyArial: Arial, sans-serif;
