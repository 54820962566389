.landingpage__videotopbanner__wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: -1px;

  @media (--mobile) {
    height: 100vw;
  }
  .landingpage__topbanner__splash__position {
    @media only screen and (max-width: 450px) {
      display: none;
    }
  }
}

.landingpage {
  &__videotopbanner {
    object-fit: cover;
    width: 100vw;
    &__caption {
      &--dark {
        h1,
        .heading--1,
        p {
          color: $gColorFont1;
        }
      }
      &__wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
      }
    }
    &__logo {
      position: absolute;
      top: 0;
      right: 4%;
      transform: scale(0.8);
      @media (--tablet) {
        transform: scale(0.6);
      }
      @media (--mobile) {
        transform: scale(0.6);
        top: -15px;
        right: -403px;
      }
    }
    &__wrapper {
      @media (--mobile) {
        max-height: 50px;
        height: 100vw;
        max-height: 500px;
        margin: 0;
      }
    }
  }
  &__videotopbanner--still-photo {
    display: none;
  }
}

@media only screen and (max-width: 499px) {
  .landingpage__videotopbanner--still-photo {
    height: 400px;
    display: flex;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    border-radius: 0;
    position: absolute;
    right: 0;
    top: 0;
    min-width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-color: $color1;
  }

  #landingpage__videotopbanner {
    display: none;
  }
}

.article .landingpage__videotopbanner__wrapper {
  margin-bottom: -8vw;
  @media (--mobile) {
    margin-bottom: 0;
  }
}
