#product-card {
  .product-card {
    &__content {
      &__iu {
        background-color: var(--tertiary-background-color);
        padding-bottom: 0;
        @media (--mobile) {
          padding-bottom: 13px;
        }
        &__image {
          padding: 0;
          img {
            width: 100%;
            height: 100%;
          }
          a {
            margin: 1px;
            display: block;
            transition: all 0.2s ease-in-out;
            &:hover {
              opacity: 0.8;
            }
          }
          &__lg {
            width: 66.66%;
          }
        }
        &__cta {
          padding: 1px;
          &__arrow {
            background-color: $color2;
            display: inline-flex !important;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            text-align: center;
            padding: 10px;
            font-size: 14px;
            text-decoration: none;
            line-height: 18px;
            color: $gColorFont1;
            transition: all 0.2s ease-in-out;
            width: calc(100% - 2px);
            height: calc(50% - 2px);
            position: absolute;
            bottom: 0;
            right: 0;
            text-transform: uppercase;
            &:hover {
              color: $color5;
              text-decoration: underline;
              opacity: 1 !important;
              background-color: $color1;
              .product-card__read-more__arrow {
                background-color: $color2;
                svg {
                  fill: $color1;
                }
              }
            }
            @media (--mobile) {
              font-size: 12px;
            }
          }
        }
      }
      &__body {
        &__iu {
          padding: 0;
          @media (--mobile) {
            margin-bottom: -13px;
          }
        }
      }
    }
  }
}
