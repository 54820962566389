/* shared */
.product-stock {
  &__text {
    color: $gColorFont2;
    font-size: 0.75rem;
  }

  &__text__in-stock {
    display: none;
  }

  &--not_in_stock {
    background-color: #666;
  }

  &--productlist {
    text-align: center;
  }

  @media (--mobile) {
    font-size: 0.7em;
  }

  &__status {
    width: 5px;
    height: 5px;
    margin: 5px 3px 1px 1px;
    border-radius: 1px;
    display: inline-block;

    &--in_stock {
      background-color: $gColor1;
    }

    &--not_in_stock {
      background-color: #666;
    }
  }
}
