/* Various SCSS for the CMS system, and its plugins */

.cms-section {
  padding: 0;
  /* border-radius: 4px; Need to align with marketing about the use of border-radius  */
  &__no-bottom-margin {
    padding-bottom: 0 !important;
    .highlight__padding-container {
      padding-bottom: 0 !important;
    }
    .productlist-footer {
      padding-bottom: 0 !important;
    }
  }

  &__no-top-margin {
    padding-top: 0 !important;
    .highlight__padding-container {
      padding-top: 0 !important;
    }
    .productlist-footer {
      padding-top: 0 !important;
    }
  }

  &__add-bottom-margin {
    margin-bottom: 60px !important;
    @media (--tablet-lg) {
      margin-bottom: 50px !important;
    }
    @media (--tablet-sm) {
      margin-bottom: 40px !important;
    }
    @media (--mobile) {
      margin-bottom: 30px !important;
    }
  }

  &__add-top-margin {
    margin-top: 40px !important;
    @media (--tablet-lg) {
      margin-top: 30px !important;
    }
    @media (--tablet-sm) {
      margin-top: 20px !important;
    }
    @media (--mobile) {
      margin-top: 20px !important;
    }
  }

  p {
    img {
      /* border-radius: 4px; Need to align with marketing about the use of border-radius  */
    }
  }
  img {
    /* border-radius: 4px; Need to align with marketing about the use of border-radius  */
  }
  &_carrousel {
    border-radius: 4px;
  }
  @media (--desktop) {
    .product-card__content__header__h2,
    .product-card__content__header__h3,
    .product-card__content__body__productbox__list {
      padding-left: 0;
      padding-right: 0;
    }
    .productbox__slider {
      margin-left: 0;
    }
  }
}

.cms-productlist {
  padding: 0;
  overflow: hidden;
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  @media (--mobile) {
    padding-right: 0 !important;
  }
  .productlist {
    .productbox:nth-last-child(1):nth-of-type(odd) {
      @media only screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
  #campaign-overview-productlist-target {
    position: absolute;
    top: -70;
    @media (--mobile) {
      top: -40px;
    }
  }
}

.cms-background-image {
  height: 100%;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.cms-halfwidth,
.cms-article_body,
.cms-topbanner,
.cms-productfilter {
  padding: 0;
}
.cms-productfilter {
  padding-bottom: 60px;
  @media (--tablet-lg) {
    padding-bottom: 50px;
  }
  @media (--tablet-sm) {
    padding-bottom: 40px;
  }
  @media (--mobile) {
    padding-bottom: 20px;
  }

  .outer-container {
    padding-right: 0;
    padding-left: 0;
  }
}

.cms-clerk,
.cms-partnerlist,
.cms-productlist,
.cms-productlist-grid,
.cms-subpages,
.cms-newsletter,
.cms-image-boxes,
.cms-article_step_by_step,
.cms-step_by_step_image,
.cms-points {
  padding: 40px 0 60px 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (--tablet-lg) {
    padding: 30px 0 50px 0;
  }
  @media (--tablet-sm) {
    padding: 20px 0 40px 0;
  }
  @media (--mobile) {
    a.btn {
      margin-top: 5px;
    }
    padding: 15px 15px 20px 15px;
  }
}

.cms-points {
  h4 {
    margin-bottom: 0;
  }
}

.cms-image-boxes {
  .product-card__content__header__h2 {
    padding-left: 0;
    padding-right: 0;
  }
}

.cms-productgrouplist {
  padding-bottom: 0;
  width: 1435px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  @media (--desktop-lg) {
    width: 1725px;
     }
  @media (--tablet-and-less) {
   width: 100%;
  }

  @media (--mobile) {
    .cms-heading {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.cms-productlist {
  @media (--mobile) {
    padding-left: 0;
    padding-right: 0;
  }
}

.cms-productlist-grid {
  @media (--tablet-lg) {
    padding-left: 40px;
    padding-right: 40px;
    }
  @media (--tablet-sm) {
  padding-left: 30px;
  padding-right: 30px;
  }

  @media (--mobile) {
    padding-left: 15px;
    padding-right: 15px !important;
  }
}

.cms-related_articles {
  padding: 40px 0 60px;
  overflow: hidden;
  @media (--tablet-lg) {
    padding: 30px 0 50px 0;
  }
  @media (--tablet-sm) {
    padding: 20px 0 40px 0;
  }
  @media (--mobile) {
    padding: 20px 0 30px 0;
  }
}

.cms-newsletter {
  @media (--mobile) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

}

.cms-partnerlist {
  @media (--mobile) {
    padding-right: 0;
    padding-left: 0;
    .heading {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}


.cms-codeblock {
  .cms-heading {
    margin: 60px 0 40px;
    @media (--tablet-sm) {
      margin: 50px 0 30px;
    }
    @media (--mobile) {
      margin: 30px 0 20px;
    }
  }
  @media (--mobile) {
    padding: 0 15px;
  }
}

.cms-faqblock,
.cms-faq {
  .heading--2 {
    text-align: center;
  }
}

.cms-faqblock {
  @media (--mobile) {
    padding: 0 15px;
  }
}

/* Plugin: Section Carrousel */

.section-carrousel {
  /* Force nowrap on first render, before slick kicks in. */
  overflow-x: hidden;
  white-space: nowrap;
  /* How to show banners before slick kicks in. */
  & > div {
    /* Full width for banner divs. */
    width: 100%;
    /* Avoid wrapping the DOM element. */
    display: inline-block;
    white-space: normal;
    height: 100%;
    &:nth-child(n + 2) {
      display: none;
    }
  }
}

.cms-banner {
  min-height: 330px;
  overflow: hidden;
  border-radius: 14px;
  @media (--mobile) {
    min-height: 265px;
  }
  &-logo {
    position: absolute;
    &--bottom-left {
      bottom: 14px;
      left: 14px;
    }
    &--bottom-right {
      bottom: 14px;
      right: 14px;
    }
    &--top-left {
      top: 14px;
      left: 14px;
    }
    &--top-right {
      top: 14px;
      right: 14px;
    }
    @media (--mobile) {
      max-width: 90px;
    }
    @media (--tablet) {
      .col-24 & {
        max-width: 200px;
      }
      .col-24.col-md-8 & {
        max-width: 110px;
      }
      .col-24.col-md-16 & {
        max-width: 150px;
      }
    }
    @media (--desktop) {
      .col-24 & {
        max-width: 200px;
      }
      .col-24.col-md-8 & {
        max-width: 110px;
      }
      .col-24.col-md-16 & {
        max-width: 150px;
      }
    }
  }
}


.cms-partnerlist {
  overflow-x: hidden;
  .article {
    padding: 0 50px;
  }
}

.cms-section .cms-banner {
  padding: 5px 6px 0 6px;
  margin: 0 0 6px 0;
  @media (--mobile) {
    padding: 0 5px;
    margin: 5px 0;
  }
}

.cms-heading {
  margin: 0 0 30px 0;
  @media (--tablet) {
    margin: 0 0 20px 0;
  }
  @media (--mobile) {
    margin: 0 0 15px 0;
  }
}

.cms-article_body {
  .btn {
    margin: 0;
  }

  /* clearfix hack, to ensure full height even if children are floating */
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

.cms-article_header__logo {
  max-height: 45px;
  max-width: 100px;
  position: absolute;
  right: 30px;
  top: 30px;
  @media (--tablet) {
    right: 20px;
    top: 20px;
  }
  @media (--tablet-sm-and-less) {
    max-height: 40px;
    max-width: 90px;
  }
  @media (--mobile) {
    position: unset;
    margin: 0 0 15px 0;
  }
  @media (--mobile-sm) {
    max-height: 35px;
    max-width: 80px;
  }
}

.cms-campaign_overview{
  overflow: hidden;
  margin-top: -15px;
  position: relative;
  padding-bottom: 20px;
  @media (--tablet-sm) {
    padding-bottom: 15px;
  }
  @media (--mobile) {
    margin-top: -10px;
    padding-bottom: 5px;
  }
}

.cms-manufactor-focus {
  padding-bottom: 20px;
  overflow: hidden;
  @media (--tablet-sm) {
    padding-bottom: 15px;
  }
  @media (--mobile) {
    padding-bottom: 5px;
  }
}
