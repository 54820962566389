@import "~styles/utils/media-queries";

.header {
  font: var(--text-header-lg);
  align-items: center;
  display: flex;
}

.header-container {
  display: flex;
  align-items: center;
  padding: 15px 45px 0 15px;
  gap: 10px;

  @media (--mobile) {
    padding: 15px 15px 0;
  }
}

.fullscreen-header {
  padding: 30px 40px 5px;

  @media (--mobile) {
    padding-top: 15px;
  }
}

.subtitle {
  color: var(--subdued-color);
  margin: 10px 15px;
  font-family: var(--default-font);
  font-weight: var(--font-weight-bold);
}

.size-fullscreen {
  .header {
    @media (--mobile) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .subtitle {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.icon {
  height: 32px;
  width: 32px;
  flex-shrink: 0;

  svg {
    height: 100%;
    width: 100%;
    fill: var(--icon-color, var(--muted));
    stroke: var(--icon-color-fg, var(--color-white));
    border-radius: var(--rounded-corners-circle-or-square);
  }

  @media (--tablet) {
    height: 30px;
    width: 30px;
  }

  @media (--mobile) {
    height: 26px;
    width: 26px;
  }
}

.info {
  --icon-color: var(--color-info);
  --icon-color-fg: var(--color-info-fg);

  svg {
    transform: rotate(180deg);
  }
}

.error {
  --icon-color: var(--color-error);
  --icon-color-fg: var(--color-error-fg);
}

.warning {
  --icon-color: var(--color-warning);
  --icon-color-fg: var(--color-warning-fg);
}

.success {
  --icon-color: var(--color-success);
  --icon-color-fg: var(--color-success-fg);
}
