@import "~styles/utils/media-queries";

.content {
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;
  gap: 10px;
  margin-top: 15px;

  @media (--mobile) {
    gap: 5px;
    margin-top: 10px;
  }
}
