@import "../../styles/mixins";

.cms-mosaic-container {
  display: grid;
  @media (--tablet-and-above) {
    grid-template-columns: repeat(2, 50%);
  }
  @media (--mobile) {
    grid-template-columns: repeat(1, 100%);
  }

  &__outer-grid-container:before {
    content: "";
    display: block;
    /*Sets the height of outer-grid-container based on it's width*/
    margin-top: 100%;
  }
  &__outer-grid-container {
    display: flex;
    position: relative;
    width: 100%;

    .mosaic-background-brand-color {
      background-color: var(--brand-color);
      .design-lavpris &, .design-lampeguru & {
        > * {
          color: var(--primary-color);
          fill: var(--primary-color);
        }
      }
      .design-lampeguru & {
        .cms-mosaic-container__outer-grid-container__arrow-button {
          border-color: var(--primary-color);
          background-color: transparent;
          svg {
            fill: var(--primary-color);
          }
          &:hover {
            background-color: var(--primary-color);
            svg {
              fill: var(--color-on-primary-color);
            }
          }
        }
      }
    }
    .mosaic-background-offset-of-brand-color {
      background-color: var(--offset-of-brand-color);
      .design-completvvs & {
        background-color: var(--secondary-color);
      }
      .design-lampeguru & {
        background-color: var(--primary-color);
        > * {
          color: var(--brand-color);
          fill: var(--brand-color);
        }
        .cms-mosaic-container__outer-grid-container__arrow-button {
          border-color: var(--brand-color);
          background-color: transparent;
          svg {
            fill: var(--brand-color);
          }
          &:hover {
            background-color: var(--brand-color);
            svg {
              fill: var(--color-on-brand-color);
            }
          }
        }
      }
    }
    .mosaic-background-offset-of-brand-color-darker {
      background-color: var(--offset-of-brand-color-darker);
      .design-lavpris &, .design-lampeguru & {
        background-color: var(--highligted-background-color);
      }
    }
    .mosaic-background-offset-of-brand-color-darker,
    .mosaic-background-offset-of-brand-color {
      .design-lavpris & {
        .cms-mosaic-container__outer-grid-container__arrow-button {
          border-color: var(--white);
          background-color: transparent;
          svg {
            fill: var(--white);
          }
          &:hover {
            background-color: var(--white);
            svg {
              fill: var(--primary-color);
            }
          }
        }
      }
    }

    .product-background-default {
      background-color: var(--product-background-default);
    }
    .product-background-color1 {
      background-color: var(--color-brand-brighter-1);
    }
    .product-background-color2 {
      background-color: var(--color-brand-brighter-2);
    }
    .product-background-color3 {
      background-color: var(--color-brand-brighter-3);
    }
    .product-background-color4 {
      background-color: var(--color-brand-brighter-4);
    }

    &__grid-container {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      grid-template-rows: repeat(4, 25%);

      /*Sets the correct size of this container, based on it's parent*/
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
    &__grid-container .grid-element {
      position: relative;
      text-transform: var(--title-uppercase);
      overflow: hidden;
      text-decoration: none;
    }
    &__grid-container a.grid-element:hover {
      .grid-element-shadow-container {
        .grid-element-shadow {
          width: 100%;
        }
      }
    }
    &__grid-container .grid-element-shadow-container {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      pointer-events: none;
      .grid-element-shadow {
        position: relative;
        left: 0;
        width: 0;
        height: 100%;
        background: var(--dark);
        opacity: 5%;
        pointer-events: none;
        transition: width 0.4s ease-out;
      }
    }

    &__grid-container .grid-span-1y {
      grid-row-start: span 1;
    }

    &__grid-container .grid-span-2y {
      grid-row-start: span 2;
    }
    &__grid-container .grid-span-3y {
      grid-row-start: span 3;
    }
    &__grid-container .grid-span-4y {
      grid-row-start: span 4;
    }

    &__grid-container .grid-span-1x {
      grid-column-start: span 1;
      @media (--tablet-and-above) {
        font-size: 1vw;
      }
      @media (--mobile) {
        font-size: 2vw;
      }
    }
    &__grid-container .grid-span-2x {
      grid-column-start: span 2;
    }
    &__grid-container .grid-span-1x.grid-span-1y,
    &__grid-container .grid-span-2x.grid-span-1y {
      .animated-arrow {
        @media (--mobile-sm) {
          display: none;
        }
      }
    }
    &__grid-container .grid-span-2x {
      line-height: 1.3;
      font-size: 1.9vw;
      .design-lavpris & {
        font-size: 1.6vw;
      }
      @media (--mobile) {
        font-size: 3.6vw;
        .design-lavpris & {
          font-size: 3.2vw;
        }
      }
    }
    &__grid-container .grid-span-1x.grid-span-1y,
    &__grid-container .grid-span-2x.grid-span-1y {

      .splash--text {
        display: none;
      }
      @media (--tablet-and-above) {
        font-size: 1.5vw;
      }

      @media (--tablet-and-less) {
        img {
          display: none;
        }
      }
    }

    &__grid-container .grid-span-2x.grid-span-4y,
    &__grid-container .grid-span-2x.grid-span-3y,
    &__grid-container .grid-span-3x.grid-span-2y,
    &__grid-container .grid-span-4x.grid-span-2y,
    &__grid-container .grid-span-4x.grid-span-3y {
      font-size: 2.2vw;
      @media (--mobile) {
        font-size: 4vw;
      }
    }
    &__grid-container .grid-span-2x.grid-span-3y,
    &__grid-container .grid-span-2x.grid-span-4y {
      .cms-mosaic-container__outer-grid-container__discover-box__content,
      .cms-mosaic-container__outer-grid-container__text-box__content {
        justify-content: center;
      }
    }
    &__grid-container .grid-span-3x {
      grid-column-start: span 3;
      line-height: 1.2;
      @media (--tablet-and-above) {
        font-size: 3vw;
      }
      @media (--mobile) {
        font-size: 6vw;
      }
    }
    &__grid-container .grid-span-4x {
      grid-column-start: span 4;
      line-height: 1.2;
        font-size: 3.8vw;
        .design-lavpris & {
          font-size: 2.8vw;
        }

      @media (--mobile) {
        font-size: 9vw;
        .design-lavpris & {
          font-size: 6.8vw;
        }
      }
      @media (--mobile-sm) {
        font-size: 10vw;
            }
    }
    &__grid-container .grid-span-4x.grid-span-1y {
      font-size: 1.25vw;
      line-height: 1.2;
      @media (--tablet) {
        font-size: 1.5vw;
      }
      @media (--mobile) {
        font-size: 3.6vw;
      }
      @media (--mobile-xl) {
        font-size: 2.5vw;
      }
      @media (--mobile-sm) {
        .animated-arrow {
          display: none;
        }
      }
    }
    &__grid-container .grid-span-2x.grid-span-4y,
    &__grid-container .grid-span-3x.grid-span-4y,
    &__grid-container .grid-span-4x.grid-span-4y {
      .cms-mosaic-container__outer-grid-container__text-box__content,
      .cms-mosaic-container__outer-grid-container__discover-box__content {
        justify-content: center;
      }
    }
    &__logo {
      top: 15px;
      left: 15px;
      max-width: 90px;
      max-height: 40px;
      z-index: 1;
      position: absolute;

      @media (--tablet-lg) {
        max-width: 80px;
        max-height: 35px;
      }
      @media (--tablet-sm) {
        max-width: 70px;
        max-height: 30px;
      }
      @media (--tablet-sm-and-less) {
        left: 10px;
        top: 10px;
      }
      @media (--mobile) {
        max-width: 50px;
        max-height: 20px;
      }
      @media (--mobile-lg) {
        max-width: 60px;
        max-height: 25px;
      }

      &--white {
        filter: brightness(0) invert(1);
      }
    }
    &__product-box {
      width: 100%;
      height: 100%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-family: var(--brand-font);
      @media (--tablet-sm-and-less) {
        padding: 10px;
      }

      &__picture {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &__splash {
        &--pct, &--text {
          top: 0;
          right: 0;
          height: unset;
          position: absolute;
        }

        &--pct {
          &.span-2x-2y,
          &.span-2x-3y,
          &.span-2x-4y {
            width: 5vw;
            @media (--mobile) {
              width: 12vw;
            }
            @media (--mobile-xl) {
              width: 11vw;
            }
            @media (--mobile-sm) {
              width: 13vw;
            }
          }
          &.span-4x-4y {
            height: unset;
            width: 9vw;
            max-width: unset;
            max-height: unset;
            @media (--tablet) {
              width: 8vw;
            }
            @media (--mobile) {
              width: 16vw;
            }
            @media (--mobile-xl) {
              width: 14vw;
            }
            @media (--mobile-sm) {
              width: 18vw;
            }
          }
        }
      }

      $max-information-height: 38%;

      &__image-container {
        min-height: calc(100% - $max-information-height);
        display: flex;
        justify-content: center;
        position: relative;
        padding: 5% 10%;
        flex-grow: 1;
        flex-basis: 0;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      &__information {
        max-height: $max-information-height;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        text-transform: none;
        color: var(--standard-text-color);
      }
      &__information &__title {
        margin-bottom: 1%;
        overflow: hidden;
        line-height: 1.4;
          font-size: 62%;
        .design-lampeguru & {
          font-weight: var(--brand-font-weight);
        }
        @include line-clamp(2);
        @media (--mobile-md) {
          font-size: 90%;
        }

      }
      &__information &__prices {
        display: flex;
        align-items: baseline;
        &__price, &__offer-price {
          color: var(--product-price-color);
          margin-right: 4.5%;
          font-size: 72% !important;
          font-weight: var(--font-weight-price);
          @media (--mobile-md) {
            font-size: 100% !important;
          }
        }
        &__offer-price {
          color: var(--discount-color);
        }
        &__normal-price {
          color: var(--before-price);
          text-decoration: line-through;
          font-size: 47% !important;
          font-weight: var(--before-price-font-weight);
          @media (--mobile-md) {
            font-size: 82% !important;
          }
        }
      }
      &__information &__shipping-fee-notice {
        font-size: 50%;
        margin-top: 1.5%;
        @media (--mobile) {
          font-size: 57%;
        }
      }
    }
    &__text-box {
      background-color: var(--offset-of-brand-color-darker);
      padding: 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      .design-lavpris & {
        background-color: var(--dark);
      }
      @media (--tablet-sm-and-less) {
        padding: 10px;
      }

      &__campaign-button {
        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-start;
        align-items: center;

        &__campaign-text {
          font-size: 15px;
          font-weight: var(--font-weight-bold);
          text-transform: initial;
          color: var(--color-on-primary-color);
          @media (--tablet-sm-and-less) {
            font-size: 14px;
          }
          @media (--mobile-md) {
            display: none;
          }
        }
      }

      &__img {
        max-width: 90px;
        max-height: 40px;
        @media (--tablet-lg) {
          max-width: 80px;
          max-height: 35px;
        }
        @media (--tablet-sm) {
          max-width: 70px;
          max-height: 30px;
        }
        @media (--tablet-sm-and-less) {
          left: 10px;
          top: 10px;
        }
        @media (--mobile) {
          max-width: 50px;
          max-height: 20px;
        }
        @media (--mobile-lg) {
          max-width: 60px;
          max-height: 25px;
        }

      }
      &__img--white {
        filter: brightness(0) invert(1);
      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 100%;
        color: var(--color-on-primary-color);
        &__fat-text {
          font-family: var(--brand-font);
          font-weight: var(--brand-font-weight);
          letter-spacing: var(--letter-spacing-xs);
          word-spacing: var(--word-spacing-xs);
          margin: 10px 0;
          @media (--mobile-md) {
            margin: 5px 0;
          }
        }
        &__text {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: 10px;
          font-family: var(--default-font);
          text-transform: none;
          @media (--tablet-sm-and-less) {
            font-size: 15px;
          }
          @media (--mobile) {
            font-size: 14px;
          }
          @media (--mobile-md) {
            font-size: 12px;
          }
        }
        &__arrow-button-down {
          display: flex;
          flex-direction: column;
          justify-content: center;
          transform: rotate(90deg);
          margin: 10px;
          @media (--tablet-sm-and-less) {
            margin: 8px;
          }
          @media (--mobile-md) {
            margin: 5px;
          }
        }
      }
    }
    &__discount-block,
    &__seen-on-tv-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-family: var(--brand-font);
      font-weight: var(--brand-font-weight);
      color: var(--color-on-primary-color);
      &__text-container {
        display: flex;
        justify-content: center;
        &__inner-text-container {
          display: flex;
          flex-direction: column;
          &__text {
            text-align: start;
            font-size: 182%;
          }
          &__discount-text,
          &__seen-on-tv-text {
            text-align: start;
            font-size: 445%;
            line-height: 90%;
          }
        }
      }
      &__button-container {
        display: flex;
        justify-content: center;
        margin-top: 5%;
      }
    }
    &__seen-on-tv-box {
      &__text-container {
        &__inner-text-container {
          &__text {
            font-size: 162%;
            .design-greenline & {
              font-size: 201%;
            }
            .design-lavpris &, .design-lampeguru & {
              font-size: 187%;
            }
            .design-completvvs & {
              font-size: 198%;
            }
          }
        }
      }
      &__button-container {
        display: flex;
        justify-content: center;
        margin-top: 5%;
      }
    }
    &__discover-box {
      color: var(--white);
      height: 100%;
      width: 100%;
      position: relative;
      &__splash {
        &--text {
          right: 15px;
          top: 15px;
          font-size: 60% !important;
          padding: 0.6% 2.4% !important;
          position: absolute;
          @media (--tablet-sm-and-less) {
          right: 10px;
          top: 10px;
          }
          @media (--tablet-xs) {
            padding: 2px 8px !important;
            font-size: 12px !important;
        }
          @media (--mobile-md) {
              font-size: 10px !important;
              padding: 1px 6px !important
          }

          .design-greenline & {
            background-color: var(--primary-color) !important;
          }

        }
        &--pct {
          top: 15px;
          right: 15px;
          height: unset;
          &.span-2x-2y,
          &.span-2x-3y,
          &.span-2x-4y {
            width: 5vw;
            @media (--mobile) {
              width: 12vw;
            }
            @media (--mobile-xl) {
              width: 11vw;
            }
            @media (--mobile-sm) {
              width: 13vw;
            }
          }
          @media (--tablet-sm-and-less) {
            top: 10px;
            right: 10px;
          }
          &.span-4x-4y {
            height: unset;
            width: 18%;
            max-width: unset !important;
            max-height: unset !important;
            top: 15px;
            right: 15px;

          }
        }
      }
      &__content {
        font-family: var(--brand-font);
        font-weight: var(--title-font-weight);
        display: flex;
        flex-direction: column;
        position: absolute;
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: 1;
        hyphens: auto;
        word-break: auto-phrase;
        &__button.btn {
          pointer-events: all;
          width: fit-content;
          font-family: var(--button-font);
          margin: 0;
          text-transform: none;
          .design-lavpris & {
            background-color: var(--primary-color);
            border: none;
            font-size: 1.6rem;
            @media (--tablet-lg) {
              font-size: 1.5rem;
            }
            @media (--tablet-sm) {
              font-size: 1.35rem;
            }
            @media (--mobile) {
              font-size: 1.25rem;
            }
            @media (--mobile-sm) {
              font-size: 1rem;
            }
            &:hover {
              background-color: color-mix(in srgb,var(--cta-color),var(--white)15%);
              color: var(--color-on-primary-color);
            }
          }
          .design-lampeguru & {
            background-color: var(--cta-color);
            border: none;
            &:hover {
              background-color: var(--cta-hover-color);
              color: var(--color-on-cta-hover-color);
            }
          }
        }
        &__text {
          .design-lavpris & {
            background-color: rgba(255, 212, 47, 0.9);
            color: var(--color-on-brand-color);
            padding: 0.2vw 0.8vw;
            width: fit-content;
          }
        }
      }
      &__content--big {
        justify-content: center;
        padding: 4.6%;
        .splash--text {
          font-size: 50% !important;
          right: 15px;
          top: 15px;
          }
        @media (--mobile) {
          justify-content: flex-end;
          padding: 15px;
        }
        &__text {
          margin: 15px 0;
          letter-spacing: var(--letter-spacing-sm);
          word-spacing: var(--word-spacing-sm);
        }
      }
      &__content--tall {
        padding: 15px;
        @media (--tablet-sm-and-less) {
          padding: 10px;
        }

        &__text {
          letter-spacing: var(--letter-spacing-xs);
          word-spacing: var(--word-spacing-xs);
          margin: 10px 0;
        }
      }
      &__content--small {
        justify-content: flex-end;
        padding: 15px;

        @media (--tablet-sm-and-less) {
          padding: 10px;
        }

        &__text {
          letter-spacing: var(--letter-spacing-xs);
          word-spacing: var(--word-spacing-xs);
          margin: 10px 0;
          @media (--mobile-md) {
            margin: 5px 0;
          }

        }
      }
      &__background-img {
        width: 100%;
        height: 100%;
        transition: transform 0.5s;
        object-fit: cover;
      }
      &__rotate-arrow {
        transform: rotate(90deg);
        width: fit-content;
      }
    }
    &__discover-box:hover {
      .cms-mosaic-container__outer-grid-container__discover-box__background-img {
        transform: scale(1.1);
      }
    }
    &__arrow-button {
      pointer-events: all;
      transition: all 0.4s ease;
      $arrow-width: 40px;
      flex-shrink: 0;
      width: $arrow-width;
      height: $arrow-width;
      border: var(--border-medium) var(--color-on-brand-color);
      border-radius: var(--rounded-corners-circle-or-square);
      display: flex;
      align-items: center;
      justify-content: center;
      .grid-span-4x & {
        @media (--mobile-sm) {
          display: block !important;
        }
      }
      .design-lampeguru & {
        border: none;

      }
      .design-lavpris & {
        border: none;
        background-color: var(--dark);

      }
      @media (--tablet-sm-and-less) {
        width: 30px;
        height: 30px;

      }

      @media (--mobile-md) {
        border: var(--border-thin) var(--color-on-brand-color);
        width: 24px;
        height: 24px;
      }

      svg {
        width: 15px;
        height: 15px;
        fill: var(--color-on-primary-color);



        @media (--tablet-sm-and-less) {
          width: 10px;
          height: 10px;
        }

        @media (--mobile-sm) {
          width: 8px;
          height: 8px;
        }
      }
    }
    &__arrow-button:hover,
    a:hover &__arrow-button {
      background-color: var(--white);
      svg {
        fill: var(--primary-color);

      }
      .design-lavpris & {
        background-color: color-mix(in srgb,var(--cta-color),var(--white)15%);
        svg {
          fill: var(--color-on-primary-color);
        }
      }
      .design-lampeguru & {
        background-color: var(--dark);
        svg {
          fill: var(--white);
        }
      }
    }
    &__center-horizontal {
      display: flex;
      justify-content: center;
      height: 100%;
    }
    &__center-vertical {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &__campaign-box {
      &:has(.cms-mosaic-container__outer-grid-container__countdown-content){
        padding-bottom: 12%;
    
      }
      .grid-span-4x & {
        padding-left: 4.6%;
        padding-right: 4.6%;
   
      }
      .grid-span-2y & {
        .animated-arrow {
          @media (--mobile) {
            display: none;
          }
          @media (--mobile-lg) {
            display: flex;
          }
        }
      }

      background-color: var(--brand-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-weight: var(--brand-font-weight);
      color: var(--color-on-brand-color);
      padding: 16px;
      gap: 4%;
      @media (--tablet-and-less) {
        padding: 10px;
      }
      @media (--mobile-md) {
        gap: 2%;
      }
      &__arrow-down {
        display: flex;
        flex-direction: column;
        justify-content: center;
        transform: rotate(90deg);
        margin: 10px;
          .design-lampeguru & {
            svg {
              fill: var(--dark);
            }
          }

        @media (--tablet-sm-and-less) {
          margin: 8px;
        }
        @media (--mobile-md) {
          margin: 5px;
        }

      }
      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        &__text {
          font-family: var(--brand-font);
          font-weight: var(--brand-font-weight);
          letter-spacing: var(--letter-spacing-xs);
          word-spacing: var(--word-spacing-xs);
          line-height: 1.3;
          font-family: var(--brand-font);
          font-size: 1.8vw;
          @media (--tablet) {
            font-size: 1.6vw;
          }
          @media (--mobile) {
            font-size: 3.6vw
          }
          @media (--mobile-md) {
            display: none;
          }
        }
      }
    }

    &__countdown-content {
      display: flex;
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 8px 16px;
      background-color: var(--color-urgent);
      justify-content: center;
      align-items: center;
      @media (--tablet-sm-and-less) {
        padding: 6px 10px;
      }
      @media (--mobile-md) {
        padding: 4px 8px;
      }
      &__expiry-label {
        font-weight: var(--font-weight-bold);
        font-size: 14px;
        text-transform: var(--minor-header-uppercase);
        text-align: center;
        color: var(--white);
        @media (--tablet-sm-and-less) {
          font-size: 12px;
        }
        @media (--mobile-md) {
          font-size: 10px;
        }
      }
    }

    &__campaign-splash-svg {
      max-width: 54%;
      max-height: 54%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        min-width: 100%;
      }
      @media (--mobile-md) {
        max-width: 80%;
        max-height: 80%;
      }
    }
  }
  &__position-unset{
    position: unset;

  }
  &__product-position {
    bottom: 15px;
    left: 0;
    height: fit-content;
    top: unset;
    @media (--tablet-sm-and-less) {
      bottom: 10px;
    }
    @media (--mobile-md) {
      bottom: 5px;
    }
  }
}
