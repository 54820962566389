.container-fluid {
  .half-width {
    &__wrapper {
      @media (--mobile) {
        padding: 5px 5px 0 5px;
      }
      &__style2,
      &__style4 {
        .half-width__text {
          @media (--desktop) {
            padding: 5% 15% 5% 9%;
          }
          @media (--tablet) {
            padding: 12% 8% 13% 8%;
          }
        }
      }
    }
    &__text {
      @media (--mobile) {
        padding: 0px 5px 10px;
      }
    }
  }
}

.cms-halfwidth {
  .half-width {
    &__wrapper {
      display: flex;
      flex-direction: row;
      margin: 0;

      &__style1 {
        background-color: var(--offset-of-brand-color-lighter);
        .design-lavpris & {
          background-color: var(--brand-color);
        }
        .design-completvvs & {
          background-color: var(--brand-color);

          .btn-primary {
            background-color: var(--cta-color);
            color: var(--color-on-cta-color);
            border-color: var(--cta-color);
            &:hover {
              background-color: var(--cta-hover-color);
              border-color: var(--cta-hover-color);
              color: var(--color-on-cta-hover-color);
            }
          }
        }
        .design-lampeguru & {
          background-color: var(--primary-color);
          h2,
          p {
            color: var(--color-on-primary-color);
          }
        }
        .design-vvskupp & {
          h2,
          p {
            color: var(--white);
          }
        }
        .design-greenline & {
          background-color: var(--secondary-color);
          h2,
          p {
            color: var(--color-on-secondary-color);
          }
        }
      }
      &__style2 {
        p,
        h2 {
          color: var(--color-on-primary-color);
        }
        background-color: var(--primary-color);
        flex-direction: row-reverse;
        .design-lampeguru & {
          background-color: var(--brand-color);
          p,
          h2 {
            color: var(--color-on-brand-color);
          }
        }

        .half-width__products {
          margin-left: -30px;
          margin-right: 0px;
          @media (--tablet) {
            margin-left: -10px;
          }
          @media (--mobile) {
            margin-left: 0;
          }
        }
        @media (--tablet-and-above) {
          .half-width__products {
            justify-content: flex-start;
          }
        }
        .btn-whiteBorder:hover,
        .btn-whiteBorder:active,
        .btn-whiteBorder:focus {
          color: var(--brand-color);
        }
      }

      &__style4,
      &__style3 {
        p,
        h2 {
          color: var(--color-on-highligted-background-color);
        }
        flex-direction: row-reverse;

        background-color: var(--highligted-background-color);
        @media (--tablet-and-above) {
          .half-width__products {
            justify-content: flex-start;
          }
        }

        .half-width__products {
          margin-left: -30px;
          margin-right: 0px;
          @media (--tablet) {
            margin-left: -10px;
          }
          @media (--mobile) {
            margin-left: 0;
          }
        }
        .btn-whiteBorder:hover {
          color: var(--highligted-background-color);
        }
      }
      &__style3 {
        flex-direction: row;
        background-color: var(--offset-of-brand-color-darker);

        .design-completvvs &
        .btn-primary {
          background-color: var(--cta-color);
          color: var(--color-on-cta-color);
          border-color: var(--cta-color);
          &:hover {
            background-color: var(--cta-hover-color);
            border-color: var(--cta-hover-color);
            color: var(--color-on-cta-hover-color);
          }
        }
      
        .design-lavpris &,
        .design-lampeguru & {
          background-color: var(--offset-of-brand-color-lighter);
          p,
          h2 {
            color: var(--standard-text-color);
          }
        }
        .design-vvskupp & {
          h2,
          p {
            color: var(--white);
          }
        }
        @media (--tablet-and-above) {
          .half-width__products {
            justify-content: flex-end;
          }
        }
      }
      &__style4 {
        .design-vvskupp & {
          .btn-whiteBorder {
            border-color: var(--dark);
            color: var(--dark);
            &:hover {
              background-color: var(--dark);
              color: var(--white);
              outline: none;
            }
          }
        }
      }
      &__style-standard {
        background-color: transparent;
        padding: 40px;
        max-width: 1435px;
        margin-left: auto;
        margin-right: auto;
        @media (--desktop-lg) {
          max-width: 1725px;
        }
        @media (--tablet-sm) {
          padding: 30px;
        }
        @media (--mobile) {
          padding: 15px;
        }
        .half-width__image {
          border-radius: var(--rounded-corners);
          @media (--mobile) {
            width: calc(100vw - 30px);
          }
        }
        .half-width__content {
          @media (--mobile) {
            text-align: center;
          }
        }
      }
      &__style-standard.is-reversed {
        .btn-brand {
          border: var(--border-medium) $gColorFont1;
          background: transparent;
          transition: all 0.4s ease;
          color: $gColorFont1;
          &:hover,
          &:active,
          &:focus {
            background-color: $gColorFont1;
            color: $color5;
          }

          .design-lavpris & {
            background-color: var(--brand-color);
            color: var(--color-on-brand-color);
            border-color: var(--brand-color);
            &:hover,
            &:active,
            &:focus {
              background-color: var(--primary-color);
              color: var(--color-on-primary-color);
              border-color: var(--primary-color);
              text-decoration: none;
            }
          }
          .design-completvvs & {
            background-color: transparent;
            border-color: var(--brand-color);
            color: var(--brand-color);
            &:hover,
            &:active,
            &:focus {
              background-color: var(--brand-color);
              border-color: var(--brand-color);
              color: var(--color-on-brand-color);
              text-decoration: none;
            }
          }
        }
      }
      &__style2.is-reversed,
      &__style4.is-reversed {
        flex-direction: row;
        @media (--tablet-and-above) {
          .half-width__products {
            justify-content: flex-end;
          }
        }
      }
      &__style1.is-reversed,
      &__style3.is-reversed,
      &__style-standard.is-reversed {
        flex-direction: row-reverse;
        @media (--tablet-and-above) {
          .half-width__products {
            justify-content: flex-start;
          }
        }
      }
      @media (--mobile) {
        flex-direction: column !important;
      }
    }
    &__products {
      display: flex;
      justify-content: flex-end;
      padding: 60px 0;
      .productbox__info {
        height: unset;
      }
      @media (--tablet-lg) {
        padding: 50px 0;
      }
      @media (--tablet-sm) {
        padding: 40px 0;
      }
      @media (--mobile) {
        width: auto !important;
        padding: 30px 0 0 0;
        justify-content: center;
        .productbox {
          max-width: 275px;
          width: calc(50vw - 18px);
          &:last-of-type {
            margin: 0;
          }
        }
        .productbox__image img {
          height: auto;
        }
      }
    }
    &__video {
      width: 40%;
      @media (--mobile) {
        width: 100%;
      }
    }
    &__image {
      width: 40%;
      min-height: 47dvh;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      object-fit: cover;
      max-height: 485px;
      @media (--tablet) {
        min-height: 42dvh;
      }
      @media (--mobile) {
        width: 100vw;
        order: 1;
        height: 50vw;
        min-height: 190px;
        max-height: 390px;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 60%;
      @media (--mobile) {
        width: 100%;
        order: 2;
        margin: 0 auto;
      }
    }

    &__text {
      width: 100%;
      padding: 4% 15% 5% 15%;
      .btn {
        margin: 20px 0 0;
        @media (--mobile) {
          margin: 5px auto 10px auto;
        }
      }
      @media (--tablet-lg) {
        padding: 5% 10%;
      }
      @media (--tablet-sm) {
        padding: 5% 8%;
      }
      @media (--mobile) {
        padding: 20px 15px;
      }
    }
  }
}
