.main-container,
.footer {
  @media (--ios) {
    overflow-x: hidden;
  }
  max-width: 100vw;
}

.container-breadcrumb {
  background-color: transparent;
  padding: 0 5px;
  overflow: hidden;
  .container {
    width: 1435px;
    padding: 0;
    @media (--desktop-lg) {
      width: 1725px;
    }
    @media (--tablet-lg) {
      width: 100%;
      padding-right: 40px;
      padding-left: 40px;
    }
    @media (--tablet-sm) {
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

.none-active {
  opacity: 0.3;
}

.no-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
  @media (--mobile) {
    font-size: 12px;
  }
  svg {
    fill: $colorBg1;
    height: 150px;
    width: 190px;
    margin-bottom: 15px;
    @media (--mobile) {
      margin-bottom: 10px;
    }
  }
}

.no-margin {
  margin: 0;
}

.outer-container {
  width: 1435px;
  margin: 0 auto;
  position: relative;
  display: block;
  padding-right: 0;
  padding-left: 0;
  position: relative;
  @media (--desktop-lg) {
    width: 1725px;
  }
  @media (--tablet-lg) {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media (--tablet-sm) {
    width: 100%;
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (--mobile) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}

#product-card {
  background-color: $colorBg2;
  width: 100%;

  .container {
    width: 100%;
    padding: 0;
    max-width: initial;
    @media (--mobile) {
      margin: 0;
    }
  }
}
