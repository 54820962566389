@import "~styles/utils/media-queries";

.content {
  display: flex;
  justify-content: center;
}

.la-ball-spin-clockwise-fade,
.la-ball-spin-clockwise-fade > div {
  position: relative;
  box-sizing: border-box;
}

.la-ball-spin-clockwise-fade {
  display: block;
  font-size: 0;
  color: var(--primary-color);
  width: 32px;
  height: 32px;
}

.la-ball-spin-clockwise-fade.la-dark {
  color: var(--dark);
}

.la-ball-spin-clockwise-fade > div {
  display: inline-block;
  float: none;
  background-color: currentcolor;
  border: 0 solid currentcolor;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -4px;
  margin-left: -4px;
  border-radius: 100%;
  animation: ball-spin-clockwise-fade 1s infinite linear;
}

.la-ball-spin-clockwise-fade > div:nth-child(1) {
  top: 5%;
  left: 50%;
  animation-delay: -0.875s;
}

.la-ball-spin-clockwise-fade > div:nth-child(2) {
  top: 18.1802%;
  left: 81.8198%;
  animation-delay: -0.75s;
}

.la-ball-spin-clockwise-fade > div:nth-child(3) {
  top: 50%;
  left: 95%;
  animation-delay: -0.625s;
}

.la-ball-spin-clockwise-fade > div:nth-child(4) {
  top: 81.8198%;
  left: 81.8198%;
  animation-delay: -0.5s;
}

.la-ball-spin-clockwise-fade > div:nth-child(5) {
  top: 94.9999%;
  left: 50%;
  animation-delay: -0.375s;
}

.la-ball-spin-clockwise-fade > div:nth-child(6) {
  top: 81.8198%;
  left: 18.1802%;
  animation-delay: -0.25s;
}

.la-ball-spin-clockwise-fade > div:nth-child(7) {
  top: 50%;
  left: 5%;
  animation-delay: -0.125s;
}

.la-ball-spin-clockwise-fade > div:nth-child(8) {
  top: 18.1795%;
  left: 18.1804%;
  animation-delay: 0s;
}

/*
  * Animation
  */
@keyframes ball-spin-clockwise-fade {
  50% {
    opacity: 0.25;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
