
.productbox__slider {
  background-color: var(--secondary-background-color);
  border-radius: var(--rounded-corners);
  cursor: pointer;
  display: block;
  height: 15px;
  position: relative;
  user-select: none;
    .design-billigvvs &,
    .design-greenline & {
      background-color: var(--white-background-color);
    }
    .design-vvskupp &,
    .design-completvvs & {
      background-color: var(--tertiary-background-color);
    }
  
  

  @media (--mobile) {
    margin: 0 15px 10px;
  }
  &__indicator {
    background-color: var(--primary-color);
    border-radius: var(--rounded-corners);
    height: 15px;
    transition: box-shadow 0.3s;
    width: 100%;
    &__wrapper {
      cursor: grab;
      margin: -20px 0;
      padding: 20px 0;
      position: absolute;
      top: 0;
      &:hover,
      &--active {
        .productbox__slider__indicator {
          box-shadow: 0 0 0 10px color-mix(in srgb, var(--primary-color), transparent 90%);
        }
      }
    }
  }
}
