
.newsletter {
  &.pb {
    padding-bottom: 30px;
  }
  &__form {
    border-radius: var(--rounded-corners);
    overflow: hidden;
    padding: 30px;
    max-width: fit-content;

    @media (--tablet-sm-and-less) {
      max-width: initial;
      width: 100%;
    }
    @media (--mobile) {
      padding: 15px;
    }
    .newsletter__contactinfo {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 10px 15px 0;
      @media (--tablet-and-above) {
        min-width: 250px;
      }

      @media (--mobile) {
        width: 50%;
        padding: 0 5px 5px 0;
      }
      @media (--mobile-md) {
        width: 100%;
        padding: 0 0 5px 0;
      }
      &--name {
        @media (--mobile) {
          width: 100%;
        }
      }

      .label {
        color: var(--muted);
        font: var(--title-textinput);
        margin: 10px 0 5px;
        text-transform: var(--title-uppercase);
        @media (--mobile) {
          margin: 8px 0 3px;
        }
      }
      .input {
        border: var(--border-thin) var(--border);
        border-radius: var(--rounded-corners);
        color: var(--standard-text-color);
        font-size: 14px;
        padding: 15px;
      }
      &__wrapper {
        display: flex;
        flex-wrap: wrap;

        width: 100%;
      }
    }
    .btn {
      margin-left: 0;
      margin-right: 0;
      @include line-clamp(1);
      @media (--mobile) {
        margin-top: 15px;
      }
      @media (--mobile-md) {
        width: 100%;
      }
    }
    &.newsletter-white {
      background-color: var(--white);
      margin-left: auto;
      margin-right: auto;
    }
    &.newsletter-transparent {
      background-color: transparent;
      .newsletter__contactinfo .input {
        border: none;
      }

      .newsletter__contactinfo > label {
        .design-lavpris &, .design-lampeguru & {
          color: var(--dark);
        }
      }
      .newsletter__disclaimer {
        .design-lavpris &, .design-lampeguru & {
          color: var(--dark);
          opacity: 0.6;
        }
      }
      .btn {
        .design-lavpris & {
          border: var(--border-thin) var(--primary-color);
        }
      }
      @media (--tablet-and-above) {
        padding-left: 0;
        padding-right: 0;
      }
    }
    &.newsletter-text-light {
      .newsletter__header,
      .newsletter__disclaimer,
      .newsletter__disclaimer a {
        color: var(--white);
      }
      .newsletter__contactinfo > label {
        color: var(--white);
      }

      .newsletter__disclaimer {
        opacity: 0.6;
      }
    }
    &.newsletter-text-light {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__disclaimer {
    margin: 20px 0 0 0;
    color: var(--muted);
    font: var(--title-xs);
    @media (--mobile) {
      font: var(--title-xs-mobile);
      margin: 15px 0 0 0;
    }
    a {
      &:hover,
      &:active,
      &:focus {
        color: var(--hover-of-brand-color);
        text-decoration: underline;
        opacity: 1;
      }
    }
  }
  &__header {
    text-transform: var(--title-uppercase);
    letter-spacing: var(--letter-spacing-sm);
    word-spacing: var(--word-spacing-sm);
  }
  &__header, &__wrapper, &__disclaimer {
    max-width: 852px;
  }
}
