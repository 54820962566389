/* stylelints disabled because of linting issues with vendor prefixes and sorting */
/* stylelint-disable */
@mixin line-clamp($lines: 1) {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: $lines;
  overflow: hidden;
}
/* stylelint-enable */

@mixin custom-scrollbar {
  // Only change scrollbar for desktop
  @media only screen and (hover: hover) {
    @supports not selector(::-webkit-scrollbar) {
      scrollbar-color: #999999 transparent;
      scrollbar-width: thin;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#999999, 0.3);
      border: 5px solid transparent;
      border-radius: var(--rounded-corners-sm);
      background-clip: padding-box;
      transition: all 0.4s ease;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: rgba(#999999, 1);
    }

    &::-webkit-scrollbar {
      width: 16px;
    }
  }
}
