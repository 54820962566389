.step-by-step-image {
  @media (--tablet-xs) {
    max-width: 821px;
    margin-left: auto;
    margin-right: auto;
  }
  @media (--mobile) {
    max-width: 677px;
    margin-left: auto;
    margin-right: auto;
  }

  &__content {
    display: flex;
    flex-flow: row;
    margin: 20px 0 30px 0;
    @media (--tablet-sm) {
      margin: 15px 0 25px 0;
    }
    @media (--mobile) {
      flex-flow: column;
      margin: 10px 0;
    }
    &:last-of-type {
      @media (--tablet-sm) {
        margin-bottom: 20px;
      }
      @media (--mobile) {
        margin-bottom: 5px;
      }
    }
    &__image {
      position: relative;
      overflow: hidden !important;
      border-radius: var(--rounded-corners);
      flex-basis: 50%;
      transition: flex-basis 400ms, ease-in-out 0.4s;
      cursor: pointer;
      @media (--mobile) {
        margin: 0 0 15px;
        flex-basis: 100%;
        border-radius: var(--rounded-corners-sm);
      }
      &-fullsize-icon {
        align-items: center;
        height: 50px;
        justify-content: center;
        width: 50px;
        position: absolute;
        background-color: var(--white);
        color: var(--white);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-radius: var(--rounded-corners-circle-or-square);
        opacity: 0;
        visibility: hidden;
        transition: opacity 600ms, visibility 600ms, scale 400ms, ease-in-out 0.4s;

        display: flex;
        svg {
          fill: var(--primary-color);
          height: 20px;
          width: 20px;
        }
        &:hover {
          transform: scale(1.3);
          transition: scale 400ms, ease-in-out 0.4s;
        }
      }
      img {
        transition: transform 0.5s;
        width: 100%;
        border-radius: var(--rounded-corners);
        height: 100%;
        object-fit: cover;
        @media (--mobile) {
        border-radius: var(--rounded-corners-sm);
        }
      }
      &:hover {
        @media (--tablet-and-above) {
          img {
            transform: scale(1.1);
          }
          .step-by-step-image__content__image-fullsize-icon {
            visibility: visible;
            opacity: 0.75;
          }
        }
      }
      &-icon {
        align-items: center;
        height: 50px;
        justify-content: center;
        right: 15px;
        width: 50px;
        top: 15px;
        position: absolute;
        background-color: var(--brand-color);
        border-radius: var(--rounded-corners-circle-or-square);
        font-family: var(--brand-font);
        color: var(--color-on-brand-color);
        display: flex;
        @media (--tablet-sm-and-less) {
          width: 40px;
          height: 40px;
        }
        @media (--mobile) {
          right: 0;
          left: 10px;
          top: 10px;
        }
      }
    }
    &__text {
      flex-basis: 50%;
      justify-content: center;
      padding: 0 5%;
      display: flex;
      flex-direction: column;
      @media (--tablet-lg) {
        padding: 0 40px;
      }
      @media (--tablet-sm) {
        padding: 0 30px;
      }
      @media (--mobile) {
        padding: 0;
        flex-basis: 100%;
      }
      &-important-message {
        background-color: var(--primary-color);
        padding: 6px 15px;
        border-radius: var(--rounded-corners-sm);
        color: var(--color-on-primary-color);
        font-weight: var(--font-weight-bold);
        font-size: 12px;
        max-width: fit-content;
        margin-bottom: 15px;
        @media (--mobile) {
          padding: 4px 10px;
          font-size: 11px;
          margin-bottom: 10px;
        }
      }
      &-text {
        margin: 5px 0 15px;
        @media (--mobile) {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  &__content:nth-child(odd) {
    flex-flow: row-reverse;
    @media (--mobile) {
      flex-flow: column;
    }
    .step-by-step-image__content__image-icon {
      left: 15px;
      right: 0;
      @media (--mobile) {
        right: 0;
        left: 10px;
      }
    }
  }

  .step-by-step-image__content.is-fullsize {
    flex-wrap: wrap;
    transition: flex-basis 400ms, ease-in-out 0.4s;
    .step-by-step-image__content__image {
      flex-basis: 100%;
      transition: flex-basis 400ms, ease-in-out 0.4s;
    }

    .step-by-step-image__content__text {
      padding: 0;
      margin-top: 15px;
      flex-basis: 100%;
    }
  }
}
